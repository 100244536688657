import React from "react";
import { Drawer } from "antd"; // upgrade and verify

import { PdfLoader } from "./PdfLoader";

type PdfPreviewSlideoutProps = {
  searchTerms?: string[];
  documentId?: string;
  documentUrl?: string;
  onSlideoutClose: () => void;
  caseSenstive?: boolean;
};

const documentApiUrl = "/api/documents/download?";

export function PdfPreviewSlideout({
  documentId,
  documentUrl,
  searchTerms,
  onSlideoutClose,
  caseSenstive,
}: PdfPreviewSlideoutProps): JSX.Element {
  if (documentId) {
    documentUrl = documentId
      ? `${window.location.origin}${documentApiUrl}id=${documentId}`
      : undefined;
  }
  return (
    <Drawer
      width={"80%"}
      visible={!!documentUrl}
      onClose={onSlideoutClose}
      bodyStyle={{ height: "100%", padding: 0, backgroundColor: "#eeeeee" }}
    >
      <PdfLoader
        documentUrl={documentUrl}
        searchTerms={searchTerms}
        caseSensitive={caseSenstive}
        onSlideoutClose={onSlideoutClose}
      />
    </Drawer>
  );
}
