import React from "react";

import { QuickFilterBar as FilterBar } from "components/form_components/QuickFilterBar";
import { useSuppliers } from "lib/hooks/api/suppliers/useSuppliers";
import { EventDataTypes } from "lib/tracking";
import { FilterFormProps, FilterSections } from "./FilterForm";
import { DEFAULT_PAGINATION } from "./SupplierTable";
import { convertSupplierFiltersToRequest, SupplierFilters } from "./utils";

const sectionsToFilters: Record<FilterSections, (keyof SupplierFilters)[]> = {
  suppliers: ["suppliers", "isSme"],
  supplierGuids: ["guids"],
  buyers: ["buyerIds", "buyerLists", "buyerCategories"],
  lots: ["lotIds"],
  signals: ["signals"],
};

function getAppliedFilters(
  filters: SupplierFilters,
  hiddenFilterSections?: FilterFormProps["hiddenSections"],
) {
  const appliedFilters = Object.entries(filters).filter(([key, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === "object") {
      if (key === "sort") {
        return false;
      }
      return Object.keys(value).length > 0;
    }
    if (typeof value === "string") {
      return value.length > 0;
    }
    if (typeof value === "boolean") {
      return true;
    }
    return false;
  });

  const hiddenFilters =
    hiddenFilterSections?.flatMap((section) => sectionsToFilters[section]) || [];

  return appliedFilters
    .map(([key]) => key)
    .filter((key) => !hiddenFilters.includes(key as keyof SupplierFilters));
}

const filterKeyToLabel: Record<keyof SupplierFilters, string> = {
  isSme: "SME Supplier",
  buyerIds: "Buyers",
  buyerLists: "Buyer lists",
  buyerCategories: "Buyer type",
  text: "",
  suppliers: "Suppliers",
  signals: "Signals",
  sort: "",
  guids: "Suppliers",
  lotIds: "Lots",
};

type Props = {
  filters: SupplierFilters;
  onClearFilter: (value: keyof SupplierFilters) => void;
  onFilterClick: (value: keyof SupplierFilters) => void;
  hiddenFilterSections?: FilterFormProps["hiddenSections"];
};
export function QuickFilterBar({
  filters,
  onClearFilter,
  onFilterClick,
  hiddenFilterSections,
}: Props) {
  const appliedFilters = getAppliedFilters(filters, hiddenFilterSections);

  const { isLoading, data } = useSuppliers(
    convertSupplierFiltersToRequest(filters, DEFAULT_PAGINATION),
  );

  let sortField: string = filters.sort?.field || "name";

  if (sortField === "awards_count") {
    sortField = "Notices";
  }

  return (
    <FilterBar<SupplierFilters>
      appliedFilters={appliedFilters}
      filterKeyToLabel={filterKeyToLabel}
      sortField={sortField}
      onClear={onClearFilter}
      isLoading={isLoading}
      totalResults={data?.pagingInfo?.totalResults || 0}
      hiddenFields={["text"]}
      dataType={EventDataTypes.supplier}
      onFilterClick={onFilterClick}
    />
  );
}
