import * as React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Checkbox, Select, Spin } from "antd5";
import { OptionProps, SelectProps } from "antd5/lib/select";
import debounce from "lodash.debounce";

import { SearchResponse } from "lib/StotlesApi";

import css from "./AutocompleteMultiselect.module.scss";

const { Option } = Select;

const MAX_TAG_COUNT = 5;
const MAX_RESULT_COUNT = 100;
const MAX_FILTER_BY = MAX_RESULT_COUNT;

export type LabeledValue<IdentType extends string | number> = {
  key: IdentType;
  label: React.ReactNode;
};

type MultiSelectableProps<Item, Resp, IdentType extends string | number> = {
  searchByText: (text: string, limit?: number) => Promise<Resp>;
  searchByIds: (ids: IdentType[]) => Promise<Resp>;
  onItemsChange: (newItemIds: IdentType[]) => void;
  getIdentifier: (item: Item) => IdentType;
  displayedAttr: keyof Item;
  itemIds: IdentType[];
  narrowSearchLabel: (count: number) => string;
  renderIcon?: (item: Item) => React.ReactNode;
};

const SELECT_ALL_ID = "-1";

function useMultiSelectable<
  Item extends Record<string, unknown>,
  Resp extends SearchResponse<Item>,
  IdentType extends number | string,
>({
  searchByText,
  searchByIds,
  onItemsChange,
  getIdentifier,
  displayedAttr,
  itemIds,
  narrowSearchLabel,
  renderIcon,
}: MultiSelectableProps<Item, Resp, IdentType>) {
  // We store it as a ref as the component doesn't have to update when this changes
  // and it's much easier to prevent circular updates that way.
  const itemNameCache = React.useRef({} as Record<IdentType, Item[keyof Item]>);
  // We just cache it so we can repeat the fetch with same query in case user click "Add all".
  const [searchText, setSearchText] = React.useState<string>("");
  // First 20 records matching user's query. `null` when user is not searching.
  const [matchingItems, setMatchingItems] = React.useState<Item[] | null>(null);
  // Total number of matching records -> used to check if the user can click "Add all".
  const [numMatching, setNumMatching] = React.useState<number>(0);
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  // The actual value that will be passed to `Select`
  // calculated based on `props.recordsId` and `itemNameCache`.
  const [value, setValue] = React.useState<LabeledValue<IdentType>[]>([]);
  // make sure the latest results are always returned
  const requestCount = React.useRef<number>(0);

  // Called whenever user selects/deselects/clears items in Select component.
  const handleChange = React.useCallback(
    async (selectedOptions: LabeledValue<IdentType>[]) => {
      let newIds = selectedOptions.map((o) => o.key);

      // Early out for 'clear' operation
      if (newIds.length === 0) {
        setMatchingItems(null);
        setNumMatching(0);
        onItemsChange([]);
        setSearchText("");
        return;
      }

      // SELECT_ALL_ID is a special value only used by the "Select all" option.
      // Because it is a string (regardless of numeric or uuid ids), we must map the newIds
      // to strings first
      if (newIds.map((id) => `${id}`).indexOf(SELECT_ALL_ID) !== -1) {
        // get rid of the special value
        newIds = newIds.filter((id) => `${id}` !== SELECT_ALL_ID);

        // Fetch *all* records matching the query
        const response = await searchByText(searchText);

        // We don't want to add records that are already selected so keep a set of ids
        const idSet = new Set(newIds);
        for (const r of response.results) {
          if (idSet.has(getIdentifier(r))) {
            continue;
          }
          itemNameCache.current[getIdentifier(r)] = r[displayedAttr];
          newIds.push(getIdentifier(r));
        }

        // For good UX, clear the results after clicking "select all"
        setMatchingItems(null);
        setNumMatching(0);
      }

      onItemsChange(newIds);
    },
    [onItemsChange, searchByText, searchText, displayedAttr, getIdentifier],
  );

  // This updates values in the Select.
  // If we don't have names in cache for some record ids it will fetch them
  // using record search API.
  React.useEffect(() => {
    void (async () => {
      const missing: IdentType[] = [];
      for (const id of itemIds) {
        const label = itemNameCache.current[id];
        if (label === undefined) {
          missing.push(id);
        }
      }

      if (missing.length > 0) {
        const response = await searchByIds(missing);

        for (const r of response.results) {
          itemNameCache.current[getIdentifier(r)] = r[displayedAttr];
        }
      }

      setValue(
        itemIds.map((id) => ({
          key: id,
          label: `${itemNameCache.current[id] || ""}`,
        })),
      );
    })();
  }, [displayedAttr, itemIds, searchByIds, getIdentifier]);

  // This is called when user types something in the Select box.
  const fetchRecords = React.useMemo(() => {
    const callback = async (text: string) => {
      setSearchText(text);

      if (text === "") {
        setMatchingItems(null);
        setNumMatching(0);
        return;
      }

      setIsFetching(true);
      const thisRequestNumber = ++requestCount.current;

      const response = await searchByText(text, MAX_RESULT_COUNT);

      // the result has been superseded by another
      if (thisRequestNumber < requestCount.current) return;

      for (const r of response.results) {
        itemNameCache.current[getIdentifier(r)] = r[displayedAttr];
      }

      setMatchingItems(response.results);
      setNumMatching(response.paging_info.total_results);

      setIsFetching(false);
    };

    return debounce(callback, 150);
  }, [displayedAttr, searchByText, getIdentifier]);

  // `Add ${n} matching` button in selections box.
  // Only appears if < 100 results
  let firstOption = null;
  if (matchingItems !== null) {
    if (isFetching) {
      // the `loading` prop of Select is not really a great fit for what we want here, so we can just
      // implement our own
      firstOption = (
        <Option disabled value="__NO_OP__">
          <Spin size="small" />
        </Option>
      );
    } else if (matchingItems.length === 0) {
      firstOption = (
        <Option disabled value={"__NO_OP__"}>
          No matches
        </Option>
      );
    } else if (numMatching < MAX_FILTER_BY) {
      firstOption = (
        <Option value={SELECT_ALL_ID} className={css.addMore}>
          <span className={css.addMoreOption}>
            <PlusOutlined /> Add {numMatching} matching
          </span>
        </Option>
      );
    } else {
      // TODO: Consider adding a hint that narrowing search enables "Add all" button
      firstOption = (
        <Option disabled value={"__NO_OP__"}>
          {narrowSearchLabel(numMatching)}
        </Option>
      );
    }
  }

  const additionalOptions = (
    matchingItems
      ? matchingItems.map((r) => (
          <Option key={getIdentifier(r)} value={getIdentifier(r)} title={`${r[displayedAttr]}`}>
            <Checkbox
              checked={itemIds.includes(getIdentifier(r))}
              onClick={(event) => event.preventDefault()}
            >
              {/*  event.preventDefault needs to be added  here, otherwise onChange function triggers twice */}
              <span className={css.optionLabel} onClick={(event) => event.preventDefault()}>
                {`${r[displayedAttr]}`}
                {renderIcon && renderIcon(r)}
              </span>
            </Checkbox>
          </Option>
        ))
      : (value.map(
          (v) => (
            <Option
              key={v.key}
              value={v.key}
              // Had to add a title prop to this label, otherwise when the label is clicked and doesn't have a title prop...
              // the optionLabelProp is ignored and the Select component doesn't identify that this value has been selected
              label={<span title={v.label?.toString()}>{v.label}</span>}
              title={v.label?.toString()}
            >
              <Checkbox
                checked={itemIds.includes(v.key)}
                onClick={(event) => event.preventDefault()}
              >
                {/*  event.preventDefault needs to be added here, otherwise onChange function triggers twice */}
                <span className={css.optionLabel} onClick={(event) => event.preventDefault()}>
                  {v.label}
                </span>
              </Checkbox>
            </Option>
          ),
          // we can recognise what these are, but typescript cannot
        ) as unknown)
  ) as React.ReactElement<OptionProps>;

  const options = [firstOption, additionalOptions];
  return { fetchRecords, handleChange, options, itemIds, value };
}

type Props<Item, Resp, IdentType extends string | number> = {
  itemIds: IdentType[];
  onItemsChange: (newRecordIds: IdentType[]) => void;
  placeholder: string;
  searchByText: (text: string, limit?: number) => Promise<Resp>;
  searchByIds: (ids: IdentType[]) => Promise<Resp>;
  displayedAttr: keyof Item;
  narrowSearchLabel: (count: number) => string;
  id?: string;
  noMaxTagCount?: boolean;
  getIdentifier: (item: Item) => IdentType;
  renderIcon?: (item: Item) => React.ReactNode;
} & SelectProps<LabeledValue<IdentType>[]>;

/**
 *
 * @deprecated This component is a little broken after the ant 5 upgrade and should be replaced or fixed
 * The ids from keys are strings as they come from the Options components and are read as keys
 * There are hacky fixes in the buyer and supplier multi selects to convert the ids to the correct type
 * because of an urgent prod support issue, but this needs fixing here.
 *
 * I don't think the LabeledValue<IdentType extends string | number> type is correct, and I think we should figure out what is
 * actually being returned first and then fix the type
 *
 * We also need to move from option components to option objects as the option components are deprecated and will be removed in antd 6
 *
 * If you are updating this component, please contact @mholtom for any questions
 *
 */
export function AutocompleteMultiselect<
  Item extends Record<string, unknown>,
  Resp extends SearchResponse<Item>,
  IdentType extends string | number,
>({
  onItemsChange,
  searchByIds,
  searchByText,
  getIdentifier,
  itemIds,
  noMaxTagCount,
  displayedAttr,
  narrowSearchLabel,
  renderIcon,
  ...rest
}: Props<Item, Resp, IdentType>): JSX.Element {
  const { value, fetchRecords, handleChange, options } = useMultiSelectable<Item, Resp, IdentType>({
    onItemsChange,
    searchByIds,
    searchByText,
    getIdentifier,
    itemIds,
    displayedAttr,
    narrowSearchLabel,
    renderIcon,
  });
  return (
    <Select<LabeledValue<IdentType>[]>
      mode="multiple"
      showSearch
      suffixIcon={null}
      labelInValue
      value={value}
      notFoundContent={null}
      onChange={handleChange}
      filterOption={false}
      onSearch={fetchRecords}
      style={{ width: "100%" }}
      maxTagCount={noMaxTagCount ? undefined : MAX_TAG_COUNT}
      maxTagPlaceholder={`${value.length - MAX_TAG_COUNT} more...`}
      popupClassName={css.dropdown}
      {...rest}
    >
      {options}
    </Select>
  );
}
