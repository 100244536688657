import * as React from "react";

export default function RightChevron(): JSX.Element {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.52827 0.529247C0.788619 0.268897 1.21073 0.268897 1.47108 0.529247L5.47108 4.52925C5.73143 4.7896 5.73143 5.21171 5.47108 5.47206L1.47108 9.47206C1.21073 9.73241 0.788619 9.73241 0.52827 9.47206C0.26792 9.21171 0.26792 8.7896 0.52827 8.52925L4.05687 5.00065L0.52827 1.47206C0.26792 1.21171 0.26792 0.789596 0.52827 0.529247Z"
        fill="#263247"
      />
    </svg>
  );
}
