import * as React from "react";
import { Button, Checkbox, Form, Input, message, Modal, Select, Typography } from "antd"; // remove typography, fix form, beware global styles, verify visuals
import { FormComponentProps } from "antd/lib/form";
import classnames from "classnames";

import { WithStotlesApi, withStotlesApi } from "lib/stotlesApiContext";
import * as tracking from "lib/tracking";
import { BasicSignal, getSignalTypes } from "lib/types/models";
import { EMPTY_ARRAY } from "lib/utils";

import css from "./ShareModal.module.scss";

type Record = {
  guid: string;
  signals?: BasicSignal[];
  stage?: string | null;
  score: number;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  records: Record[];
};

const EMAIL_FORM_ID = "email-form";
const INPUT_TOKEN_SEPARATORS = [",", " ", "\n"];
const emailRegexpStr = "[^@]*@(?:[^@.]+\\.\\w+){1,}";
// at least one email address, follwed by 0 or more of (,(other email address))
// eg.
// ✓ test@example.com
// ✓ test@example.com,test1@example.com
// ✗ test@example.com,test1@example.com,
// ✗ test@example.com+test1@example.com
const COMMA_SEPARATED_EMAILS = new RegExp(`${emailRegexpStr}(?:,${emailRegexpStr})*`);

type BasicFormProps = {
  records: Record[];
  onClose: Props["onClose"];
};

type FullFormProps = FormComponentProps & BasicFormProps;
type State = { recipients: string[] };

class EmailForm extends React.Component<WithStotlesApi<FullFormProps>, State> {
  state = {
    recipients: [],
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { form, api, onClose, records } = this.props;

    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        const signalTypesTracking: string[] = [];
        const allSignals: BasicSignal[] = [];
        const stagesTracking: string[] = [];

        const recordDetails = records.map((record) => {
          if (record.signals) {
            allSignals.push(...record.signals);
          }
          const signalTypes = getSignalTypes(record.signals);
          if (signalTypes) {
            Object.keys(signalTypes).forEach((signalType) => {
              signalTypesTracking.push(signalType);
            });
          }

          if (record.stage) {
            stagesTracking.push(record.stage);
          }

          return {
            guid: record.guid,
            Stage: record.stage,
            "Signal types": signalTypes ? Object.keys(signalTypes) : [],
            "Signal score": record.score,
            Signals: signalTypes,
          };
        });

        //filter out duplicate signals across selected records
        const uniqueSignals = allSignals.filter(
          (signal, index, self) => index === self.findIndex((s) => s.id === signal.id),
        );

        tracking.logEvent(tracking.EventNames.shareEmailSent, {
          "Form values": values,
          Records: recordDetails,
          "Signal types": [...new Set(signalTypesTracking)],
          Signals: getSignalTypes(uniqueSignals),
          Stages: [...new Set(stagesTracking)],
        });

        try {
          await api.submitShareViaEmailForm(values);
          onClose();
          message.success("Sent", 2);
        } catch (e) {
          message.error("Email could not be sent", 2);
          throw e;
        }
      }
    });
  };

  componentDidMount() {
    const { form, records } = this.props;
    form.setFieldsValue({ guids: records.map((record) => record.guid).join("\n") });
  }

  handleRecipientsChange = (newRecipients: string[]) => {
    this.setState({ recipients: newRecipients });
    this.props.form.setFieldsValue({ email: newRecipients.join(",") });
  };

  render() {
    const { form } = this.props;

    return (
      <Form
        id={EMAIL_FORM_ID}
        action="/api/records/share"
        method="POST"
        onSubmit={this.handleSubmit}
      >
        <Form.Item label="To" className={classnames(css.item, css.normalLineHeight)}>
          <Select<string[]>
            // for now we are hding the dropdown and not giving this select any options, but in the
            // near future this is likely to be populated by a list of preferred contacts from the
            // back end
            mode="tags"
            className={css.fullWidth}
            open={false}
            tokenSeparators={INPUT_TOKEN_SEPARATORS}
            onChange={this.handleRecipientsChange}
            value={this.state.recipients}
          >
            {EMPTY_ARRAY}
          </Select>
          <small>
            Hint: Add multiple recipients to your email by using a comma or space between addresses.
          </small>
          {form.getFieldDecorator("email", {
            rules: [
              { required: true, message: "A recipient is required" },
              {
                message: "Please enter a valid email address",
                pattern: COMMA_SEPARATED_EMAILS,
              },
            ],
          })(<Input name="email" hidden />)}
        </Form.Item>
        {!window.currentUser && (
          <Form.Item label="From" className={css.item}>
            {form.getFieldDecorator("sender_email", {
              rules: [
                { required: true, message: "A sender is required" },
                {
                  message: "Please enter a valid email address",
                  pattern: new RegExp(emailRegexpStr),
                },
              ],
            })(<Input name="sender_email" />)}
          </Form.Item>
        )}
        <Form.Item label="Optional Message" className={css.item}>
          {form.getFieldDecorator("message")(<Input.TextArea rows={5} name="message" />)}
        </Form.Item>
        <Form.Item className={css.itemHidden}>
          {form.getFieldDecorator("guids")(<Input.TextArea hidden name="guids" />)}
        </Form.Item>
        <Form.Item label="CC me in the email" className={classnames(css.item, css.ccItem)}>
          {form.getFieldDecorator("cc")(<Checkbox name="cc" />)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedEmailForm = Form.create<FullFormProps>({ name: "shareViaEmailForm" })(
  withStotlesApi(EmailForm),
);

function ShareModal({ isOpen, onClose, records }: Props): JSX.Element {
  const footer = React.useMemo(
    () => [
      <Button key="cancel" onClick={onClose}>
        Cancel
      </Button>,
      <Button key="send" type="primary" form={EMAIL_FORM_ID} htmlType="submit">
        Send
      </Button>,
    ],
    [onClose],
  );

  return (
    <Modal visible={isOpen} closable onCancel={onClose} footer={footer}>
      <Typography.Title level={2}>Share via email</Typography.Title>
      <WrappedEmailForm records={records} onClose={onClose} />
    </Modal>
  );
}

export default ShareModal;
