import React from "react";
import classNames from "classnames";

import PaywallStar from "lib/icons/PaywallStar";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import { DataTypeNameWithBuyersAndSuppliers, SignalCategory } from "lib/types/models";

import css from "./paywallUtils.module.scss";

import AccountBriefingPaywall from "../../../assets/images/paywall/Account_Briefing_Paywall.svg";
import AccountBriefingPaywallSmall from "../../../assets/images/paywall/Account_Briefing_Paywall_Small.svg";
import BuyersPaywall from "../../../assets/images/paywall/Buyers_Paywall.svg";
import BuyersPaywallSmall from "../../../assets/images/paywall/Buyers_Paywall_Small.svg";
import CompetitorsPaywall from "../../../assets/images/paywall/Competitors_Paywall.svg";
import CompetitorsPaywallSmall from "../../../assets/images/paywall/Competitors_Paywall_Small.svg";
import ContactsPaywall from "../../../assets/images/paywall/Contacts_Paywall.svg";
import ContactsPaywallSmall from "../../../assets/images/paywall/Contacts_Paywall_Small.svg";
import ContractExpiriesPaywall from "../../../assets/images/paywall/Contracts_Paywall.svg";
import ContractExpiriesPaywallSmall from "../../../assets/images/paywall/Contracts_Paywall_Small.svg";
import DocumentsPaywall from "../../../assets/images/paywall/Documents_Paywall.svg";
import DocumentsPaywallSmall from "../../../assets/images/paywall/Documents_Paywall_Small.svg";
import FrameworksPaywall from "../../../assets/images/paywall/Frameworks_Paywall.svg";
import GuestAccessUpgradePaywall from "../../../assets/images/paywall/Guest_Access_Upgrade_Paywall.svg";
import PartnersPaywall from "../../../assets/images/paywall/Partners_Paywall.svg";
import PartnersPaywallSmall from "../../../assets/images/paywall/Partners_Paywall_Small.svg";
import SpendPaywall from "../../../assets/images/paywall/Spend_Paywall.svg";
import SpendPaywallSmall from "../../../assets/images/paywall/Spend_Paywall_Small.svg";
import SuppliersPaywall from "../../../assets/images/paywall/Suppliers_Paywall.svg";
import SuppliersPaywallSmall from "../../../assets/images/paywall/Suppliers_Paywall_Small.svg";

// ProHelperDataTypes is a subset of FeatureType
export type FeatureType =
  // If changing FeatureType, make sure to update the paywallContentTestCases in PaywallTestHelpers.ts to match
  | "AWARDS"
  | "COMPETITORS"
  | "PARTNERS"
  | "BUYERS"
  | "SUPPLIERS"
  | "CONTACTS"
  | "SPEND"
  | "DOCUMENTS"
  | "ACCOUNT_BRIEFINGS"
  | "GUEST_ACCESS_UPGRADE"
  | "FRAMEWORKS";

type PaywallType = "BANNER" | "MODAL" | "POPOVER";

/**
 * Even though most of the time, ProHelperDataTypes fits neatly into FeatureType as it is a subset of it,
 * there are some cases where it can be undefined. To ensure a safe conversion, we'll default to SUPPLIERS as
 * that seems like the most likely/common ambigious case we have
 */
export function convertDataTypeToFeatureType(datatype?: ProHelperDataTypes): FeatureType {
  return datatype ? (datatype as FeatureType) : "SUPPLIERS";
}

/**
 * Currently the toggle to enable data sources in the back end doesn't match FeatureType so we have to do this
 * @param featureType
 * @returns
 */
export function convertFeatureTypeToDataType(
  featureType: FeatureType,
): DataTypeNameWithBuyersAndSuppliers {
  // TODO: These features are currently not paywalled via data sources toggle in admin panel, but there are plans to do so
  const unblockedFeatures = ["ACCOUNT_BRIEFINGS", "DOCUMENTS"];

  const extraFeatures = ["SPEND", "COMPETITORS", "PARTNERS"];

  // Effectively a no-op
  if (unblockedFeatures.includes(featureType)) {
    return "TENDERS|PRE-TENDERS";
  } else if (extraFeatures.includes(featureType)) {
    return "BUYERS|SUPPLIERS";
  } else return featureType as DataTypeNameWithBuyersAndSuppliers;
}

/**
 * Converts signal category to corresponding feature type. We prioritisie relating the paywall content to the redacted signal category,
 * but if it isn't a competitor signal or a partner signal, we'll fall back to the datatype (which mostly has a corresponding FeatureType)
 * @param signalCategory
 */
export function convertSignalCategoryToFeatureType(
  signalCategory: SignalCategory,
  fallbackdDatatype?: ProHelperDataTypes,
): FeatureType {
  switch (signalCategory) {
    case "Competitors":
      return "COMPETITORS";
    case "Partners":
      return "PARTNERS";
    default:
      // Most of the time, this will default to SUPPLIERS, but if the dataType is actually defined -
      // it will show the paywall relating to that datatype
      return convertDataTypeToFeatureType(fallbackdDatatype);
  }
}

type Content = {
  title: string;
  description: string;
  bullets?: string[];
  plantype?: "Growth" | "Expert";
};

type PaywallContent = Record<FeatureType, Content>;

export function getPaywallContent(featureType: FeatureType): Content {
  const paywallContent: PaywallContent = {
    AWARDS: {
      title: "Upgrade to unlock contract award data",
      description:
        "Identify expiring contracts that can be used to spot upcoming opportunities and build relationships with the buyer, before the tender is written.",
      bullets: [
        "Use early buying signals to get ahead of renewals and downstream opportunities.",
        "Achieve your sales goals faster by pushing notices straight to your CRM.",
        "Uncapped exports on contract award data.",
      ],
      plantype: "Growth",
    },

    PARTNERS: {
      title: "Upgrade to unlock partner intelligence",
      description:
        "Map your partner network to identify contracts they've won, as well as upcoming opportunities you can pursue.",
      bullets: [
        "Gain a comprehensive view of partner activity through supplier dashboards.",
        "Map out relationships your partners already have with key buyers, to easily pinpoint potential opportunities.",
      ],
      plantype: "Growth",
    },

    COMPETITORS: {
      title: "Upgrade to unlock competitor intelligence",
      description:
        "Map your competitors to identify contracts they've won and upcoming opportunities you can infiltrate.",
      bullets: [
        "Identify and track potential competitors winning business in your space.",
        "Qualify buyers to approach based on existing competitor relations.",
      ],
      plantype: "Growth",
    },

    SUPPLIERS: {
      title: "Upgrade to unlock supplier intelligence",
      description:
        "View profiles of potential partners and competitors, identifying contracts they've won and upcoming opportunities you can pursue.",
      bullets: [
        "Review contract wins, buyers supplied, and industry analysis for any supplier.",
        "Identify potential collaborators, competitors, and upcoming opportunities.",
      ],
      plantype: "Growth",
    },

    BUYERS: {
      title: "Upgrade to unlock buyer intelligence",
      description:
        " View profiles for target accounts and existing customers, identifying contracts they've won and upcoming opportunities you can pursue. ",
      bullets: [
        "Review contract awards, top suppliers and strategic documents for any buyer.",
        "Easily navigate to open and upcoming expiries matching your keywords.",
        "Uncapped exports on buyer data. ",
      ],
      plantype: "Growth",
    },

    CONTACTS: {
      title: "Upgrade to unlock decision maker contacts",
      description: "Go beyond procurement contacts and reach out to decision makers directly.",
      bullets: [
        "Effortlessly identify the relevant decision makers for any opportunity or target account, and easily send them to your CRM.",
        "Generate personalised outreach emails in seconds with Stotles AI.",
        "Uncapped exports on decision maker contacts.",
      ],
      plantype: "Growth",
    },

    DOCUMENTS: {
      title: "Upgrade to unlock strategic documents",
      description:
        "Get access to thousands of buyer-related documents, including budgets, meeting minutes, delivery plans, strategy documents, and more.",
      bullets: [
        "Gain deeper insights with strategy documents and build deeper relationships with your target accounts.",
        "Easily identify buying signals to uncover future opportunities.",
      ],
      plantype: "Growth",
    },

    SPEND: {
      title: "Upgrade to unlock spend data",
      description:
        "Analyse invoice-level spend data that helps you uncover deeper relationships, opportunities, and insights.",
      bullets: [
        "Accurately forecast your total addressable market and identify target accounts.",
        "Track relationships between buyers and competitors, and identify market leaders in your industry.",
        "Uncapped exports on spend data.",
      ],
      plantype: "Growth",
    },
    ACCOUNT_BRIEFINGS: {
      title: "Upgrade to unlock custom briefings, tailored to you",
      description:
        "A suite of custom briefings designed to help commercial teams save hundreds of hours researching and spend more time closing deals.",
      plantype: "Expert",
    },
    GUEST_ACCESS_UPGRADE: {
      title: "Book a demo",
      description:
        "As a guest pass user, certain functionalities are restricted. To unlock a more personalised experience and explore the full potential of Stotles data, schedule a demo with our sales team. With Stotles, you can:",
      bullets: [
        "Get ahead of tenders and use our data & tools to approach the right buyers, with the right message, at the right time.",
        "Connect with decision-makers.",
        "Explore potential partners and competitors.",
        "Analyse detailed spend data for deeper insights. Book a demo now for a comprehensive overview.",
      ],
    },
    FRAMEWORKS: {
      title: "Upgrade to unlock Framework data",
      description:
        "Sift through thousands of frameworks in minutes to discover and qualify the most valuable opportunities and routes to market for your business.",
      bullets: [
        "Find your target buyers' preferred frameworks",
        "Use supplier and call-off data to identify potential partners",
        "Find relevant openings with competitor intelligence and expiry data",
      ],
      plantype: "Growth",
    },
  };

  return paywallContent[featureType];
}

export function PaidTag(): JSX.Element {
  return (
    <span className={css.paidTag}>
      <PaywallStar /> PAID FEATURE
    </span>
  );
}

// Larger images that go on banners & modals, smaller ones go on popovers. The difference
// between the two types of images is that the "Small" ones have less detail within them
type PaywallImgProps = {
  context: PaywallType;
  featureType: FeatureType;
  className?: string;
};

export function PaywallImg({ context, featureType, className }: PaywallImgProps): JSX.Element {
  const paywallBackground = (featureType: FeatureType): string => {
    switch (featureType) {
      case "COMPETITORS":
      case "SPEND":
        return css.redStotlesBackground;

      case "DOCUMENTS":
      case "PARTNERS":
        return css.yellowStotlesBackground;

      case "SUPPLIERS":
      case "BUYERS":
        return css.purpleStotlesBackground;

      default:
        return css.blueStotlesBackground;
    }
  };

  const paywallImg = (featureType: FeatureType): string => {
    switch (featureType) {
      case "AWARDS":
        return context === "POPOVER" ? ContractExpiriesPaywallSmall : ContractExpiriesPaywall;

      case "COMPETITORS":
        return context === "POPOVER" ? CompetitorsPaywallSmall : CompetitorsPaywall;

      case "PARTNERS":
        return context === "POPOVER" ? PartnersPaywallSmall : PartnersPaywall;

      case "SUPPLIERS":
        return context === "POPOVER" ? SuppliersPaywallSmall : SuppliersPaywall;

      case "BUYERS":
        return context === "POPOVER" ? BuyersPaywallSmall : BuyersPaywall;

      case "CONTACTS":
        return context === "POPOVER" ? ContactsPaywallSmall : ContactsPaywall;

      case "SPEND":
        return context === "POPOVER" ? SpendPaywallSmall : SpendPaywall;

      case "DOCUMENTS":
        return context === "POPOVER" ? DocumentsPaywallSmall : DocumentsPaywall;

      case "ACCOUNT_BRIEFINGS":
        return context === "POPOVER" ? AccountBriefingPaywallSmall : AccountBriefingPaywall;

      case "GUEST_ACCESS_UPGRADE":
        return GuestAccessUpgradePaywall;

      case "FRAMEWORKS":
        return FrameworksPaywall;
    }
  };

  return (
    <div
      data-testid="paywall-img-container"
      className={classNames(
        paywallBackground(featureType),
        {
          [css.bannerImg]: context === "BANNER",
          [css.modalImg]: context === "MODAL",
        },
        className,
      )}
    >
      <img
        src={paywallImg(featureType)}
        alt="Paywall"
        className={context === "BANNER" ? css.imgLarge : css.img}
      />
    </div>
  );
}
