import * as React from "react";
import linkify from "linkify-it";

import {
  MatchType,
  TextHighlighterMatch,
  TextMatchHighlighter,
} from "lib/core_components/TextMatchHighlighter";
import { useAnnotateNotice } from "./api/notices/useAnnotateNotice";

export function useRecordHighlighting(
  noticeGuid: string,
  noticeName: string,
  noticeDescription?: string | null,
): { recordTitle: React.ReactNode; recordDescription: React.ReactNode } {
  const { data: recordMatchData } = useAnnotateNotice(noticeGuid);

  const [recordTitle, recordDescription] = React.useMemo(() => {
    let title: React.ReactNode = noticeName;
    let description: React.ReactNode = noticeDescription;
    const descriptionMatches: TextHighlighterMatch[] = noticeDescription
      ? linkify()
          .match(noticeDescription)
          ?.map((m) => ({
            start: m.index,
            end: m.lastIndex,
            keyword: m.text,
            matchType: MatchType.Link,
          })) || []
      : [];
    if (recordMatchData) {
      const titleMatches: TextHighlighterMatch[] = [];

      for (const m of recordMatchData.keyword_matches) {
        if (m.field === "title") {
          titleMatches.push({
            ...m,
            matchType: MatchType.Keyword,
          });
        } else if (m.field === "description") {
          descriptionMatches.push({
            ...m,
            matchType: MatchType.Keyword,
          });
        }
      }

      if (titleMatches.length) {
        title = <TextMatchHighlighter text={noticeName} matches={titleMatches} />;
      }
    }
    if (noticeDescription && descriptionMatches.length) {
      description = <TextMatchHighlighter text={noticeDescription} matches={descriptionMatches} />;
    }

    return [title, description];
  }, [noticeDescription, noticeName, recordMatchData]);

  return { recordTitle, recordDescription };
}
