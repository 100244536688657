import * as React from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Menu } from "antd"; // upgrade to items style menu, check how this looks after upgrading
import { MenuItemProps } from "antd/lib/menu/MenuItem"; // don't forget this

import PaywallModal, { PaywallModalProps } from "components/paywall/PaywallModal";
import { FeatureType } from "components/paywall/paywallUtils";
import { GLOBAL_DOCUMENTS, NEW_SUPPLIER_FILTER } from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import PaywallStar from "lib/icons/PaywallStar";
import { useDialogManager } from "lib/providers/DialogManager";
import { useSubscription } from "lib/providers/Subscription";
import { TrackingProvider } from "lib/tracking";
import { getLinkDestination } from "../actions/Links";

import css from "./ProductNav.module.scss";

enum MenuItemTypes {
  TOP_LEVEL = "TOP_LEVEL",
  SUBMENU_TOP = "SUBMENU_TOP",
  SUBMENU_ITEM = "SUBMENU_ITEM",
  DIVIDER = "DIVIDER",
  ITEM_GROUP_TITLE = "ITEM_GROUP_TITLE",
}

// A nav item which is on its own in the top level nav
type TopLevelLink = {
  type: MenuItemTypes.TOP_LEVEL;
  label: string | JSX.Element;
  link?: string;
  pathnameMatch: RegExp;
  key: string;
  onClick?: () => void;
};

// Top level nav item which expands a submenu
type SubmenuTopLink = {
  type: MenuItemTypes.SUBMENU_TOP;
  label: string;
  pathnameMatch: RegExp;
  link?: string;
  key: string;
  submenuItems: MenuConfigTypes[];
};

// A MenuItem within the submenu
type SubmenuItem = {
  type: MenuItemTypes.SUBMENU_ITEM;
  label: string | JSX.Element;
  description: string;
  link?: string;
  key: string;
  onClick?: () => void;
};

type DividerType = {
  type: MenuItemTypes.DIVIDER;
};

type ItemGroupTitle = {
  type: MenuItemTypes.ITEM_GROUP_TITLE;
  title: string;
};

type MenuConfigTypes = SubmenuItem | DividerType | ItemGroupTitle | SubmenuTopLink | TopLevelLink;
type MenuConfig = (TopLevelLink | SubmenuTopLink)[];

function MenuSubHeading({ title, key }: { title: string; key: string }): JSX.Element {
  return (
    <p key={key} className={css.itemGroupTitle}>
      {title}
    </p>
  );
}

function SimpleMenuItem({
  label,
  link,
  key,
  description,
  ...props
}: {
  label: string | JSX.Element;
  description?: string;
  link?: string;
  key: string;
} & MenuItemProps) {
  const linkNode = (
    <a
      className={css.menuItemContent}
      // important to ensure that the link will be valid eg. ensure it starts
      // with http if external, not just www
      href={link ? getLinkDestination(link) : undefined}
    >
      <h4 className={css.menuItemTitle}>{label}</h4>
      {description && <p className={css.menuItemDesc}>{description}</p>}
    </a>
  );
  return (
    <Menu.Item {...props} key={key}>
      {linkNode}
    </Menu.Item>
  );
}

function matchesPath(c: TopLevelLink | SubmenuTopLink, pathname: string) {
  return ("link" in c && pathname === c.link) || c.pathnameMatch.test(pathname);
}

function extractSelectedKey(menuConfig: MenuConfig, pathname: string) {
  return menuConfig.find((c) => matchesPath(c, pathname))?.key;
}

function renderMenuItems(configTypes: MenuConfigTypes[]) {
  const menuItems: JSX.Element[] = [];
  for (const [i, configItem] of configTypes.entries()) {
    switch (configItem.type) {
      case MenuItemTypes.TOP_LEVEL:
        menuItems.push(
          <SimpleMenuItem
            className={css.topLevelMenuItem}
            key={configItem.key}
            label={configItem.label}
            link={configItem.link}
            onClick={configItem.onClick}
          />,
        );
        break;
      case MenuItemTypes.SUBMENU_TOP:
        menuItems.push(
          <Menu.SubMenu
            className={css.topLevelMenuItem}
            title={
              <>
                {configItem.label} <CaretDownOutlined className={css.submenuDownIcon} />
              </>
            }
            // This class hides the caret-down icon when subemnu children overflow
            popupClassName={css.hideIconOnOverflow}
            // This prop allows us to display the menu items directly below the submenu titles
            popupOffset={[-16, 6]}
            key={configItem.key}
            onTitleClick={() =>
              configItem.link
                ? (window.location.href = getLinkDestination(configItem.link))
                : undefined
            }
          >
            {/* This extra menu item a hacky solution to getting the submenu title to receive the selected class */}
            {/* Ant requires that the parent AND the child's keys are both in selected keys */}
            <Menu.Item key={configItem.key} style={{ display: "none" }} />

            {renderMenuItems(configItem.submenuItems)}
          </Menu.SubMenu>,
        );

        break;
      case MenuItemTypes.SUBMENU_ITEM:
        menuItems.push(
          <SimpleMenuItem
            className={css.submenuItem}
            label={configItem.label}
            description={configItem.description}
            link={configItem.link ?? undefined}
            key={configItem.key}
            onClick={configItem.onClick}
          />,
        );
        break;
      case MenuItemTypes.ITEM_GROUP_TITLE:
        menuItems.push(<MenuSubHeading title={configItem.title} key={configItem.title} />);
        break;
      case MenuItemTypes.DIVIDER:
        menuItems.push(<Menu.Divider key={i} />);
        break;
      default:
        throw "Unsupported menu config type";
    }
  }
  return menuItems;
}

function ProductNav(): JSX.Element {
  const {
    currentUser,
    location: { pathname },
  } = window;

  const subscription = useSubscription();
  const dialogManager = useDialogManager();
  const buyerSupplierPaywall = !subscription.hasDataTypes("BUYERS|SUPPLIERS");
  const frameworksPaywall = !subscription.hasDataTypes("FRAMEWORKS");
  const accountBriefingsPaywall = !currentUser?.has_account_briefing_permission;
  const isGlobalDocumentsEnabled = useVariableValue(GLOBAL_DOCUMENTS, false);
  const isNewSupplierFilterEnabled = useVariableValue(NEW_SUPPLIER_FILTER, false);

  const openPaywallModal = React.useCallback(
    (featureType: FeatureType) => {
      dialogManager.openDialog(PaywallModal, {
        featureType: featureType,
      } as Omit<PaywallModalProps, "isOpen" | "onClose">);
    },
    [dialogManager],
  );

  const recordSubmenuConfig: MenuConfigTypes[] = React.useMemo(() => {
    const config: MenuConfigTypes[] = [];

    config.push(
      {
        type: MenuItemTypes.SUBMENU_ITEM,
        label: "Find notices",
        link: "/",
        key: "findNotices",
        description: "Search all published public sector procurement notices.",
      },
      {
        type: MenuItemTypes.SUBMENU_ITEM,
        label: "My notices",
        link: "/my-notices",
        key: "notices",
        description: "View all of your action notices; qualified, assigned and saved.",
      },
    );

    return config;
  }, []);

  // This is a dynamic config fed to the renderMenuItems which generates the correct components
  const menuConfig: MenuConfig = React.useMemo(() => {
    const config: MenuConfig = [];
    if (!currentUser) return [];
    config.push({
      type: MenuItemTypes.SUBMENU_TOP,
      label: "Notices",
      key: "records",
      pathnameMatch: /^\/(lists|search|notices.*|(\?.+))?$/, // Match root or root followed by query string OR starting with `/lists` OR `/search`
      submenuItems: recordSubmenuConfig,
    });

    if (FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS)) {
      config.push({
        type: MenuItemTypes.TOP_LEVEL,
        label: (
          <span className={css.menuItemLabel}>
            Frameworks{frameworksPaywall && <PaywallStar />}
          </span>
        ),
        link: frameworksPaywall ? undefined : "/frameworks",
        pathnameMatch: /\/frameworks/,
        key: "frameworks",
        onClick: () => {
          if (frameworksPaywall) openPaywallModal("FRAMEWORKS");
        },
      });
    }

    const buyersSubmenuConfig: MenuConfigTypes[] = [];

    buyersSubmenuConfig.push({
      type: MenuItemTypes.SUBMENU_ITEM,
      label: "Find buyers",
      link: "/buyers/all-buyers",
      key: "all-buyers",
      description: "Search all public sector buyers.",
    });

    buyersSubmenuConfig.push({
      type: MenuItemTypes.SUBMENU_ITEM,
      label: "Buyer lists",
      link: "/buyers/lists",
      key: "saved-buyer-lists",
      description: "View all of your saved buyer lists.",
    });

    buyersSubmenuConfig.push({
      type: MenuItemTypes.SUBMENU_ITEM,
      label: "Find contacts",
      link: "/contacts",
      key: "contacts",
      description: "Search for decision-maker contacts within buyer organisations.",
    });

    config.push({
      type: MenuItemTypes.SUBMENU_TOP,
      label: "Buyers",
      key: "buyers",
      pathnameMatch: /^\/(buyers|contacts)/,
      submenuItems: buyersSubmenuConfig,
    });

    const suppliersSubmenuConfig: MenuConfigTypes[] = [];
    if (isNewSupplierFilterEnabled && window.currentUser?.use_supplier_name === false) {
      config.push({
        type: MenuItemTypes.TOP_LEVEL,
        label: "Suppliers",
        pathnameMatch: /^\/(suppliers)/,
        key: "suppliers",
        link: "/suppliers/search",
      });
    } else {
      config.push({
        type: MenuItemTypes.SUBMENU_TOP,
        label: "Suppliers",
        pathnameMatch: /^\/(suppliers|partners|competitors)/,
        key: "suppliers",
        submenuItems: suppliersSubmenuConfig,
      });
    }

    suppliersSubmenuConfig.push({
      type: MenuItemTypes.SUBMENU_ITEM,
      label: "Find suppliers",
      link: "/suppliers/search",
      key: "suppliers",
      description: "Search all companies awarded contracts by public sector buyers.",
    });

    suppliersSubmenuConfig.push({
      type: MenuItemTypes.SUBMENU_ITEM,
      label: (
        <span className={css.menuItemLabel}>
          My competitors{buyerSupplierPaywall && <PaywallStar />}
        </span>
      ),

      link: buyerSupplierPaywall ? undefined : "/competitors",
      onClick: () => {
        if (buyerSupplierPaywall) openPaywallModal("COMPETITORS");
      },
      key: "competitors",
      description: "Monitor competitor activity and relationships.",
    });

    suppliersSubmenuConfig.push({
      type: MenuItemTypes.SUBMENU_ITEM,
      label: (
        <span className={css.menuItemLabel}>
          My partners{buyerSupplierPaywall && <PaywallStar />}
        </span>
      ),
      link: buyerSupplierPaywall ? undefined : "/partners",
      onClick: () => {
        if (buyerSupplierPaywall) openPaywallModal("PARTNERS");
      },
      key: "partners",
      description: "Monitor partner activity and relationships.",
    });

    config.push({
      type: MenuItemTypes.SUBMENU_TOP,
      label: "Reports",
      pathnameMatch: /^\/reports/,
      key: "reports",
      submenuItems: [
        {
          type: MenuItemTypes.SUBMENU_ITEM,
          label: (
            <span className={css.menuItemLabel}>
              Custom briefings {accountBriefingsPaywall && <PaywallStar />}
            </span>
          ),
          link: "/reports/custom",
          key: "customReports",
          description: "View all custom briefings created for your team.",
        },
        {
          type: MenuItemTypes.SUBMENU_ITEM,
          label: "Industry reports",
          link: "/reports/industry",
          key: "industryReports",
          description: "Data-backed reports on industry movement and supplier opportunities.",
        },
      ],
    });

    if (FeatureToggles.isEnabled(Feature.SPEND_DATA)) {
      config.push({
        type: MenuItemTypes.TOP_LEVEL,
        label: (
          <div className={css.topLevelLabel}>Spend{buyerSupplierPaywall && <PaywallStar />}</div>
        ),

        link: buyerSupplierPaywall ? undefined : "/spend",
        onClick: () => {
          if (buyerSupplierPaywall) openPaywallModal("SPEND");
        },
        pathnameMatch: /\/spend/,
        key: "spend",
      });
    }

    if (isGlobalDocumentsEnabled) {
      config.push({
        type: MenuItemTypes.TOP_LEVEL,
        label: <div className={css.topLevelLabel}>Documents</div>,
        link: "/documents",
        pathnameMatch: /\/documents/,
        key: "documents",
      });
    }

    return config;
  }, [
    currentUser,
    recordSubmenuConfig,
    buyerSupplierPaywall,
    accountBriefingsPaywall,
    isGlobalDocumentsEnabled,
    frameworksPaywall,
    openPaywallModal,
    isNewSupplierFilterEnabled,
  ]);

  const menuItems: JSX.Element[] = React.useMemo(() => {
    return renderMenuItems(menuConfig);
  }, [menuConfig]);

  const selectedKeys = React.useMemo(() => {
    const key = extractSelectedKey(menuConfig, pathname);
    return key ? [key] : [];
  }, [menuConfig, pathname]);

  return (
    <TrackingProvider
      data={{
        "Context source": "Navigation bar",
      }}
    >
      <Menu
        className={css.navMenu}
        mode="horizontal"
        selectedKeys={selectedKeys}
        overflowedIndicator={
          <>
            More &nbsp;
            <CaretDownOutlined className={css.submenuDownIcon} />
          </>
        }
      >
        {menuItems}
      </Menu>
    </TrackingProvider>
  );
}
export default ProductNav;
