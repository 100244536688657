import * as React from "react";
import { forwardRef, Ref, useImperativeHandle } from "react";
import moment from "moment";

import { DEFAULT_PREVIEW_LOOKBACK } from "components/account_management/feed_settings/Preview";
import RecordCardList from "components/feed/RecordCardList";
import RecordPreviewHeading from "components/feed/RecordPreviewHeading";
import {
  filterRecords,
  getSignalFiltersData,
  SignalFiltersData,
} from "components/onboarding/onboardingUtils";
import {
  FeedPreviewPageSources,
  PreviewSignalFilters,
  SignalFilterCategoryData,
} from "components/onboarding/SharedOnboarding";
import { RecordPreview } from "lib/types/models";
import { PreviewGuidMatchMap, SelfServeQuery, Signal, SignalCategory } from "../../lib/StotlesApi";

import css from "./FeedPreviewOutputs.module.scss";

type Props = {
  results: RecordPreview[];
  loading: boolean;
  guidMatchMap?: PreviewGuidMatchMap;
  signalFiltersData?: SignalFiltersData;
  allSignals?: Signal[];
  pageSource: FeedPreviewPageSources;
  signalMap?: Map<string, Signal>;
  feedCriteria: SelfServeQuery;
  companyId?: number;
};

type FeedPreviewOutputRef = {
  /**
   * allow external components to override/set filter options
   * */

  setFilterOptions: (selectedDataSets: string[]) => void;
};

function FeedPreviewOutputs(
  {
    results,
    loading,
    guidMatchMap,
    signalFiltersData: filterDataSets,
    pageSource,
    allSignals,
    signalMap,
    feedCriteria,
    companyId,
  }: Props,
  ref: Ref<FeedPreviewOutputRef>,
) {
  const [checkboxFilters, setCheckboxFilters] = React.useState<PreviewSignalFilters>({});
  const [checkedKeys, setCheckedKeys] = React.useState<PreviewSignalFilters>({});
  const [dateFilter, setDateFilter] = React.useState<Date | undefined>();

  // Function that gets fired when someone clicks a filter checkbox
  const handleFilterCheck = React.useCallback((checkObject?: PreviewSignalFilters) => {
    // We use this to reset the filter
    if (!checkObject) {
      setCheckedKeys({});
      setCheckboxFilters({});
    } else {
      setCheckedKeys((oldCheckedKeys) => ({ ...oldCheckedKeys, ...checkObject }));
      const filterType = Object.keys(checkObject)[0] as SignalCategory; // checkObject should only ever have one key
      const filterOptions = checkObject[filterType]?.filter((k) => !k.includes("parent:"));
      setCheckboxFilters((oldFilters) => ({ ...oldFilters, ...{ [filterType]: filterOptions } }));
    }
  }, []);

  const handleDateFilter = (date?: Date) => {
    setDateFilter(date);
  };
  // Every time the search inputs change we need to recalculate applied and available filter
  // Remove any filters options that are no longer relevant while still keep track of the checked state
  React.useEffect(() => {
    if (filterDataSets) {
      const newCheckedKeys: PreviewSignalFilters = {};
      const newFilters: PreviewSignalFilters = {};
      (Object.entries(filterDataSets) as [SignalCategory, SignalFilterCategoryData][]).forEach(
        ([filterType, dataSet]) => {
          let updatedCheckedKeys: string[] | undefined;
          // If the parent node is checked, we want to add all options as checked children
          if (checkedKeys[filterType]?.some((ck) => ck.includes("parent:"))) {
            const signals = dataSet?.signals;
            updatedCheckedKeys = ["parent: " + filterType];
            if (signals) {
              updatedCheckedKeys = updatedCheckedKeys.concat(signals.map((s) => s.name));
            }
            // If parent node isn't checked, we want to only remember which keys were previously checked
          } else {
            updatedCheckedKeys = dataSet?.signals
              .filter((s) => checkedKeys[filterType]?.includes(s.name))
              .map((s) => s.name);
          }
          if (!updatedCheckedKeys) updatedCheckedKeys = [];

          newCheckedKeys[filterType] = updatedCheckedKeys;
          const filterOptions = updatedCheckedKeys.filter((k) => !k.includes("parent:"));
          newFilters[filterType] = filterOptions;
        },
      );
      setCheckedKeys(newCheckedKeys);
      setCheckboxFilters(newFilters);
    }
    // We don't want to include checkedKeys as a dep because this should only run when the inputs to the filterDataSets change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDataSets]);

  useImperativeHandle(ref, () => ({
    setFilterOptions,
  }));

  const setFilterOptions = (selectedDataSets: string[]) => {
    if (filterDataSets) {
      const newCheckedKeys: PreviewSignalFilters = {};
      const newFilters: PreviewSignalFilters = {};
      if (selectedDataSets.length > 0) {
        (Object.entries(filterDataSets) as [SignalCategory, SignalFilterCategoryData][]).forEach(
          ([filterType, dataSet]) => {
            if (selectedDataSets.includes(filterType)) {
              const signals = dataSet?.signals;
              if (signals) {
                newFilters[filterType] = signals.map((s) => s.name);
              }
              if (filterType in newFilters) {
                newCheckedKeys[filterType] = ["parent: " + filterType].concat(
                  newFilters[filterType] as string[],
                );
              }
            }
          },
        );
      }
      setCheckedKeys(newCheckedKeys);
      setCheckboxFilters(newFilters);
    }
  };

  const activeFiltersCount = React.useMemo(() => {
    return Object.values(checkboxFilters).reduce(
      (count, filters) => count + (filters?.length ?? 0),
      0,
    );
  }, [checkboxFilters]);

  const dateFilteredRecords = React.useMemo(() => {
    if (dateFilter) {
      return results.filter((result) => new Date(result.publish_date) > dateFilter);
    } else return results;
  }, [dateFilter, results]);

  const dateFilteredFilterSets = React.useMemo(() => {
    if (!dateFilter || !guidMatchMap || !filterDataSets || !allSignals) return filterDataSets;

    const dateFilteredGuidMatchMap: PreviewGuidMatchMap = {};
    const dateFilteredGuids = dateFilteredRecords.map((r) => r.guid);
    for (const guid of Object.keys(guidMatchMap)) {
      if (dateFilteredGuids.includes(guid)) dateFilteredGuidMatchMap[guid] = guidMatchMap[guid];
    }
    return getSignalFiltersData(allSignals, dateFilteredGuidMatchMap);
  }, [dateFilter, dateFilteredRecords, guidMatchMap, filterDataSets, allSignals]);

  const checkBoxFilteredRecords = React.useMemo(() => {
    if (guidMatchMap && signalMap) {
      // we pass dateFilteredRecords as the results because that's the top level filter for the preview
      return filterRecords(
        checkboxFilters,
        guidMatchMap,
        dateFilteredRecords,
        activeFiltersCount,
        signalMap,
      );
    } else return [];
  }, [checkboxFilters, dateFilteredRecords, guidMatchMap, activeFiltersCount, signalMap]);

  const monthlyAverageResults = React.useMemo(() => {
    const fromDate = DEFAULT_PREVIEW_LOOKBACK;
    return (results.length / moment().diff(fromDate, "days")) * 30.5;
  }, [results.length]);

  return (
    <div className={css.feedPreviewOutputs}>
      <div className={css.header}>
        <RecordPreviewHeading
          handleFilterCheck={handleFilterCheck}
          totalResultLength={results.length}
          dateFilteredResultLength={dateFilteredRecords.length}
          resultsShowing={checkBoxFilteredRecords.length}
          activeFiltersCount={activeFiltersCount}
          loading={loading}
          signalFiltersData={dateFilteredFilterSets}
          checkedKeys={checkedKeys}
          handleDateFilter={handleDateFilter}
          dateValue={dateFilter}
          monthlyAverageResults={monthlyAverageResults}
          allowDateFilter={false}
          recordListCountLabel={"in last 6 months"}
          pageSource={pageSource}
        />
      </div>
      <div className={css.recordListContainer}>
        <RecordCardList
          records={checkBoxFilteredRecords}
          popoverPlacement="left"
          guidMatchMap={guidMatchMap}
          disableRecordNavigation={pageSource === FeedPreviewPageSources.ONBOARDING}
          showTenderStage={pageSource !== FeedPreviewPageSources.ONBOARDING}
          signalMap={signalMap}
          feedCriteria={feedCriteria}
          companyId={companyId}
        />
      </div>
    </div>
  );
}

export default forwardRef(FeedPreviewOutputs);
