import * as React from "react";
import { CloseCircleOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Tag } from "antd5";
import { produce } from "immer";
import { OscarSearchModal } from "pages/admin/buyer_edit/OscarFormField";
import { v4 as uuidv4 } from "uuid";

import TextButton from "components/actions/TextButton";
import OrgSearchModal from "components/organisation_clean/OrgSearchModal";
import TextInputModal from "components/organisation_clean/TextInputModal";
import {
  OrgToBeCreated,
  OrgWithStats,
  UpdatableAttributes,
} from "components/organisation_clean/types";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import StotlesVerifiedIcon from "lib/core_components/StotlesVerifiedIcon";
import { useDialogManager } from "lib/providers/DialogManager";
import { yellow300 } from "lib/themes/colors";
import { NewLabel } from "./OrgLabels";
import OrgUpdatePreview from "./OrgUpdatePreview";

import css from "./OrgRelationshipSelection.module.scss";

type Props = {
  targetOrgs: OrgWithStats[];
  inactiveOrgs: OrgWithStats[];
  displayedInactiveOrgs: OrgWithStats[];
  displayedTargetOrgs: OrgWithStats[];
  newOrgs: OrgToBeCreated[];
  orgPrimaryRole: "Buyer" | "Supplier";
  setTargetOrgs: (targetOrgs: OrgWithStats[]) => void;
  setNewOrgs: (newOrgs: OrgToBeCreated[]) => void;
  setInactiveOrgs: (inactiveOrgs: OrgWithStats[]) => void;
  setAttributeDecisions: (orgGuid: string, newValues: UpdatableAttributes) => void;
};

/**
 * Component which allows user to select buyers to be marked as inactive and possibly end up transferring responsibilities to
 * the targetOrg or a new organisation not defined yet in our db
 */
function OrgRelationshipSelection({
  targetOrgs,
  inactiveOrgs,
  newOrgs,
  orgPrimaryRole,
  displayedInactiveOrgs,
  displayedTargetOrgs,
  setTargetOrgs,
  setInactiveOrgs,
  setNewOrgs,
  setAttributeDecisions,
}: Props): JSX.Element {
  const addNewOrg = React.useCallback(
    (name: string) => {
      const newOrg: OrgToBeCreated = { name, guid: uuidv4() };
      setNewOrgs(
        produce(newOrgs, (newOrgsCopy) => {
          newOrgsCopy.push(newOrg);
        }),
      );
    },
    [setNewOrgs, newOrgs],
  );

  const updatedValuesForOrg = (
    updatedOrgs: OrgWithStats[],
    currentOrg: OrgWithStats,
  ): UpdatableAttributes | undefined => {
    const updatedOrg = updatedOrgs.find((org) => org.guid === currentOrg.guid);

    if (updatedOrg) {
      return {
        is_stotles_verified: updatedOrg?.is_stotles_verified,
        oscar_id: updatedOrg?.oscar_id,
        companies_house_id: updatedOrg?.companies_house_id,
      };
    } else {
      return undefined;
    }
  };

  return (
    <div className={css.page}>
      <div className={css.pageContent}>
        <h2>{orgPrimaryRole} becoming inactive</h2>
        <FindOrgsButton
          modalTitle={`Select ${orgPrimaryRole.toLocaleLowerCase()} to set inactive (and potentially transfer responsibilities from)`}
          setSelectedOrgs={setInactiveOrgs}
          selectedOrgs={inactiveOrgs}
          hideOrgs={targetOrgs.map((org) => org.guid)}
          onSubmitNewOrg={addNewOrg}
          orgPrimaryRole={orgPrimaryRole}
          allowNewBuyer={false}
        />

        <OrgRelationshipItemList
          isInactive
          selectedOrgs={displayedInactiveOrgs}
          setOrgs={setInactiveOrgs}
          setAttributeDecisions={setAttributeDecisions}
        />
        {inactiveOrgs.map((buyer, i) => (
          <>
            {updatedValuesForOrg(displayedInactiveOrgs, buyer) && (
              <OrgUpdatePreview
                index={i}
                anchorOrg={buyer}
                orgName={buyer.name}
                orgId={buyer.guid}
                newValues={updatedValuesForOrg(displayedInactiveOrgs, buyer)}
              />
            )}
          </>
        ))}
      </div>
      <div className={css.pageContent}>
        <h2>{orgPrimaryRole} to transfer responsibility to</h2>
        <FindOrgsButton
          modalTitle={`Select ${orgPrimaryRole.toLocaleLowerCase()} to transfer responsibilities to`}
          setSelectedOrgs={setTargetOrgs}
          selectedOrgs={targetOrgs}
          hideOrgs={inactiveOrgs.map((org) => org.guid)}
          onSubmitNewOrg={addNewOrg}
          orgPrimaryRole={orgPrimaryRole}
          allowNewBuyer
        />

        <OrgRelationshipItemList
          selectedOrgs={displayedTargetOrgs}
          setOrgs={setTargetOrgs}
          setAttributeDecisions={setAttributeDecisions}
        />

        <OrgRelationshipItemList
          isNew
          selectedOrgs={newOrgs}
          setOrgs={setNewOrgs}
          setAttributeDecisions={setAttributeDecisions}
        />

        {targetOrgs.map((buyer, i) => (
          <>
            {updatedValuesForOrg(displayedTargetOrgs, buyer) && (
              <OrgUpdatePreview
                index={i}
                anchorOrg={buyer}
                orgName={buyer.name}
                orgId={buyer.guid}
                newValues={updatedValuesForOrg(displayedTargetOrgs, buyer)}
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
}

type ButtonProps = {
  selectedOrgs: OrgWithStats[];
  modalTitle: string;
  hideOrgs: string[];
  orgPrimaryRole: "Buyer" | "Supplier";
  allowNewBuyer: boolean;
  onSubmitNewOrg: (newOrgName: string) => void;
  setSelectedOrgs: (org: OrgWithStats[]) => void;
};
function FindOrgsButton({
  selectedOrgs,
  modalTitle,
  hideOrgs,
  orgPrimaryRole,
  allowNewBuyer,
  setSelectedOrgs,
  onSubmitNewOrg,
}: ButtonProps): JSX.Element {
  const dialogManager = useDialogManager();

  const openNewOrgModal = React.useCallback(() => {
    dialogManager.openDialog(TextInputModal, {
      onSubmit: (name: string) => onSubmitNewOrg(name),
      modalTitle: `Create new ${orgPrimaryRole.toLocaleLowerCase()} (already exists in modal, don't change)`,
      title: `Type the ${orgPrimaryRole.toLocaleLowerCase()} name that should exist `,
      description: (
        <span className={css.newBuyerDescription}>
          Are you sure the {orgPrimaryRole.toLocaleLowerCase()} isn't in the existing list? Make
          sure to check for alternate names. You can do this by:
          <ul className={css.bullets}>
            <li>
              Searching for a broader term in case of different variations (e.g., search{" "}
              <i>Camden</i> instead of <i>London borough of Camden</i>)
            </li>
            <li>
              Googling the typed name (e.g., Googling "PCC for Cheshire" shows "Police and Crime
              Commissioner for Cheshire")
            </li>
          </ul>
        </span>
      ),
    });
  }, [dialogManager, orgPrimaryRole, onSubmitNewOrg]);

  const renderOrgSearchFooter = React.useCallback(
    (onClose) => {
      if (allowNewBuyer) {
        return (
          <div>
            <TextButton
              onClick={() => {
                openNewOrgModal();
                onClose();
              }}
            >
              {orgPrimaryRole} doesn't exist in list
            </TextButton>
          </div>
        );
      } else return null;
    },
    [allowNewBuyer, openNewOrgModal, orgPrimaryRole],
  );

  return (
    <div className={css.buttonContainer}>
      <Button
        onClick={() => {
          dialogManager.openDialog(OrgSearchModal, {
            onSelectBuyer: (org) =>
              setSelectedOrgs(
                produce(selectedOrgs, (selected) => {
                  selected.push(org);
                }),
              ),
            title: modalTitle,
            selectedBuyers: selectedOrgs,
            hideBuyers: hideOrgs,
            orgPrimaryRole: orgPrimaryRole,
            footer: renderOrgSearchFooter,
          });
        }}
      >
        Find a {orgPrimaryRole.toLocaleLowerCase()}
      </Button>
      {allowNewBuyer && (
        <>
          <span>OR</span>

          <TextButton onClick={openNewOrgModal}>
            {orgPrimaryRole} doesn't exist in the list
          </TextButton>
        </>
      )}
    </div>
  );
}

type ItemProps<Org extends OrgWithStats | OrgToBeCreated> = {
  selectedOrgs: Org[];
  isNew?: boolean;
  isInactive?: boolean;

  setOrgs: (orgs: Org[]) => void;
  setAttributeDecisions: (orgGuid: string, newValues: UpdatableAttributes) => void;
};
function OrgRelationshipItemList<Org extends OrgWithStats | OrgToBeCreated>({
  selectedOrgs,
  isNew,
  isInactive,
  setOrgs,
  setAttributeDecisions,
}: ItemProps<Org>): JSX.Element {
  const dialogManager = useDialogManager();

  const removeOrg = (guid: string) => {
    setOrgs(
      produce(selectedOrgs, (newSelectedOrgs) => {
        const idx = newSelectedOrgs.findIndex((i) => i.guid === guid);
        newSelectedOrgs.splice(idx, 1);
      }),
    );
  };

  const changeOrgOscarId = (org: Org, oscar_id: string | null) => {
    // If this is a new org, we must set the value on the org directly
    if (isNew) {
      setOrgs(
        produce(selectedOrgs, (newSelectedOrgs) => {
          const newOrg = newSelectedOrgs.find((i) => i.guid === org.guid);
          if (newOrg) {
            newOrg["oscar_id"] = oscar_id;
          }
        }),
      );
      // Otherwise we set the value as a decision
    } else {
      setAttributeDecisions(org.guid, {
        oscar_id: oscar_id,
      });
    }
  };

  const verifyOrg = (org: Org, checked: boolean) => {
    // If this is a new org, we must set the value on the org directly
    if (isNew) {
      setOrgs(
        produce(selectedOrgs, (newSelectedOrgs) => {
          const newOrg = newSelectedOrgs.find((i) => i.guid === org.guid);
          if (newOrg) {
            newOrg["is_stotles_verified"] = checked;
          }
        }),
      );
    } else {
      setAttributeDecisions(org.guid, { is_stotles_verified: checked, oscar_id: org.oscar_id });
    }
  };

  return (
    <div className={css.listContainer}>
      <ul>
        {selectedOrgs.map((org) => (
          <li key={org.guid} className={org.is_stotles_verified ? css.item : css.unverifiedItem}>
            <h2 className={css.titleContainer}>
              <EllipsisTooltipText fullText={org.name} containerClassname={css.title} />
              {org.is_stotles_verified && <StotlesVerifiedIcon />}
              {isInactive && <Tag color={yellow300}>INACTIVE</Tag>}
              {isNew && <NewLabel />}
            </h2>

            <div className={css.actions}>
              {org.oscar_id && (
                <>
                  <span className={css.oscarIdText}>
                    Oscar ID: {org.oscar_id}{" "}
                    <CloseOutlined
                      className={css.closeIcon}
                      onClick={() => changeOrgOscarId(org, null)}
                    />
                  </span>
                </>
              )}
              <Input
                placeholder="Input Oscar Id"
                className={css.inputOscarId}
                onChange={(e) => changeOrgOscarId(org, e.target.value)}
                value={org.oscar_id ?? ""}
                allowClear
              />
              <Button
                onClick={() =>
                  dialogManager.openDialog(OscarSearchModal, {
                    handleChange: (refOrg) =>
                      changeOrgOscarId(org, refOrg?.sourceIdentifier || null),
                  })
                }
                style={{ marginTop: "4px" }}
              >
                <SearchOutlined /> Search Oscar name
              </Button>
              <Checkbox
                checked={org.is_stotles_verified}
                onChange={(checked) => verifyOrg(org, checked.target.checked)}
              >
                Is Stotles verified
              </Checkbox>

              <TextButton
                className={css.removeButton}
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => removeOrg(org.guid)}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OrgRelationshipSelection;
