import * as React from "react";
import { Modal } from "antd"; // upgrade and verify

import BuyerListPanel from "./OrgListPanel";
import { OrgWithStats } from "./types";
import { FilterType, OrgSearchFilters, useOrgSearch } from "./useOrgSearch";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelectBuyer: (b: OrgWithStats) => void;
  selectedBuyers: OrgWithStats[];
  title: string;
  footer?: (onClose: () => void) => JSX.Element | null;
  hideBuyers?: string[];
  orgPrimaryRole: "Buyer" | "Supplier";
};

function OrgSearchModal({
  isOpen,
  onClose,
  onSelectBuyer,
  selectedBuyers,
  title,
  footer,
  hideBuyers,
  orgPrimaryRole,
}: Props): JSX.Element {
  const [filterState, setFilterState] = React.useState<FilterType>({
    textSearch: "",
    countryCode: ["UK"],
    primaryRole: orgPrimaryRole,
  });

  const searchResults = useOrgSearch(filterState);

  const displayList = React.useMemo(() => {
    if (hideBuyers) {
      return searchResults.filter((r) => !hideBuyers.includes(r.guid));
    } else return searchResults;
  }, [searchResults, hideBuyers]);
  return (
    <Modal
      style={{ top: "5vh", minWidth: "750px" }}
      bodyStyle={{ height: "90vh" }}
      visible={isOpen}
      onCancel={onClose}
      footer={footer ? footer(onClose) : null}
    >
      <div style={{ display: "grid", gridTemplateRows: "auto minmax(0,1fr)", height: "100%" }}>
        <header>
          <h1>{title}</h1>
          <OrgSearchFilters filters={filterState} setFilters={setFilterState} />
        </header>
        <BuyerListPanel
          countLabel={`${displayList.length} results`}
          orgs={displayList}
          buttonConfig={{
            selectedLabel: "Selected",
            label: "Select",
            onSelect: (b) => {
              onSelectBuyer(b);
              onClose();
            },
          }}
          disableSelected={true}
          expandedByDefault={false}
          selectedGuids={selectedBuyers.map((b) => b.guid)}
        />
      </div>
    </Modal>
  );
}

export default OrgSearchModal;
