import * as React from "react";
import { DeleteTwoTone, DownloadOutlined, EditOutlined, FileOutlined } from "@ant-design/icons";
import { Button, message } from "antd"; // upgrade message to use context

import { stringSort } from "lib/columnSort";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { exportDataToCsv } from "lib/exportArrayToCsv";
import { useStotlesApi } from "lib/stotlesApiContext";
import { DeleteDocumentModal } from "./DocumentComponents";
import { getFriendlyDocumentType } from "./documentTypes";
import {
  BuyerBasicIdentificationInfo,
  DocumentSearchResponse,
  File,
  SearchFileWithUserInfo,
} from "./types";

import css from "./DocumentsTable.module.scss";

type Props = {
  // filters for filtering down the results to be shown in the table
  filters: {
    buyers?: Record<string, BuyerBasicIdentificationInfo>;
    text?: string;
    uploaderGuid?: string[];
  };

  // form inputs to be rendered above the table
  renderFormElement?: (
    handleSubmit: (event: React.SyntheticEvent<HTMLElement>) => Promise<void>,
  ) => React.ReactNode;

  // the funciton for searching
  fetchFolderResults: (filters: {
    buyerGuids: string[] | undefined;
    text: string | undefined;
    userGuid?: string[] | undefined;
  }) => Promise<DocumentSearchResponse>;

  // the function for getting information about users which are displayed in each results. If not
  // given, any unknown users remain anonymous
  fetchUserDetails?: (requestParameters: {
    userDetailsRequest: { userGuids: string[] };
  }) => Promise<{
    userDetails?: { guid: string; email?: string; firstName: string; id: number }[];
  }>;

  // we want to do a conversion of buyer guid (coming from the results from the storage solution) to
  // a name, to make the presentation of the results easier to understand. Some of the names will
  // already be present from the info passed in, but this needs to know how to fetch any names which
  // are missing
  fetchMissingBuyerInfo: (request: {
    guids: string[];
  }) => Promise<{ results: { id: number; name: string; guid: string }[] }>;

  loadResultsOnMount?: boolean;
  onDocumentNameClick?: (fileId: string) => void;
} & (
  | {
      mode: "select";
      onEditDocumentClick?: undefined;
      onDocumentSelect: (file: File) => void;
    }
  | {
      mode?: "edit";
      onEditDocumentClick: (fileId: string) => void;
      onDocumentSelect?: undefined;
    }
);
/*
 * This is a relatively low-level component which is passed some filters, and has a few functions
 * for fetching injected into it, to allow it to be used more adaptively across admin or non admin
 * contexts.
 */
export default function DocumentsTable({
  renderFormElement,
  fetchFolderResults,
  fetchUserDetails,
  fetchMissingBuyerInfo,
  onEditDocumentClick,
  onDocumentNameClick,
  onDocumentSelect,
  filters,
  loadResultsOnMount,
  mode,
}: Props): JSX.Element {
  const { text, buyers, uploaderGuid } = filters;
  const [folderDetails, setFolderDetails] = React.useState<SearchFileWithUserInfo[] | undefined>();
  const [missingBuyerInfo, setMissingBuyerInfo] = React.useState<
    Record<string, BuyerBasicIdentificationInfo>
  >({});

  const api = useStotlesApi();

  const [loading, setLoading] = React.useState(false);
  const [fileToDelete, setFileToDelete] = React.useState<File>();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

  const handleCSVExport = React.useCallback(() => {
    if (folderDetails) {
      exportDataToCsv("export-documents.csv", folderDetails);
    }
  }, [folderDetails]);

  const columns: ColumnType<SearchFileWithUserInfo>[] = React.useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (_, c) => (
          <span
            className={css.documentName}
            onClick={() => onDocumentNameClick && onDocumentNameClick(c.id)}
          >
            <p>
              <FileOutlined /> {c.title ?? c.name}
            </p>
            {c.title && <p>{c.name}</p>}
          </span>
        ),
      },
      {
        key: "buyer",
        title: "Buyer",
        render: (_, c) => (
          <span>
            {buyers?.[c.owner_guid]?.name ??
              missingBuyerInfo[c.owner_guid]?.name ??
              `${c.owner_guid}`}
          </span>
        ),
      },
      {
        key: "document_type",
        title: "Doc Type",
        dataIndex: "document_type",
        sorter: stringSort((x) => x.document_type && getFriendlyDocumentType(x.document_type)),
        render: (_, c) => (
          <span>{c.document_type && getFriendlyDocumentType(c.document_type)}</span>
        ),
      },
      {
        key: "uploader",
        title: "User Email",
        dataIndex: "emailAddress",
      },
      {
        key: "published_at",
        title: "Publish Date",
        dataIndex: "published_at",
        sorter: stringSort((x) => x.published_at),
      },
      {
        key: "uploaded_at",
        title: "Upload Date",
        dataIndex: "uploaded_at",
        sorter: stringSort((x) => x.uploaded_at),
        defaultSortOrder: "descend",
      },
      !mode || mode === "edit" || !onDocumentSelect
        ? {
            title: "Actions",
            key: "actions",
            width: "120px",
            render: (_, c) => (
              <>
                <a href={`/api/documents/download?id=${c.id}`} download title="Download document">
                  <span>
                    <DownloadOutlined />
                  </span>
                </a>
                {onEditDocumentClick && (
                  <span
                    onClick={() => onEditDocumentClick(c.id)}
                    className={css.actionIcons}
                    title="Edit document"
                  >
                    <EditOutlined />
                  </span>
                )}
                <span
                  className={css.actionIcons}
                  onClick={() => {
                    setFileToDelete(c);
                    setDeleteModalOpen(true);
                  }}
                  title="Delete document"
                >
                  <DeleteTwoTone twoToneColor="#ff4d4f" />
                </span>
              </>
            ),
          }
        : {
            title: "Select",
            key: "select",
            width: "120px",
            fixed: "right",
            render: (_, doc) => (
              <Button
                onClick={() => {
                  onDocumentSelect(doc);
                }}
              >
                Add
              </Button>
            ),
          },
    ],
    [mode, onDocumentSelect, onDocumentNameClick, buyers, missingBuyerInfo, onEditDocumentClick],
  );

  const fetchResults = React.useCallback(async () => {
    setLoading(true);
    try {
      const buyerGuids = buyers ? Object.keys(buyers) : undefined;
      const response = await fetchFolderResults({
        buyerGuids: buyerGuids?.length ? buyerGuids : undefined,
        text,
        userGuid: uploaderGuid,
      });
      if (response && response.files) {
        const missingBuyerGuids: Set<string> = new Set();
        const userGuids: Set<string> = new Set();
        let userDetails = undefined;
        const files = response.files as SearchFileWithUserInfo[];
        for (const file of files) {
          if (!buyers || !buyers[file.owner_guid]) {
            missingBuyerGuids.add(file.owner_guid);
          }
          const userTokens = file.uploaded_by?.split(":");
          if (userTokens?.length > 1 && userTokens[0] === "user") {
            userGuids.add(userTokens[1]);
            file.userGuid = userTokens[1];
          }
        }
        if (fetchUserDetails) {
          userDetails = (
            await fetchUserDetails({
              userDetailsRequest: { userGuids: Array.from(userGuids) },
            })
          )?.userDetails;
        }

        const uniqueMissingBuyerGuids = Array.from(missingBuyerGuids);
        if (uniqueMissingBuyerGuids.length > 0) {
          const resp = await fetchMissingBuyerInfo({ guids: uniqueMissingBuyerGuids });
          if (resp && resp.results) {
            const newMissingBuyerInfo: typeof missingBuyerInfo = {};
            for (const b of resp.results) {
              newMissingBuyerInfo[b.guid] = { id: b.id, name: b.name, guid: b.guid };
            }
            setMissingBuyerInfo(newMissingBuyerInfo);
          }
        }

        for (const file of files) {
          let userEmail = undefined;
          if (file.userGuid) {
            if (window.currentUser && file.userGuid === window.currentUser.guid) {
              userEmail = window.currentUser.email;
            } else if (userDetails && userDetails.length) {
              userEmail = userDetails.find((x) => x.guid === file.userGuid)?.email;
            }
          }
          file.emailAddress = userEmail ?? file.userGuid ?? "Unknown user";
        }
        setFolderDetails(files);
      }
    } catch (err) {
      console.log(err);
      void message.error("There was an error performing document search.");
    }
    setLoading(false);
  }, [buyers, fetchFolderResults, fetchUserDetails, fetchMissingBuyerInfo, text, uploaderGuid]);

  const handleSearch = React.useCallback(
    async (event: React.SyntheticEvent<HTMLElement>) => {
      event.preventDefault();
      await fetchResults();
    },
    [fetchResults],
  );

  React.useEffect(() => {
    if (loadResultsOnMount) {
      void fetchResults();
    }
    // run only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = React.useMemo(
    () => (renderFormElement ? renderFormElement(handleSearch) : null),
    [renderFormElement, handleSearch],
  );

  const handleDeleteDocument = async (file: File) => {
    try {
      await api.deleteDocument(file.id);
      setFolderDetails((files) => files?.filter((v) => v.id !== file.id));
      void message.success(`Successfully deleted "${file.name}"`);
    } catch {
      void message.error(`Failed to delete "${file.name}"`);
    }
  };

  return (
    <>
      {form}
      {mode === "edit" && (
        <Button
          type="primary"
          onClick={handleCSVExport}
          disabled={!folderDetails || folderDetails.length == 0}
        >
          Export
        </Button>
      )}
      <Table
        loading={loading}
        dataSource={folderDetails}
        columns={columns}
        rowKey={(folderItem) => folderItem.id}
        scroll={{ x: true }}
      />
      {fileToDelete && (
        <DeleteDocumentModal
          isOpen={deleteModalOpen}
          fileToDelete={fileToDelete}
          onDelete={handleDeleteDocument}
          onClose={() => {
            setDeleteModalOpen(false);
            setFileToDelete(undefined);
          }}
        />
      )}
    </>
  );
}
