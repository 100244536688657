import * as React from "react";
import { Select } from "antd"; // upgrade and verify along with usages
import { OptionProps, SelectProps } from "antd/lib/select";

import * as tracking from "lib/tracking";

type Props<T> = {
  children: (React.ReactElement<OptionProps> | null | undefined)[];
  selectRef?: React.Ref<Select<T[]>>;
} & SelectProps<T[]>;

// Every time a user chooses an option, the dropdown will close.
// The dropdown can otherwise be annoyingly persistent for the user
function AutoclosingMultiselect<T>({
  children,
  onChange,
  onDropdownVisibleChange,
  onSearch,
  selectRef,
  ...rest
}: Props<T>): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const handleChange = React.useCallback(
    (
      newValues: T[],
      options: React.ReactElement<OptionProps> | React.ReactElement<OptionProps>[],
    ) => {
      // we will probably not use `options` much, but ant passes them anyway,
      // and would like us to pass them through to `onChange`. We _know_ that options
      // will be an array, because we are using a multiselect, but TS/ant does not
      if (onChange) onChange(newValues, options as React.ReactElement<OptionProps>[]);

      setOpen(false);
      tracking.logEvent(tracking.EventNames.signalsEdited, {
        "Page source": "Account Management - Feed Settings",
        "Action type": children.length > newValues.length ? "Removed" : "Added",
        "Signal type": "Keywords",
        "Context source": "Free-text",
        "Signal name(s)": "--",
      });
    },
    [onChange, children],
  );

  const handleDropdownVisibleChange = React.useCallback(
    (isOpen: boolean) => {
      if (onDropdownVisibleChange) {
        onDropdownVisibleChange(isOpen);
      }
      setOpen(isOpen);
    },
    [onDropdownVisibleChange],
  );

  const handleSearch = React.useCallback(
    (value: string) => {
      if (onSearch) {
        onSearch(value);
      }
      // when the user selects an item, the dropdown closes. If they keep typing, the box should reopen.
      if (!open) {
        handleDropdownVisibleChange(true);
      }
    },
    [onSearch, open, handleDropdownVisibleChange],
  );

  return (
    <Select<T[]>
      // ant's Select API exposes .focus() and .blur() methods, but a ref is needed to access them
      // NB. the difference between .blur() and open={false} is that the user can still be typing in
      // the input, but the dropdown will be hidden if open={false}, but when .blur() is called, focus
      // completely leaves the input and typed text disappears.
      // TODO consider using React.forwardRef if the ref becomes more widely used
      ref={selectRef}
      mode="multiple"
      showSearch
      allowClear
      showArrow={false}
      open={open}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      onChange={handleChange}
      autoClearSearchValue
      onSearch={handleSearch}
      {...rest}
    >
      {children}
    </Select>
  );
}

export default AutoclosingMultiselect;
