import React, { useEffect, useState } from "react";
import _ from "lodash";

import CentredSpinner from "lib/core_components/CentredSpinner";
import CategoryDropdown from "./CategoryDropdown";
import InfiniteSuppliersChecklist from "./InfiniteSuppliersChecklist";
import SignalSuppliersChecklist from "./SignalSupplierChecklist";

import css from "./SuppliersDropdown.module.scss";

type Props = {
  competitors: SupplierOption[];
  partners: SupplierOption[];
  isLoading: boolean;
  handleSuppliersSelection: (suppliers: SupplierOption[], flag: "add" | "remove") => void;
  textSearch: string;
  isOpen: boolean;
  value: string[];
};

export type SupplierOption = {
  label: string;
  value: string;
};

export type SupplierSelectCategory = "Competitor" | "Partner" | "Other suppliers";

function SuppliersDropdown({
  competitors,
  partners,
  isLoading,
  handleSuppliersSelection,
  textSearch,
  isOpen,
  value,
}: Props) {
  // Categories
  const [categorySelected, setCategorySelected] = useState<SupplierSelectCategory | undefined>(
    undefined,
  );

  // if modal hidden, reset the category
  useEffect(() => {
    if (!isOpen) {
      setCategorySelected(undefined);
    }
  }, [isOpen]);

  const categoryOptions: SupplierSelectCategory[] = ["Competitor", "Partner", "Other suppliers"];

  const signalSupplierOptions = categorySelected === "Competitor" ? competitors : partners;

  const allSignalSuppliers = _.union(competitors, partners);

  function handleCategorySelection(label: SupplierSelectCategory) {
    setCategorySelected(label);
  }

  function getCategoryLabel(category?: string): string {
    switch (category) {
      case "Competitor":
        return `Competitor signals (${competitors.length})`;
      case "Partner":
        return `Partner signals (${partners.length})`;
      case "Other suppliers":
      default:
        if (isLoading) return `Other suppliers`;
        return `Other suppliers (10,000+)`;
    }
  }

  const renderedCategoryInput = () => {
    if (isLoading) return <CentredSpinner />;

    if (categorySelected === undefined && textSearch.length === 0)
      return (
        <CategoryDropdown
          categories={categoryOptions}
          handleCategorySelection={handleCategorySelection}
          getCategoryLabel={getCategoryLabel}
        />
      );

    if (categorySelected === "Other suppliers" || textSearch.length > 0) {
      return (
        <InfiniteSuppliersChecklist
          signalSuppliers={allSignalSuppliers}
          excludeSignals={textSearch.length === 0}
          textSearch={textSearch}
          categoryLabel={getCategoryLabel(categorySelected)}
          onChange={handleSuppliersSelection}
          value={value}
        />
      );
    }

    return (
      <SignalSuppliersChecklist
        options={signalSupplierOptions}
        category={categorySelected ?? "Competitor"}
        categoryLabel={getCategoryLabel(categorySelected)}
        onChange={handleSuppliersSelection}
        value={value}
      />
    );
  };

  return (
    <div
      className={`${css.options} ${isOpen ? css.show : ""}`}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      {
        // show the description when not searching
        textSearch.length === 0 && (
          <div className={css.filterDescription}>
            <span className={css.filterDescriptionText}>
              Search the suppliers' activities you want to track
            </span>
          </div>
        )
      }
      <div className={css.dropdown}>{renderedCategoryInput()}</div>
    </div>
  );
}

export default SuppliersDropdown;
