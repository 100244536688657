import React, { useEffect, useState } from "react";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd"; // move icons to icon prop if icon is to the left
import {
  GlobalWorkerOptions,
  PDFDocumentProxy,
  version as pdfjsVersion,
} from "pdfjs-dist/legacy/build/pdf";
import * as PDFJSViewer from "pdfjs-dist/legacy/web/pdf_viewer";

import { useTracking } from "lib/tracking";
import * as tracking from "lib/tracking";
import { assertDefined } from "lib/utils";
import { PDFFindController } from "./PDFFindController";

import "pdfjs-dist/legacy/web/pdf_viewer.css";
import css from "./DisplayPdf.module.scss";
// TODO move to use local version of pdf worker
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.js`;

type PdfViewerProps = {
  pdf: PDFDocumentProxy;
  searchTerms?: string[];
  caseSensitive?: boolean;
  onSlideoutClose?: () => void;
};

export function PdfViewer({
  pdf,
  searchTerms,
  caseSensitive,
  onSlideoutClose,
}: PdfViewerProps): JSX.Element {
  const [pdfViewer, setPdfViewer] = useState<PDFJSViewer.PDFViewer>();

  const pdfDivRef = React.useRef<HTMLDivElement | null>(null);
  const pdfContainerRef = React.useRef<HTMLDivElement | null>(null);
  const [errors, setErrors] = React.useState<string[]>([]);

  const { logEvent } = useTracking();
  useEffect(() => {
    const container = pdfContainerRef.current;
    const viewer = pdfDivRef.current;
    assertDefined(container);
    assertDefined(viewer);

    const bus = new PDFJSViewer.EventBus();

    const pdfLinkService = new PDFJSViewer.PDFLinkService({
      eventBus: bus,
    });

    const findController = new PDFFindController({
      eventBus: bus,
      linkService: pdfLinkService,
    });

    const jsViewer = new PDFJSViewer.PDFViewer({
      container: container,
      viewer: viewer,
      eventBus: bus,
      findController: findController,
      linkService: pdfLinkService,
      l10n: new PDFJSViewer.GenericL10n("en"),
      renderer: "canvas",
    });

    setPdfViewer(jsViewer);
    pdfLinkService.setViewer(jsViewer);
    jsViewer.setDocument(pdf);
    pdfLinkService.setDocument(pdf);
    return () => {
      jsViewer.cleanup();
    };
  }, [pdf]);

  useEffect(() => {
    if (!searchTerms || !searchTerms.length || !pdfViewer) {
      return;
    }

    setErrors([]);

    pdfViewer.eventBus.dispatch("find", {
      query: searchTerms,
      highlightAll: true,
      findPrevious: false,
      caseSensitive,
    });
  }, [searchTerms, pdfViewer, caseSensitive]);

  const handleNextSearchMatch = () => {
    if (pdfViewer) {
      const findController = pdfViewer.findController as PDFFindController;
      findController._nextMatch();
      logEvent(tracking.EventNames.documentNextMatchClicked);
    }
  };

  const handlePreviousSearchMatch = () => {
    if (pdfViewer) {
      pdfViewer.eventBus.dispatch("find", {
        type: "again",
        phraseSearch: true,
        highlightAll: true,
        findPrevious: true,
        query: searchTerms,
        caseSensitive,
      });
      logEvent(tracking.EventNames.documentPreviousMatchClicked);
    }
  };

  return (
    <>
      <div className={css.pdfViewer}>
        <div className={css.toolbar}>
          <div className={css.pageNavigation}>
            <span onClick={onSlideoutClose} className={css.closeViewerCross}>
              <CloseOutlined />
            </span>
          </div>
          {searchTerms && searchTerms.length > 0 && (
            <div className={css.findButtons}>
              <Button onClick={handlePreviousSearchMatch}>
                <LeftOutlined />
                Previous match
              </Button>
              <Button
                type="primary"
                onClick={handleNextSearchMatch}
                className={css.nextMatchButton}
              >
                Next match <RightOutlined />
              </Button>
            </div>
          )}
          <span className={css.pageCount}>{pdf.numPages} pages</span>
        </div>
        {errors.length > 0 && (
          <ul className={css.errors}>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        )}

        <div ref={pdfContainerRef} className={css.viewerContainer}>
          <div id="viewer" className={"pdfViewer"} ref={pdfDivRef}></div>
        </div>
      </div>
    </>
  );
}
