/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestOutreachContactsRequestContactFilters
 */
export interface RequestOutreachContactsRequestContactFilters {
    /**
     * 
     * @type {string}
     * @memberof RequestOutreachContactsRequestContactFilters
     */
    jobTitle: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestOutreachContactsRequestContactFilters
     */
    seniority: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestOutreachContactsRequestContactFilters
     */
    jobFunction: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RequestOutreachContactsRequestContactFilters
     */
    buyerIds: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestOutreachContactsRequestContactFilters
     */
    buyerListIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestOutreachContactsRequestContactFilters
     */
    buyerCategoryIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RequestOutreachContactsRequestContactFilters
     */
    includeUncategorisedBuyers: boolean;
}

/**
 * Check if a given object implements the RequestOutreachContactsRequestContactFilters interface.
 */
export function instanceOfRequestOutreachContactsRequestContactFilters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "jobTitle" in value;
    isInstance = isInstance && "seniority" in value;
    isInstance = isInstance && "jobFunction" in value;
    isInstance = isInstance && "buyerIds" in value;
    isInstance = isInstance && "buyerListIds" in value;
    isInstance = isInstance && "buyerCategoryIds" in value;
    isInstance = isInstance && "includeUncategorisedBuyers" in value;

    return isInstance;
}

export function RequestOutreachContactsRequestContactFiltersFromJSON(json: any): RequestOutreachContactsRequestContactFilters {
    return RequestOutreachContactsRequestContactFiltersFromJSONTyped(json, false);
}

export function RequestOutreachContactsRequestContactFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestOutreachContactsRequestContactFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobTitle': json['job_title'],
        'seniority': json['seniority'],
        'jobFunction': json['job_function'],
        'buyerIds': json['buyer_ids'],
        'buyerListIds': json['buyer_list_ids'],
        'buyerCategoryIds': json['buyer_category_ids'],
        'includeUncategorisedBuyers': json['include_uncategorised_buyers'],
    };
}

export function RequestOutreachContactsRequestContactFiltersToJSON(value?: RequestOutreachContactsRequestContactFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_title': value.jobTitle,
        'seniority': value.seniority,
        'job_function': value.jobFunction,
        'buyer_ids': value.buyerIds,
        'buyer_list_ids': value.buyerListIds,
        'buyer_category_ids': value.buyerCategoryIds,
        'include_uncategorised_buyers': value.includeUncategorisedBuyers,
    };
}

