import * as React from "react";
import moment from "moment";

import { DateRangeCriteria } from "../record_search/types";
import { RangeInput } from "./RangeInputs";
import { DatePickerPopover } from "./RelativeDatePicker";

export type Props = {
  values?: DateRangeCriteria;
  handleChange: (dates: DateRangeCriteria) => void;
  format?: string;
  disabled?: boolean;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  showFutureDates?: boolean;
};

export default function DateRangePicker(props: Props): JSX.Element {
  const { values, handleChange, format, minDate, maxDate, showFutureDates = true } = props;

  const updateFrom = React.useCallback(
    (newVal?: moment.Moment, newRelativeVal?: string) => {
      handleChange({ ...values, from: newVal, relativeFrom: newRelativeVal });
    },
    [handleChange, values],
  );

  const updateTo = React.useCallback(
    (newVal?: moment.Moment, newRelativeVal?: string) => {
      handleChange({ ...values, to: newVal, relativeTo: newRelativeVal });
    },
    [handleChange, values],
  );

  // save having 2 separate callbacks with the same deps
  const disabledCallbacks = React.useMemo(
    () => ({
      from: (current?: moment.Moment): boolean => {
        if (!current || !values) {
          return false;
        }
        if (minDate && current && minDate >= current) {
          return true;
        }
        if (maxDate && current && maxDate <= current) {
          return true;
        }
        if (values.relativeTo) {
          return moment().add(moment.duration(values.relativeTo)) <= current;
        }
        return !!(values.to && values.to <= current);
      },
      to: (current?: moment.Moment): boolean => {
        if (!current || !values) {
          return false;
        }
        if (minDate && current && minDate >= current) {
          return true;
        }
        if (maxDate && current && maxDate <= current) {
          return true;
        }
        if (values.relativeFrom) {
          return moment().add(moment.duration(values.relativeFrom)) >= current;
        }
        return !!(values.from && values.from >= current);
      },
    }),
    [minDate, maxDate, values],
  );

  return (
    <RangeInput
      from={
        <DatePickerPopover
          value={values ? values.from : undefined}
          relativeValue={values?.relativeFrom}
          onSelect={updateFrom}
          format={format}
          disabledDate={disabledCallbacks.from}
          placeholder="Start"
          showFutureDates={showFutureDates}
        />
      }
      to={
        <DatePickerPopover
          value={values?.to}
          relativeValue={values?.relativeTo}
          onSelect={updateTo}
          format={format}
          disabledDate={disabledCallbacks.to}
          placeholder="End"
          showFutureDates={showFutureDates}
        />
      }
    />
  );
}
