import React from "react";

import { DefaultTheme } from "components/app_layout/DefaultTheme";

type Props = {
  size?: number;
  fill?: string;
  marginTop?: number | string;
  ariaLabel?: string;
};

export function StageIconFull({
  size = 16,
  fill = DefaultTheme.token?.colorPrimaryActive,
  marginTop = 0,
  ariaLabel = "",
}: Props) {
  return (
    <svg
      aria-label={ariaLabel}
      width={size}
      height={size}
      viewBox={`0 0 ${size + 1} ${size + 1}`}
      fill="none"
      style={{ marginTop }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx={size * 0.6} fill={fill} />
    </svg>
  );
}

export function StageIconHollow({
  size = 16,
  fill = DefaultTheme.token?.colorPrimaryActive,
  marginTop = 0,
  ariaLabel = "",
}: Props) {
  return (
    <svg
      aria-label={ariaLabel}
      width={size}
      height={size}
      viewBox={`0 0 ${size + 1} ${size + 1}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop }}
    >
      <rect
        x="1"
        y="1"
        width={size * 0.9}
        height={size * 0.9}
        rx="7"
        stroke={fill}
        strokeWidth="2"
      />
    </svg>
  );
}

export function StageIconTarget({
  size = 30,
  fill = DefaultTheme.token?.colorPrimaryActive,
  marginTop = 0,
  ariaLabel = "",
}: Props) {
  return (
    <svg
      aria-label={ariaLabel}
      width={size}
      height={size}
      viewBox={`0 0 ${size + 1} ${size + 1}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop }}
    >
      <rect
        x={size / 4}
        y={size / 4}
        width={size / 2}
        height={size / 2}
        rx={size / 4}
        stroke={fill}
        stroke-width={size / 12}
      />
      <rect
        x={size * 0.375}
        y={size * 0.375}
        width={size / 4}
        height={size / 4}
        rx={size / 8}
        fill={fill}
      />
    </svg>
  );
}
