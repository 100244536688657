import React, { useState } from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd5";
import { ActiveSupplierTab } from "pages/app/SupplierSearchPage";
import useDeepCompareEffect from "use-deep-compare-effect";

import { ClearSelectedButton } from "lib/core_components/ClearSelectedButton";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import {
  FILTER_COLLAPSE_MATCH_STYLING,
  NEW_SUPPLIER_FILTER,
  useVariableValue,
} from "lib/featureFlags";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { useURLState } from "lib/hooks/useURLState";
import { EventDataTypes, useTracking } from "lib/tracking";
import { ColumnSetting } from "lib/types/models";
import FilterDrawer from "../filter_form/filter_drawer/FilterDrawer";
import BulkSaveSupplier from "./BulkSaveSupplier";
import { ExportSuppliersButton } from "./ExportSuppliersButton";
import { FilterForm, FilterFormProps } from "./FilterForm";
import { QuickFilterBar } from "./QuickFilterBar";
import { SupplierTable } from "./SupplierTable";
import { useSuppliersTracking } from "./tracking";
import {
  BASELINE_SUPPLIER_COLUMNS,
  DEFAULT_FILTERS,
  parseSuppliersUrlState,
  SupplierColumns,
  SupplierFilters,
} from "./utils";

import css from "./FilterableSuppliers.module.scss";

const DEFAULT_COLUMNS = BASELINE_SUPPLIER_COLUMNS.filter(
  (c) => c.field !== "upcomingExpiries" && c.field !== "spendData",
);

type Props = {
  defaultFilters?: SupplierFilters;
  defaultColumns?: ColumnSetting<SupplierColumns>[];
  allAvailableColumns?: ColumnSetting<SupplierColumns>[];
  hiddenFilterSections?: FilterFormProps["hiddenSections"];
  linkToBuyerRelationshipPage?: { buyerGuid: string };
  activeTab?: ActiveSupplierTab;
  setActiveTab?: (tab: ActiveSupplierTab) => void;
};

export function FilterableSuppliers({
  defaultFilters = DEFAULT_FILTERS,
  defaultColumns = DEFAULT_COLUMNS,
  allAvailableColumns = BASELINE_SUPPLIER_COLUMNS,
  hiddenFilterSections,
  linkToBuyerRelationshipPage,
  activeTab,
  setActiveTab,
}: Props) {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
  const [filters = defaultFilters, setFilters] = useURLState<SupplierFilters>(
    "supplierFilters",
    defaultFilters,
    parseSuppliersUrlState,
  );
  // If default supplier guids (switching supplier views or buyer header cards) are changed in a parent component,
  // then just change the guids and make sure buyers filter is persisted
  useDeepCompareEffect(() => {
    setFilters({
      ...filters,
      guids: defaultFilters.guids,
    });
  }, [defaultFilters, setFilters]);

  const [supplierSearch, setSupplierSearch] = useState(filters.text);
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);
  const useDebounce = createUseDebounce(500);
  const debouncedSetFilters = useDebounce((f: SupplierFilters) => {
    // only filters that should narrow the results enough to have a working sort should set the sort to awards_count
    const filtersActive =
      (f.text && f.text.length > 2) ||
      f.signals?.length ||
      f.buyerIds?.length ||
      f.buyerLists?.length;

    if (filtersActive && f.sort?.field === "name") {
      f.sort = { field: "awards_count", order: "DESC" };
    }
    if (!filtersActive && f.sort?.field === "awards_count") {
      f.sort = { field: "name", order: "ASC" };
    }
    setFilters(f);
    setSupplierSearch(f.text);
  });

  const { trackingData } = useTracking();
  const filterableSuppliersTrackingData = { ...trackingData, "CTA actioned": "Filter" };
  const openGuestAccessPassPaywall = useRestrictedGuestAccess(filterableSuppliersTrackingData);

  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_MATCH_STYLING, false);

  const tracking = useSuppliersTracking();
  const debouncedFreeSearchLog = useDebounce(() => tracking.freeSearchLog());

  const loggedFilterOpen = () => {
    setIsFilterDrawerOpen(true);
    tracking.filterDrawerOpened();
  };

  return (
    <div className={css.container}>
      <div className={css.actionsContainer}>
        <div className={css.actions}>
          <Input
            placeholder="Search by supplier name"
            prefix={<SearchOutlined />}
            value={supplierSearch}
            onChange={(e) => {
              setSupplierSearch(e.target.value);
              debouncedSetFilters({ ...filters, text: e.target.value });
              debouncedFreeSearchLog();
            }}
            allowClear
            className={css.searchInput}
          />
          <Button onClick={window.guestUser ? openGuestAccessPassPaywall : loggedFilterOpen}>
            <FilterOutlined className={css.filterIcon} /> Filters
          </Button>
        </div>
        <div className={css.actions}>
          <div className={css.clearSelected}>
            {selectedSuppliers && selectedSuppliers.length > 0 && (
              <>
                <ClearSelectedButton
                  count={selectedSuppliers.length}
                  onClear={() => setSelectedSuppliers([])}
                />
                :
              </>
            )}
          </div>
          {selectedSuppliers && selectedSuppliers.length === 0 && (
            <EditColumnsButton
              allAvailableColumns={allAvailableColumns}
              selectedColumns={selectedColumns}
              onNewColumnSettingChange={(c) =>
                setSelectedColumns([...(c as ColumnSetting<SupplierColumns>[])])
              }
              dataType={EventDataTypes.supplier}
            />
          )}
          {!window.guestUser &&
            enableNewSupplierFilter &&
            window.currentUser?.use_supplier_name === false && (
              <div className={css.saveSuppliers}>
                {selectedSuppliers.length > 0 && !window.guestUser && (
                  <BulkSaveSupplier supplierGuids={selectedSuppliers} />
                )}
              </div>
            )}
          <ExportSuppliersButton filters={filters} selectedSuppliers={selectedSuppliers} />
        </div>
      </div>
      <QuickFilterBar
        filters={filters}
        onClearFilter={(key) => debouncedSetFilters({ ...filters, [key]: undefined })}
        onFilterClick={() => setIsFilterDrawerOpen(true)}
        hiddenFilterSections={hiddenFilterSections}
      />
      <SupplierTable
        filters={filters}
        onSortChange={(sort) => setFilters({ ...filters, sort })}
        selectedColumns={selectedColumns.map((f) => f.field)}
        selectedRows={selectedSuppliers}
        onSelectedRowsChange={(rows) => setSelectedSuppliers(rows)}
        linkToBuyerRelationshipPage={linkToBuyerRelationshipPage}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <FilterForm
            onChange={debouncedSetFilters}
            filters={filters}
            showTitle={isCollapseEnabled}
            closeDrawer={() => setIsFilterDrawerOpen(false)}
            hiddenSections={hiddenFilterSections}
          />
        )}
      </FilterDrawer>
    </div>
  );
}
