import React from "react";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import css from "./FilterFormTitle.module.scss";

export interface FilterFormTitleProps {
  close: () => void;
  clearFilters?: () => void;
  showClear?: boolean;
}

function FilterFormTitle({ clearFilters, close, showClear }: FilterFormTitleProps) {
  return (
    <div className={css.wrapper}>
      <div className={css.titleContainer}>
        <Button
          size={"middle"}
          icon={<DoubleRightOutlined />}
          className={css.icon}
          onClick={() => close()}
        />
        <h3 className={css.title}>Filters</h3>
      </div>
      <div className={css.buttonWrapper}>
        {showClear && clearFilters && (
          <Button type="link" onClick={() => clearFilters()}>
            Clear all
          </Button>
        )}
      </div>
    </div>
  );
}

export default FilterFormTitle;
