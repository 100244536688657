import React from "react";

import RightChevron from "lib/icons/RightChevron";
import Signal from "lib/icons/Signal";
import { SupplierSelectCategory } from "./SuppliersDropdown";

import css from "./CategoryDropdown.module.scss";

type Props = {
  categories: SupplierSelectCategory[];
  handleCategorySelection: (category: SupplierSelectCategory) => void;
  getCategoryLabel: (category: SupplierSelectCategory) => string;
};

function CategoryDropdown({ categories, handleCategorySelection, getCategoryLabel }: Props) {
  return (
    <>
      {categories.map((category) => (
        <li
          key={category}
          className={css.option}
          onClick={(e) => {
            handleCategorySelection(category);
            e.stopPropagation();
          }}
        >
          <div className={css.optionlabel}>
            {(category === "Competitor" || category === "Partner") && (
              <Signal className={css.signalIcon} size={16} label="signalIcon" />
            )}
            <span>{getCategoryLabel(category)}</span>
          </div>
          <RightChevron />
        </li>
      ))}
    </>
  );
}

export default CategoryDropdown;
