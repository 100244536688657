import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  SearchOrganisationsQuery,
  SearchOrganisationsRequest,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlInfiniteQuery, useGraphQlQuery } from "../useGraphQlClient";

const searchOrganisations = graphql(`
  query searchOrganisations($request: SearchOrganisationsRequest!) {
    searchOrganisations(SearchOrganisationsRequest: $request) {
      orgs {
        id
        name
        url
        countryCode
      }
      total
    }
  }
`);
type SearchOrganisationsResponse = SearchOrganisationsQuery["searchOrganisations"];
export type OrgBasicInfo = SearchOrganisationsResponse["orgs"][0];

export function useSearchOrganisations(
  searchOrgsRequest: SearchOrganisationsRequest,
  options?: UseQueryOptions<SearchOrganisationsQuery, unknown, SearchOrganisationsQuery, QueryKey>,
) {
  return useGraphQlQuery(
    ["organisations", searchOrgsRequest],
    searchOrganisations,
    [{ request: searchOrgsRequest }],
    {
      ...options,
    },
  );
}

export function useInfiniteSearchOrganisations(searchOrgsRequest: SearchOrganisationsRequest) {
  return useGraphQlInfiniteQuery<SearchOrganisationsQuery, { request: SearchOrganisationsRequest }>(
    ["organisations", searchOrgsRequest],
    searchOrganisations,
    [{ request: searchOrgsRequest }],
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalSuppliers = lastPage.searchOrganisations.total;
        const totalLoadedSuppliers = allPages.reduce(
          (acc, page) => acc + page.searchOrganisations.orgs.length,
          0,
        );
        return totalSuppliers > totalLoadedSuppliers ? allPages.length + 1 : undefined;
      },
      getPreviousPageParam: (_, allPages) => allPages.length - 1 || 1,
    },
  );
}
