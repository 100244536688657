import * as React from "react";
import { Dropdown } from "antd5";

import { ScreenSize, useResponsiveScreenSize } from "lib/providers/Responsive";
import { performLogout } from "lib/userUtils";
import { TextLink } from "../actions/Links";
import { MenuButton, NavBarButton } from "./AppLayoutNavbar";

export default function SettingsDropdown(): JSX.Element | null {
  const screenSize = useResponsiveScreenSize();

  const menu = {
    items: [
      {
        key: "feedSettings",
        label: <TextLink to="/account-management/feed-settings">Signal settings</TextLink>,
      },
      {
        key: "notifications",
        label: <TextLink to="/account-management/notifications">Notifications</TextLink>,
      },
      {
        key: "myteam",
        label: <TextLink to="/account-management/my-team">My team</TextLink>,
      },
      {
        type: "divider",
      },
      {
        key: "subscription",
        label: <TextLink to="/account-management/subscription">Subscription</TextLink>,
      },
      {
        key: "accountSettings",
        label: <TextLink to="/account-management/change-password">Account Settings</TextLink>,
      },
      {
        key: "integrations",
        label: <TextLink to="/account-management/integrations">Integrations</TextLink>,
      },
      {
        type: "divider",
      },
      {
        key: "logout",
        label: <MenuButton onClick={() => performLogout()}>Logout</MenuButton>,
      },
    ],
  };

  if (screenSize > ScreenSize.XS && !!window.currentUser && !!window.currentUser.company) {
    return (
      <Dropdown menu={menu} trigger={["click"]} placement="bottomRight">
        <NavBarButton>Settings</NavBarButton>
      </Dropdown>
    );
  } else return null;
}
