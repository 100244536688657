import * as React from "react";
import { ContactsOutlined } from "@ant-design/icons";
import { Card } from "antd5";
import qs from "qs";

import { ButtonLink, TextLink } from "components/actions/Links";
import { DEFAULT_CONTACT_FILTERS } from "components/contacts/contactUtils";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";

import css from "./ContactCard.module.scss";

type Props = {
  name?: string;
  address?: string;
  phone?: string;
  email?: string;
  website?: string;
  buyerIds?: number[];
  showWebsite?: boolean;
  showContactsButton?: boolean;
  isLoading?: boolean;
  className?: string;
};

type ContactLinkProps = {
  contactLink: string | undefined;
  contactLinkType?: "website" | "email";
};

function ContactLink({ contactLink, contactLinkType = "website" }: ContactLinkProps) {
  if (!contactLink) {
    return <span>--</span>;
  }

  return (
    <TextLink
      targetType="new-tab"
      to={contactLinkType === "website" ? contactLink : `mailto:${contactLink}`}
      className={css.link}
    >
      {contactLink}
    </TextLink>
  );
}

/**
 * Given a {@link ContactDetailsDto} object, renders a card with the contact details that are present.
 * @param param0
 * @returns
 */
export default function ContactCard({
  name,
  address,
  email,
  phone,
  website,
  buyerIds,
  showWebsite,
  showContactsButton,
  isLoading,
  className,
}: Props): JSX.Element {
  const openGuestAccessPassPaywall = useRestrictedGuestAccess();

  const contactsQueryString = qs.stringify(
    { contactsFilters: { ...DEFAULT_CONTACT_FILTERS, buyerIds: buyerIds } },
    { arrayFormat: "brackets" },
  );

  const handleGuestAccess = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (window.guestUser) {
      e.preventDefault();
      openGuestAccessPassPaywall();
    }
  };

  return (
    <Card loading={isLoading} aria-label="Contact details">
      <div className={css.cardContent}>
        <h3 className={css.title}>Procurement contact</h3>
        <div className={css.contactDeets}>
          <ContactDetail className={className} title="Name" value={name} />
          <ContactDetail
            title="Email"
            value={<ContactLink contactLink={email} contactLinkType="email" />}
          />
          <ContactDetail title="Telephone" value={phone} />
          <ContactDetail title="Address" value={address} />
          {showWebsite && (
            <ContactDetail title="Website" value={<ContactLink contactLink={website} />} />
          )}
        </div>
        {showContactsButton && (
          <ButtonLink
            to={`/contacts?${contactsQueryString}`}
            type="default"
            targetType="new-tab"
            onClick={handleGuestAccess}
          >
            <ContactsOutlined className={css.link} /> Find decision maker
          </ButtonLink>
        )}
      </div>
    </Card>
  );
}

type ContactDetailProps = {
  title: string;
  value?: React.ReactNode;
  className?: string;
};
/**
 * Displays the title and value of a ContactDetail, if it exists.
 * @param param0
 * @returns
 */
function ContactDetail({ title, value, className }: ContactDetailProps): JSX.Element {
  return (
    <>
      <span className={className ?? css.detail}>{title}</span>
      <span className={css.value}>{value || "--"}</span>
    </>
  );
}
