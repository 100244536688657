import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  UpdateSignalSettingsMutation,
  UpdateSignalSettingsMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const updateSignalSettings = graphql(`
  mutation updateSignalSettings($input: UpdateSignalSettings!) {
    updateSignalSettings(UpdateSignalSettings: $input) {
      countries
      keywords
      cpvCodes
      competitorGuids
      partnerGuids
      useSupplierName
    }
  }
`);

export function useUpdateSignalSettings(
  options?: UseMutationOptions<
    UpdateSignalSettingsMutation,
    unknown,
    UpdateSignalSettingsMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(updateSignalSettings, {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["savedSuppliers"]);
      void queryClient.invalidateQueries(["frameworkSignals"]);
      void queryClient.invalidateQueries(["noticeSignals"]);
      void queryClient.invalidateQueries(["signalSettings"]);
      void queryClient.invalidateQueries(["suppliers"]);
      void queryClient.invalidateQueries(["signalStats"]);
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });

  return { data: data, ...rest };
}
