import * as React from "react";
import { SafetyCertificateTwoTone } from "@ant-design/icons";
import { IconProps } from "antd/lib/icon";

function StotlesVerifiedIcon(
  props: Omit<IconProps, "type" | "theme" | "twoToneColor">,
): JSX.Element {
  const { style, ...otherProps } = props;

  return (
    <SafetyCertificateTwoTone
      // these are just default styles which will be overridden by other style props passed in
      style={{ verticalAlign: 0, marginLeft: "4px", ...style }}
      {...otherProps}
      type="safety-certificate"
      twoToneColor="#4f86fa"
    />
  );
}

export default StotlesVerifiedIcon;
