/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ViewConfigurationFilterSettingsSuppliers
 */
export interface ViewConfigurationFilterSettingsSuppliers {
    /**
     * 
     * @type {Array<number>}
     * @memberof ViewConfigurationFilterSettingsSuppliers
     */
    id?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ViewConfigurationFilterSettingsSuppliers
     */
    isSme?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettingsSuppliers
     */
    countryCode?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettingsSuppliers
     */
    guid?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettingsSuppliers
     */
    includeInGuids?: Array<ViewConfigurationFilterSettingsSuppliersIncludeInGuidsEnum>;
}


/**
 * @export
 */
export const ViewConfigurationFilterSettingsSuppliersIncludeInGuidsEnum = {
    Keywords: 'ALL_SIGNALS:Keywords',
    Competitors: 'ALL_SIGNALS:Competitors',
    Partners: 'ALL_SIGNALS:Partners',
    CpvCodes: 'ALL_SIGNALS:CPV codes'
} as const;
export type ViewConfigurationFilterSettingsSuppliersIncludeInGuidsEnum = typeof ViewConfigurationFilterSettingsSuppliersIncludeInGuidsEnum[keyof typeof ViewConfigurationFilterSettingsSuppliersIncludeInGuidsEnum];


/**
 * Check if a given object implements the ViewConfigurationFilterSettingsSuppliers interface.
 */
export function instanceOfViewConfigurationFilterSettingsSuppliers(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ViewConfigurationFilterSettingsSuppliersFromJSON(json: any): ViewConfigurationFilterSettingsSuppliers {
    return ViewConfigurationFilterSettingsSuppliersFromJSONTyped(json, false);
}

export function ViewConfigurationFilterSettingsSuppliersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewConfigurationFilterSettingsSuppliers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isSme': !exists(json, 'is_sme') ? undefined : json['is_sme'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'includeInGuids': !exists(json, 'includeInGuids') ? undefined : json['includeInGuids'],
    };
}

export function ViewConfigurationFilterSettingsSuppliersToJSON(value?: ViewConfigurationFilterSettingsSuppliers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'is_sme': value.isSme,
        'country_code': value.countryCode,
        'guid': value.guid,
        'includeInGuids': value.includeInGuids,
    };
}

