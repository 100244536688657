import * as React from "react";
import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd"; // convert button to text button and verify, convert dropdown to use menuprops, naughty global ant classes here
import moment from "moment";

import { FeedPreviewPageSources } from "components/onboarding/SharedOnboarding";
import * as tracking from "lib/tracking";

import css from "./DateFilterDropdown.module.scss";

type Props = {
  dateValue: Date | undefined;
  handleDateFilter: (date?: Date) => void;
  pageSource: FeedPreviewPageSources;
};

function DateFilterDropdown({ dateValue, handleDateFilter, pageSource }: Props): JSX.Element {
  // This could easily be a prop to make this component reusable but we don't need to currently
  const dateOptionMap: { [label: string]: Date | undefined } = React.useMemo(() => {
    return {
      "In total": undefined,
      "In the past week": moment().startOf("day").subtract(1, "week").toDate(),
      "In the past month": moment().startOf("day").subtract(1, "month").toDate(),
    };
  }, []);

  const selectedValue = React.useMemo(
    () =>
      Object.keys(dateOptionMap).find((label) => dateOptionMap[label] === dateValue) || "In total",
    [dateValue, dateOptionMap],
  );

  const renderLookBackFilter = React.useMemo(
    () => (
      <Menu selectable selectedKeys={dateValue && [dateValue.toString()]}>
        {Object.entries(dateOptionMap).map(([label, date]) => (
          <Menu.Item
            className={css.menuItem}
            key={label}
            onClick={() => {
              tracking.logEvent(tracking.EventNames.feedPreviewFiltered, {
                "Context source": "Date filter dropdown",
                value: date,
                "Page source": pageSource,
              });
              handleDateFilter(date);
            }}
          >
            {label}
            {label === selectedValue && <CheckOutlined className={css.checkIcon} />}
          </Menu.Item>
        ))}
      </Menu>
    ),
    [selectedValue, handleDateFilter, dateValue, dateOptionMap, pageSource],
  );
  return (
    <Dropdown overlayClassName={css.dateFilterDropdown} overlay={renderLookBackFilter}>
      <Button
        type="link"
        className={css.selectedValue}
        onClick={() => {
          tracking.logEvent(tracking.EventNames.feedPreviewFilterButtonClicked, {
            "Context source": "Date filter dropdown",
            "Page source": pageSource,
          });
        }}
      >
        {/* ant-5 change this to TextButton and verify */}
        {selectedValue.toLowerCase()} <DownOutlined className={css.downIcon} />
      </Button>
    </Dropdown>
  );
}

export default DateFilterDropdown;
