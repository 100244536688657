import * as React from "react";
import { List, Popover } from "antd"; // upgrade and verify
import classnames from "classnames";

import BuyerCategoryLabel from "lib/core_components/BuyerCategoryLabel";
import { BuyerCategoryAssignment } from "lib/types/models";

import css from "./BuyerCategoriesWithPopover.module.scss";

type Props = {
  buyerCategories: BuyerCategoryAssignment[];
  buyerName: string;
  size?: "small" | "default"; // small - font-size 12px, normal: default font size
};

function BuyerCategoriesWithPopover({
  buyerCategories,
  buyerName,
  size = "small",
}: Props): JSX.Element | null {
  if (buyerCategories.length === 0) {
    return null;
  }
  const [firstItem, ...remainingItems] = buyerCategories;
  return (
    <div
      className={classnames(css.buyerCategoriesCell, size === "small" ? css.labelSizeSmall : null)}
    >
      <BuyerCategoryLabel buyerCategoryId={firstItem.buyer_category_id} size={size} />
      {remainingItems.length > 0 ? (
        <>
          {" "}
          &amp;{" "}
          <Popover
            trigger="hover"
            title={<b>More buyer types for "{buyerName}"</b>}
            placement="left"
            content={
              <List>
                {remainingItems.map((c) => (
                  <List.Item key={c.buyer_category_id} className={css.buyerCategoryItem}>
                    <BuyerCategoryLabel buyerCategoryId={c.buyer_category_id} />
                  </List.Item>
                ))}
              </List>
            }
          >
            <p className={css.popoverTrigger}>{remainingItems.length} more</p>
          </Popover>
        </>
      ) : null}
    </div>
  );
}

export default BuyerCategoriesWithPopover;
