import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ContactParams } from "components/contacts/contactUtils";
import { ContactSearchResponseDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "./utils";

type QueryKey = [string, ContactParams];

export function useContactSearch(
  filters: ContactParams,
  options?: UseQueryOptions<ContactSearchResponseDto, unknown, ContactSearchResponseDto, QueryKey>,
) {
  const api = useOpenApi();
  const includeUncategorised = filters.jobFunction.includes("Uncategorised");

  return useQuery(
    ["ContactsSearch", filters],
    () =>
      api.contactsSearch({
        contactSearchRequestDto: {
          jobTitle: filters.jobTitle,
          firstName: filters.firstName,
          lastName: filters.lastName,
          seniority: filters.seniority,
          jobFunction: filters.jobFunction,
          includeEmptyJobFunction: includeUncategorised,
          buyerIds: filters.buyerIds,
          buyerListIds: filters.buyerListIds,
          buyerCategoryIds: filters.buyerCategoryIds,
          includeUncategorisedBuyers: filters.includeUncategorisedBuyers,
          limit: filters.pageSize,
          offset: (filters.currentPage - 1) * filters.pageSize,
          sort: "job_title",
          sortOrder: "ASC",
          contactIds: [],
          contactDisplayOptions: filters.contactDisplayOptions,
        },
      }),
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options },
  );
}
