import React, { useMemo } from "react";
import classnames from "classnames";

import PaywallModal from "components/paywall/PaywallModal";
import { convertDataTypeToFeatureType } from "components/paywall/paywallUtils";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { useSubscription } from "./Subscription";

import css from "./ProHelper.module.scss";

export type ProHelperDataTypes = "AWARDS" | "BUYERS" | "SUPPLIERS" | "CONTACTS";

type ProHelper = {
  checkSubscription: (required?: ProHelperDataTypes, eventData?: EventData) => boolean;
};

// returns a callback that can be used to check if the user has the required subscription on an event handler

export function useCheckSubscription(
  required: ProHelperDataTypes | undefined,
  eventData?: EventData,
): { checkSubscription: () => boolean; authorised: boolean } {
  const { checkSubscription } = useProHelper();
  const { hasDataTypes } = useSubscription();
  const checkSubscriptionCallback = React.useCallback(
    () => checkSubscription(required, eventData),
    [checkSubscription, required, eventData],
  );

  return {
    checkSubscription: checkSubscriptionCallback,
    authorised: !required || hasDataTypes(required),
  };
}
const ProHelperContext = React.createContext<ProHelper | undefined>(undefined);

type ProHelperProviderProps = {
  children: React.ReactNode;
};

export function ProHelperProvider({ children }: ProHelperProviderProps): JSX.Element {
  const subscription = useSubscription();

  const [requestedProFeature, setRequestedProFeature] = React.useState<ProHelperDataTypes>();
  const [contextSource, setContextSource] = React.useState<string>();
  const { logEvent } = useTracking();

  const onClose = () => {
    setRequestedProFeature(undefined);
  };

  const hasDataType = requestedProFeature ? subscription.hasDataTypes(requestedProFeature) : true;

  const context = useMemo(
    () => ({
      checkSubscription: (required?: ProHelperDataTypes, eventData?: EventData) => {
        setRequestedProFeature(required);
        setContextSource(eventData?.["Context source"]);
        const hasDataType = !required || subscription.hasDataTypes(required);
        if (!hasDataType) {
          logEvent(EventNames.paywallViewed, {
            ...eventData,
            "Data source required": required,
          });
        }
        return hasDataType;
      },
    }),
    [logEvent, subscription],
  );

  return (
    <ProHelperContext.Provider value={context}>
      {children}
      {requestedProFeature && (
        <PaywallModal
          featureType={convertDataTypeToFeatureType(requestedProFeature)}
          contextSource={contextSource}
          onClose={onClose}
          isOpen={!hasDataType}
        />
      )}
    </ProHelperContext.Provider>
  );
}

export function useProHelper(): ProHelper {
  const { trackingData } = useTracking();
  const context = React.useContext(ProHelperContext);
  if (context === undefined) {
    throw new Error("useProHelper must be used within a ProHelperProvider");
  }
  const checkSubscription = React.useCallback(
    (required?: ProHelperDataTypes, eventData?: EventData) => {
      return context.checkSubscription(required, { ...trackingData, ...eventData });
    },
    [context, trackingData],
  );
  return { ...context, checkSubscription };
}

export function TriggerHelperButton({
  requiredType,
  children,
  eventData,
  classname,
}: React.PropsWithChildren<{
  requiredType: ProHelperDataTypes;
  eventData?: EventData;
  classname?: string;
}>): JSX.Element {
  const { checkSubscription } = useProHelper();
  const openHelper = React.useCallback(
    () => checkSubscription(requiredType, eventData),
    [checkSubscription, eventData, requiredType],
  );

  return (
    <button className={classnames(classname, css.triggerButton)} onClick={openHelper}>
      {children}
    </button>
  );
}
