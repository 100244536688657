import React, { useEffect, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Input, Tag, Tooltip } from "antd5";
import _ from "lodash";

import PaywallPopover from "components/paywall/PaywallPopover";
import { useDebouncedValue } from "lib/debounce";
import { useSearchOrganisations } from "lib/hooks/api/organisations/useSearchOrganisations";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { OrgPrimaryRole, OrgSortBy, OrgSortOrder } from "lib/types/graphQLEnums";
import { isDefined } from "lib/utils";
import { useSignalSettingsGQL } from "../../lib/hooks/api/teams/useSignalSettingsGQL";
import Signal from "../../lib/icons/Signal";
import SuppliersDropdown, {
  SupplierOption,
} from "./dropdown_list_selects/new_supplier_selects/SuppliersDropdown";
import { SelectProps } from "./Inputs";

import css from "./NewSupplierSelect.module.scss";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options">;

export function NewSupplierSelect<T extends FieldValues>(props: FieldProps<T>) {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);
  const [isOpen, setIsOpen] = useState(false);

  const { field } = useController(props);

  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Supplier filter",
  });

  const hasSelectedSuppliers = !!field.value && field.value.length > 0;

  const [supplierLabels, setSupplierLabels] = useState<Record<string, string>>({});

  // On initial load, fetch the labels for the selected suppliers
  // This doesn't include textSearch
  const { data: initialOrganisations } = useSearchOrganisations(
    {
      textSearch: "",
      sortBy: OrgSortBy.Relevance,
      sortOrder: OrgSortOrder.Desc,
      primaryRole: OrgPrimaryRole.Supplier,
      ids: field.value ? field.value.filter(isDefined) : [],
      limit: field.value ? field.value.length : 1,
      page: 1,
    },
    {
      enabled: field.value
        ? field.value.length > 0 && Object.keys(supplierLabels).length === 0
        : false,
    },
  );

  const { data: signals, isLoading: isLoadingSignals } = useSignalSettingsGQL();

  const competitors =
    signals?.competitors?.map((s) => ({
      label: s.name,
      value: s.id,
    })) || [];

  const partners =
    signals?.partners?.map((s) => ({
      label: s.name,
      value: s.id,
    })) || [];

  const signalIds = competitors.concat(partners).map((s) => s.value);

  useEffect(() => {
    if (initialOrganisations) {
      setSupplierLabels((prev) => {
        const newLabels = { ...prev };
        if (initialOrganisations?.searchOrganisations.orgs) {
          initialOrganisations.searchOrganisations.orgs.forEach((s) => {
            newLabels[s.id] = s.name;
          });
        }
        return newLabels;
      });
    }
  }, [initialOrganisations]);

  const selectedSuppliers: SupplierOption[] =
    hasSelectedSuppliers && Object.keys(supplierLabels).length > 0
      ? field.value.filter(isDefined).map((s: string) => ({ label: supplierLabels[s], value: s }))
      : [];

  function handleSuppliersSelection(
    modifiedSuppliers: SupplierOption[],
    operation: "add" | "remove" | "clear",
  ) {
    let newSelection = selectedSuppliers;
    setSupplierLabels((prev) => {
      const newLabels = {
        ...prev,
      };
      modifiedSuppliers.forEach((s) => {
        newLabels[s.value] = s.label;
      });
      return newLabels;
    });
    switch (operation) {
      case "add": {
        newSelection = _.uniqBy([...newSelection, ...modifiedSuppliers], "value");
        break;
      }
      case "remove": {
        newSelection = newSelection.filter(
          (s) => !modifiedSuppliers.some((sup) => sup.value === s.value),
        );
        break;
      }
      case "clear": {
        newSelection = [];
        break;
      }
    }
    field.onChange(newSelection.map((s) => s.value));
  }

  function getToolTip(supplierId: string) {
    if (signals?.competitors?.find((s) => s.id === supplierId)) {
      if (signals?.partners?.find((s) => s.id === supplierId)) {
        return "Competitor and partner signal";
      }
      return "Competitor signal";
    }

    if (signals?.partners?.find((s) => s.id === supplierId)) {
      return "Partner signal";
    }
  }

  return (
    <PaywallPopover featureType="SUPPLIERS">
      <div
        onBlur={(e) => {
          if (e.currentTarget.contains(e.relatedTarget as Node)) {
            return;
          }
          setIsOpen(false);
        }}
      >
        <div className={css.filterLabelDiv} aria-label="filterLabel">
          <span>Suppliers</span>
          {field.value && field.value.length > 0 && (
            <Button
              className={css.clearButton}
              onClick={() => handleSuppliersSelection([], "clear")}
            >
              Clear
            </Button>
          )}
        </div>
        <div tabIndex={0} className={css.container}>
          <Input
            aria-label="supplierSearchInput"
            className={hasSuppliers ? css.inputField : css.disabledInputField}
            onClick={() => {
              if (hasSuppliers) {
                setIsOpen(true);
              }
            }}
            onChange={(e) => {
              const newValue = e.currentTarget.value.trim();

              // if clearing the search text, close the dropdown
              if (newValue.length === 0 && searchText.length > 0) {
                setIsOpen(false);
              }

              // if we're typing, open the dropdown
              if (newValue.length > 0 && !isOpen) {
                setIsOpen(true);
              }

              setSearchText(e.currentTarget.value.trim());
            }}
            placeholder={isOpen ? undefined : "Search competitors, partners and other suppliers"}
          />
          <SuppliersDropdown
            handleSuppliersSelection={handleSuppliersSelection}
            textSearch={debouncedText}
            isOpen={isOpen}
            value={field.value || []}
            competitors={competitors}
            partners={partners}
            isLoading={isLoadingSignals}
          />
          {field.value && field.value.length > 0 && (
            <div className={css.supplierSelections} aria-label="supplierSelections">
              <div className={css.tagContainer}>
                {field.value.map((i: string) => {
                  const supplier = { label: supplierLabels[i], value: i };
                  const isSignal = signalIds.includes(supplier.value);

                  const tag = (
                    <Tag
                      className={css.tag}
                      onClose={() => handleSuppliersSelection([supplier], "remove")}
                      closeIcon={<CloseOutlined />}
                      key={supplier.value}
                    >
                      {isSignal && (
                        <Signal className={css.signalIcon} size={16} label="signalIcon" />
                      )}
                      <span className={css.tagLabel}>{supplier.label}</span>
                    </Tag>
                  );
                  return isSignal ? (
                    <Tooltip
                      title={getToolTip(supplier.value)}
                      key={supplier.value}
                      placement="bottom"
                    >
                      {tag}
                    </Tooltip>
                  ) : (
                    tag
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </PaywallPopover>
  );
}
