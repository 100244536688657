import * as React from "react";
import classnames from "classnames";

import css from "./Labels.module.scss";

type IconProps = {
  large?: boolean;
};

export const ProIcon = ({
  large,
  ...props
}: IconProps & React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div
    {...props}
    className={classnames(props.className, css.proIcon, { [css.largePlanIcon]: large })}
  >
    PRO
  </div>
);

type BetaIconProps = {
  noMargin?: boolean;
  dark?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const BetaIcon = (props: BetaIconProps): JSX.Element => (
  <div
    {...props}
    className={classnames(props.className, css.betaIcon, {
      [css.noMargin]: props.noMargin,
      [css.dark]: props.dark,
    })}
  >
    beta
  </div>
);

export const FreeIcon = ({ large }: IconProps): JSX.Element => (
  <div className={classnames(css.freeIcon, { [css.largePlanIcon]: large })}>FREE</div>
);
