import React, { useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { Empty } from "antd5";
import { isEqual, startCase } from "lodash";
import { ActiveSupplierTab } from "pages/app/SupplierSearchPage";
import useDeepCompareEffect from "use-deep-compare-effect";

import { TextLink } from "components/actions/Links";
import { FrameworkCallOffTableCell } from "components/framework/FrameworkCallOffTableCell";
import { generateNoticeUrl } from "components/notices/utils";
import PaywallPopover from "components/paywall/PaywallPopover";
import { LinkCell } from "components/table_components/LinkCell";
import SignalsContainer from "components/tags/SignalsContainer";
import { getBuyerSupplierRelationshipDestination } from "lib/appDestinations";
import { stringSort } from "lib/columnSort";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { IN_ROW_STAT_LINK, Table } from "lib/core_components/Table/Table";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { SupplierSummary } from "lib/generated/app-api";
import { Framework_Lots } from "lib/generated/app-service-gql/graphql";
import { SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs } from "lib/generated/data-svc";
import { useSuppliers } from "lib/hooks/api/suppliers/useSuppliers";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { SignalCategory } from "lib/StotlesApi";
import { tagColourFromSignal } from "lib/tagUtils";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { EM_DASH } from "lib/utils";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import { SavedSuppliersEmpty } from "./SavedSuppliersEmpty";
import { SpendDataCell } from "./SpendDataCell";
import { SupplierNameCell } from "./SupplierNameCell";
import {
  convertSupplierFiltersToRequest,
  isValidSortField,
  SupplierColumns,
  SupplierFilters,
} from "./utils";

import css from "./SupplierTable.module.scss";

import SupplierIcon from "../../../assets/images/icons/supplier.svg";

export const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };

function getSortOrder(key: string, sort?: SortState): "ascend" | "descend" | null {
  return sort?.field !== key ? null : sort.order === "ASC" ? "ascend" : "descend";
}

const buyerFiltersAreApplied = (filters: SupplierFilters) => {
  const { buyerIds = [], buyerLists = [], buyerCategories = [] } = filters;
  return buyerIds.length > 0 || buyerLists.length > 0 || buyerCategories.length > 0;
};

function getSupplierColumns({
  filters,
  authorised,
  onUnauthorisedClick,
  selectedColumns,
  additionalData,
  linkToBuyerRelationshipPage,
  supplierTableTrackingData,
  enableNewSupplierFilter,
}: {
  filters: SupplierFilters;
  authorised: boolean;
  onUnauthorisedClick: () => void;
  selectedColumns: SupplierColumns[];
  additionalData: AdditionalSupplierData | undefined;
  linkToBuyerRelationshipPage?: { buyerGuid: string };
  supplierTableTrackingData?: EventData;
  enableNewSupplierFilter?: boolean;
}) {
  const newSupplierFilterEnabled =
    enableNewSupplierFilter && window.currentUser?.use_supplier_name === false;
  const name: ColumnType<SupplierSummary> = {
    title: "Supplier",
    dataIndex: "name",
    key: "name",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: stringSort((r) => (r as SupplierSummary).name),
    sortOrder: getSortOrder("name", filters.sort),
    render: (_, s) => (
      <PaywallPopover
        featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "SUPPLIERS"}
        showModalOnClick={!!window.guestUser}
        contextSource="In-row"
        trackingData={supplierTableTrackingData}
      >
        {!window.guestUser && enableNewSupplierFilter ? (
          <SupplierNameCell
            supplierGuid={s.guid}
            supplierName={s.name}
            contextSource="Supplier search"
          />
        ) : (
          <TextLink
            to={
              linkToBuyerRelationshipPage
                ? getBuyerSupplierRelationshipDestination(s.guid, "", "supplier")
                : `/suppliers/${s.id}`
            }
            useWouter={!!linkToBuyerRelationshipPage}
            authorised={authorised && !window.guestUser}
            onUnauthorisedClick={onUnauthorisedClick}
            className={css.supplierTextLink}
          >
            <img src={SupplierIcon} />
            <b>{s.name}</b>
          </TextLink>
        )}
      </PaywallPopover>
    ),
    sizeConfig: {
      small: 200,
      medium: 300,
      large: 400,
      xlarge: 500,
    },
  };

  const country: ColumnType<SupplierSummary> = {
    ...commonTableColumns.countryColumn,
    title: "Country",
    dataIndex: "country",
    key: "country",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    sortOrder: getSortOrder("country", filters.sort),
  };

  const sme: ColumnType<SupplierSummary> = {
    ...commonTableColumns.isSMEColumn,
    title: "Is SME",
    key: "sme",
    dataIndex: "isSme",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    sortOrder: getSortOrder("sme", filters.sort),
    render: (_, s) => (s.isSme ? "Yes" : s.isSme === false ? "No" : EM_DASH),
    sizeConfig: {
      small: 100,
      medium: 100,
      large: 100,
      xlarge: 100,
    },
  };

  const notices: ColumnType<SupplierSummary> = {
    key: "awards_count",
    dataIndex: "awardsCount",
    title: "Notices",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    sortOrder: getSortOrder("awards_count", filters.sort),

    // Setting this to stop the default clickthrough event if user doesn't exactly click awards link
    onCell: (_rec) => ({
      onClick: (event) => event.stopPropagation(),
    }),

    render: (value, s) => (
      <PaywallPopover
        featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "SUPPLIERS"}
        showModalOnClick={!!window.guestUser}
        contextSource="In-row"
        trackingData={supplierTableTrackingData}
      >
        {linkToBuyerRelationshipPage ? (
          <LinkCell
            destination={getBuyerSupplierRelationshipDestination(s.guid, "", "supplier")}
            value={value}
            tracking={{
              eventName: EventNames.highlightClicked,
              data: {
                "Highlight type": "Supplier awards",
              },
            }}
            onUnauthorisedClick={onUnauthorisedClick}
            authorised={!window.guestUser}
          />
        ) : (
          <TextLink
            to={generateNoticeUrl(
              {
                signals: filters.signals,
                buyers: filters.buyerIds,
                buyerCategories: filters.buyerCategories,
                buyerListIds: filters.buyerLists,
                suppliers: [s.id],
                supplierGuids: [s.guid],
                // when supplier mention filter is enabled, make sure to filter by directly awarded, otherwise counts don't match
                supplierMentionType: newSupplierFilterEnabled
                  ? SupplierMentionType.DirectlyAwarded
                  : undefined,
              },
              `/suppliers/${s.id}`,
            )}
            eventName={EventNames.highlightClicked}
            eventAttributes={{
              "Highlight type": "Supplier awards",
            }}
            className={IN_ROW_STAT_LINK}
            authorised={authorised && !window.guestUser}
            onUnauthorisedClick={onUnauthorisedClick}
          >
            {value}
          </TextLink>
        )}
      </PaywallPopover>
    ),
    align: "left",
    sizeConfig: {
      small: 150,
      medium: 150,
      large: 175,
      xlarge: 175,
    },
  };

  const upcomingExpiries: ColumnType<SupplierSummary> = {
    key: "expiries_count",
    dataIndex: "expiriesCount",
    title: "Upcoming expiries",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    sortOrder: getSortOrder("expiries_count", filters.sort),

    // Setting this to stop the default clickthrough event if user doesn't exactly click awards link
    onCell: (_rec) => ({
      onClick: (event) => event.stopPropagation(),
    }),

    ...commonTableColumns.expandableColumn,
    render: (_, s) => (
      <PaywallPopover
        featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "SUPPLIERS"}
        showModalOnClick={!!window.guestUser}
        contextSource="In-row"
        trackingData={supplierTableTrackingData}
      >
        {linkToBuyerRelationshipPage ? (
          <LinkCell
            destination={generateNoticeUrl(
              {
                supplierGuids: [s.guid],
                buyers: [linkToBuyerRelationshipPage.buyerGuid],
                expiryDate: { filter: { relativeFrom: "PT0S" }, hideNulls: true },
                stage: ["AWARDED"],
                // when supplier mention filter is enabled, make sure to filter by directly awarded, otherwise counts don't match
                supplierMentionType: newSupplierFilterEnabled
                  ? SupplierMentionType.DirectlyAwarded
                  : undefined,
                sort: { field: "expiry_date", order: "ASC" },
              },
              getBuyerSupplierRelationshipDestination(s.guid, "", "supplier"),
            )}
            value={s.expiriesCount}
            tracking={{
              eventName: EventNames.highlightClicked,
              data: { "Highlight type": "Supplier awards" },
            }}
            onUnauthorisedClick={onUnauthorisedClick}
            authorised={!window.guestUser}
          />
        ) : (
          <TextLink
            to={`/suppliers/${s.id}/upcoming_expiries`}
            eventName={EventNames.highlightClicked}
            eventAttributes={{
              "Highlight type": "Supplier upcoming expiries",
            }}
            className={IN_ROW_STAT_LINK}
            authorised={authorised && !window.guestUser}
            onUnauthorisedClick={onUnauthorisedClick}
          >
            {s.expiriesCount}
          </TextLink>
        )}
      </PaywallPopover>
    ),
    align: "left",
    sizeConfig: {
      small: 150,
      medium: 150,
      large: 175,
      xlarge: 175,
    },
  };

  const signals: ColumnType<SupplierSummary> = {
    dataIndex: "signals",
    key: "signals",
    title: "Signals",
    render: (_, s) => {
      if (s.signals) {
        const signals = s.signals.map((s) => ({
          ...s,
          colour: tagColourFromSignal(s.category),
        }));
        return (
          <SignalsContainer
            signals={signals}
            maxSignals={3}
            redactedSignalCategories={[
              SignalCategory.KEYWORD,
              SignalCategory.COMPETITOR,
              SignalCategory.PARTNER,
            ]}
            requiredDataType="SUPPLIERS"
            contextSource="In-row"
          />
        );
      }
    },
    align: "left",
    sortDirections: ["descend", "ascend", "descend"],
    // we only display this sort option when buyer filters are selected
    // this is due to issues with the current api not joining signals outside of that scenario
    sorter: buyerFiltersAreApplied(filters),
    sortOrder: getSortOrder("signals", filters.sort),
    sizeConfig: {
      small: 250,
      medium: 325,
      large: 300,
      xlarge: 500,
    },
  };

  const spendData: ColumnType<SupplierSummary> = {
    key: "spend",
    title: "Total spend",
    render: (_, s) => <SpendDataCell supplier={s.guid} buyers={filters.buyerIds} />,
  };

  const frameworkLots: ColumnType<SupplierSummary> = {
    key: "frameworkLots",
    title: "Lots",
    render: (_, s) => {
      if (!additionalData?.frameworkData) {
        throw new Error(`"Lots" column requested but framework lot data wasn't provided.`);
      }

      const lots = additionalData.frameworkData.get(s.guid)?.lots || [];
      return (
        <div className={css.lots}>
          {lots.map((lot) => (
            <EllipsisTooltipText
              key={lot.id}
              fullText={`${lot.id}: ${lot.title}`}
              containerClassname={css.lotName}
            />
          ))}
        </div>
      );
    },

    // TODO: Enable sorting for framework columns
    // sorter: true,
    // sortOrder: getSortOrder("frameworkLots", filters.sort),
    // sortDirections: ["descend", "ascend", "descend"],
    sizeConfig: {
      small: 240,
      medium: 240,
      large: 240,
      xlarge: 240,
    },
  };

  const frameworkLastActivity: ColumnType<SupplierSummary> = {
    key: "frameworkLastActivity",
    title: "Last activity",
    render: (_, s) => {
      if (!additionalData?.frameworkData) {
        throw new Error(`"Lots" column requested but framework lot data wasn't provided.`);
      }
      const frameworkData = additionalData.frameworkData.get(s.guid);
      const lastActivity = frameworkData?.callOffStats?.lastActivity;
      return lastActivity ? toHumanRelativeTimeFromNow(lastActivity) : "--";
    },

    // TODO: Enable sorting for framework columns
    // sorter: true,
    // sortOrder: getSortOrder("frameworkLastActivity", filters.sort),
    // sortDirections: ["descend", "ascend", "descend"],
  };

  const frameworkCallOffs: ColumnType<SupplierSummary> = {
    key: "frameworkCallOffs",
    title: "Call offs",
    render: (_, s) => {
      if (!additionalData?.frameworkData) {
        throw new Error(`"Lots" column requested but framework lot data wasn't provided.`);
      }
      const supplierFrameworkData = additionalData.frameworkData.get(s.guid);
      const callOffStats = supplierFrameworkData?.callOffStats;

      if (!supplierFrameworkData) {
        return null;
      }

      const { frameworkId } = supplierFrameworkData;

      return (
        <FrameworkCallOffTableCell
          averageAmount={callOffStats?.averageAmount.amount}
          callOffCount={callOffStats?.count}
          currency={callOffStats?.averageAmount.currency}
          linkTo={`/frameworks/${frameworkId}/call-offs?callOffFilters[suppliers][]=${s.id}`}
        />
      );
    },

    // TODO: Enable sorting for framework columns
    // sorter: true,
    // sortOrder: getSortOrder("frameworkCallOffs", filters.sort),
    // sortDirections: ["descend", "ascend", "descend"],
  };

  const columns = {
    name,
    country,
    sme,
    notices,
    signals,
    upcomingExpiries,
    spendData,
    frameworkLots,
    frameworkLastActivity,
    frameworkCallOffs,
  };

  return selectedColumns.map((c) => columns[c]);
}

type SortState = Exclude<SupplierFilters["sort"], undefined>;

/**
 * Add any extra data required by columns that are available
 * on specific screens. The columns shouldn't render (or even throw an error)
 * if the data is not available.
 *
 * If the data will be available but is loading, pass an empty map.
 * When the data is ready, construct a map with supplier GUIDs as keys.
 */
export type AdditionalSupplierData = {
  frameworkData?: Map<
    string,
    {
      lots: Framework_Lots[];
      callOffStats: SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs | undefined;
      frameworkId: string;
    }
  >;
};

type Props = {
  filters: SupplierFilters;
  onSortChange?: (sort: SortState) => void;
  selectedColumns: SupplierColumns[];
  selectedRows?: string[];
  onSelectedRowsChange?: (selectedRowKeys: string[], selectedRows: SupplierSummary[]) => void;
  linkToBuyerRelationshipPage?: { buyerGuid: string };
  additionalData?: AdditionalSupplierData;
  activeTab?: ActiveSupplierTab;
  setActiveTab?: (tab: ActiveSupplierTab) => void;
};

export function SupplierTable({
  filters,
  onSortChange,
  selectedColumns,
  selectedRows,
  onSelectedRowsChange,
  linkToBuyerRelationshipPage,
  additionalData,
  activeTab,
  setActiveTab,
}: Props) {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { isLoading, data, isError } = useSuppliers(
    convertSupplierFiltersToRequest(filters, pagination),
  );
  const { authorised, checkSubscription: onUnauthorisedClick } = useCheckSubscription("SUPPLIERS", {
    "Context source": "In-row",
  });

  useDeepCompareEffect(() => {
    onSelectedRowsChange?.([], []);
    setPagination(DEFAULT_PAGINATION);
  }, [filters]);

  const { logEvent, trackingData } = useTracking();

  const columns = React.useMemo(() => {
    const supplierTableTrackingData = { ...trackingData, "CTA actioned": "Supplier" };

    return getSupplierColumns({
      filters,
      authorised,
      onUnauthorisedClick,
      selectedColumns,
      additionalData,
      linkToBuyerRelationshipPage,
      supplierTableTrackingData,
      enableNewSupplierFilter,
    });
  }, [
    authorised,
    filters,
    additionalData,
    linkToBuyerRelationshipPage,
    onUnauthorisedClick,
    selectedColumns,
    trackingData,
    enableNewSupplierFilter,
  ]);

  if (
    activeTab &&
    setActiveTab &&
    activeTab !== ActiveSupplierTab.ALL_SUPPLIERS &&
    !filters.guids?.length
  ) {
    return <SavedSuppliersEmpty activeTab={activeTab} setActiveTab={setActiveTab} />;
  }

  return (
    <Table<SupplierSummary>
      dataSource={data?.results || []}
      columns={columns}
      loading={isLoading}
      pagination={{
        ...pagination,
        total: data?.pagingInfo.totalResults || 0,
        onChange: (page, pageSize) => {
          setPagination({ current: page, pageSize });
          logEvent(EventNames.paginationSelected, { Value: page });
        },
        showSizeChanger: true,
      }}
      onChange={(_, __, sorter) => {
        if (Array.isArray(sorter)) {
          sorter = sorter[0];
        }
        // If the sort order is not defined, we don't want to trigger a sort without an order
        if (!filters.sort && !sorter.order) {
          return;
        }
        const sortField = isValidSortField(sorter.columnKey?.toString());
        if (sortField) {
          const newSortState: SortState = {
            order: sorter.order === "descend" ? "DESC" : "ASC",
            field: sortField,
          };
          if (!isEqual(filters.sort, newSortState)) {
            onSortChange?.(newSortState);
            // TODO: maybe move all tracking?
            logEvent(EventNames.tableSorted, {
              Context: "Column action",
              "Sorted by": startCase(newSortState.field),
              "Sort order": newSortState.order,
            });
          }
        }
      }}
      rowSelection={
        onSelectedRowsChange &&
        selectedRows && {
          onChange: (selectedRowKeys, selectedRows) => {
            onSelectedRowsChange(selectedRowKeys as string[], selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRows,
        }
      }
      rowKey="guid"
      locale={{
        emptyText: isError ? (
          <Empty
            image={<WarningOutlined style={{ fontSize: 100 }} />}
            description="Sorry something has gone wrong"
          />
        ) : undefined,
      }}
    />
  );
}
