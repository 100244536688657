import React from "react";

import { getLotTitle } from "components/notices/utils";
import { PropertyInline } from "lib/core_components/Property";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { formatAmount, formatDate } from "lib/utils";
import OrganisationsContainer from "./OrganisationsPopover";

import css from "./LotDetails.module.scss";

type Lot = {
  lotNumber?: string | null;
  lotTitle?: string | null;
  value?: number | null;
  currency?: string | null;
  endDate?: string | null;
  suppliers?: { id: number; name: string }[] | null;
};

type LotProps = {
  lot: Lot;
  authorised?: boolean;
  onUnauthorisedClick?: () => boolean;
};

type LotsProps = {
  lots: Lot[];
};

function Lot({ lot, onUnauthorisedClick, authorised }: LotProps) {
  const lotTitle = getLotTitle(lot.lotNumber, lot.lotTitle);
  const value = formatAmount(lot.value, lot.currency);
  const endDate = lot.endDate ? formatDate(new Date(lot.endDate)) : null;

  return (
    <div className={css.lot}>
      {lotTitle && <h3 className={css.lotTitle}>{lotTitle}</h3>}
      <PropertyInline
        title="Supplier(s)"
        value={
          <OrganisationsContainer
            orgs={lot.suppliers ?? []}
            orgType="suppliers"
            onUnauthorisedClick={onUnauthorisedClick}
            authorised={authorised}
          />
        }
        titleClassName={css.propertyTitle}
      />
      <PropertyInline title="Lot value" value={value} titleClassName={css.propertyTitle} />
      <PropertyInline title="Expiry date" value={endDate} titleClassName={css.propertyTitle} />
    </div>
  );
}

function LotDetailsContent({ lots }: LotsProps) {
  const { checkSubscription: onUnauthorisedClick, authorised } = useCheckSubscription("SUPPLIERS");

  if (!lots.length) {
    return <span>No lot details available.</span>;
  }

  return (
    <div className={css.lotsContainer}>
      {lots.map((lot, index) => (
        <Lot
          lot={lot}
          onUnauthorisedClick={onUnauthorisedClick}
          authorised={authorised}
          key={index}
        />
      ))}
    </div>
  );
}

export default function LotDetails({ lots }: LotsProps) {
  return (
    <div>
      <h2 className={css.title}>Lot details</h2>
      <LotDetailsContent lots={lots} />
    </div>
  );
}
