import * as React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import Icon, { SettingOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd5";
import { CheckboxChangeEvent } from "antd5/es/checkbox";

import DragIcon from "lib/icons/Drag";
import * as tracking from "lib/tracking";
import { EventDataTypes } from "lib/tracking";
import { TableSettings } from "lib/types/models";
import DropdownMenu from "./DropdownMenu";

import css from "./EditColumnsButton.module.scss";

type EditColumnsButtonProps<TS extends TableSettings<string>> = {
  selectedColumns: TS["columns"];
  onColumnSettingChange?: (value: React.SetStateAction<TS>) => void;
  allAvailableColumns: TS["columns"];
  currentViewId?: string;
  // TODO: This component needs a proper refactor to use the new generate "ColumnSetting" type
  // instead of the manually created "TableSettings" type
  onNewColumnSettingChange?: (columns: TS["columns"]) => void;
  dataType: EventDataTypes;
};

/**
 * Edit columns button allows user to drag & drop columns, as well as
 * toggle which columns of a table are visible
 * @param param0
 * @returns
 */
function EditColumnsButton<TS extends TableSettings<string>>(
  props: EditColumnsButtonProps<TS>,
): JSX.Element {
  return (
    <DropdownMenu trigger={["hover"]} overlay={<DraggableColumnsList {...props} />}>
      <Button icon={<SettingOutlined className={css.gearIcon} />}>Edit columns</Button>
    </DropdownMenu>
  );
}

/**
 * Edit columns button allows user to drag & drop columns, as well as
 * toggle which columns of a table are visible
 * @param param0
 * @returns
 */
function DraggableColumnsList<TS extends TableSettings<string>>({
  selectedColumns,
  onColumnSettingChange,
  currentViewId,
  allAvailableColumns,
  onNewColumnSettingChange,
  dataType,
}: EditColumnsButtonProps<TS>): JSX.Element {
  const updateColumnSettings = React.useCallback(
    (columns: TS["columns"], columnName: string, editType: string) => {
      if (onColumnSettingChange) {
        onColumnSettingChange((oldTableSettings) => {
          return { ...oldTableSettings, columns };
        });
      } else if (onNewColumnSettingChange) {
        onNewColumnSettingChange(columns);
      }

      if (currentViewId) {
        tracking.logEvent(tracking.EventNames.columnEdit, {
          "Saved view id": currentViewId,
          "Data type": dataType,
          "Edit type": editType,
          "Column name": columnName,
        });
      } else {
        tracking.logEvent(tracking.EventNames.columnEdit, {
          "Edit type": editType,
          "Column name": columnName,
          "Data type": dataType,
        });
      }
    },

    [currentViewId, dataType, onColumnSettingChange, onNewColumnSettingChange],
  );

  const getInitialColumns = React.useCallback((): TS["columns"] => {
    const selected: TS["columns"] = [];
    for (const setting of selectedColumns) {
      const formatted = allAvailableColumns.find((c) => c.field === setting.field);
      if (formatted) selected.push(formatted);
    }
    const remaining = allAvailableColumns.filter(
      (a) => !selected.some((s) => s?.field === a.field),
    );
    // Onload, always show the selected ones first in the correct order, then the remaining options
    return [...selected, ...remaining];
  }, [allAvailableColumns, selectedColumns]);

  const [internalOrder, setInternalOrder] = React.useState<TS["columns"]>(() =>
    getInitialColumns(),
  );

  // This is only used on the myFeed page really - when a user changes views -
  // but I could imagine it could be used in future when we have more single page table displays
  React.useEffect(
    () => setInternalOrder(getInitialColumns()),
    [currentViewId, allAvailableColumns, getInitialColumns],
  );

  const selectedIdSet = React.useMemo(
    () => new Set(selectedColumns.map((item) => item.field)),
    [selectedColumns],
  );

  // Handling the check event for showing/hiding columns
  const onSelectChange = React.useCallback(
    (e: CheckboxChangeEvent) => {
      const { checked, value } = e.target;
      const copiedSettings = onColumnSettingChange ? Array.from(selectedColumns) : selectedColumns;
      const selectedIdx = copiedSettings.findIndex((i) => i.field == value);
      let editType;
      // When we check an item we want it to appear in the order that is in between selected items in the setting
      if (checked) {
        editType = "Column selected";
        // One thing to note here is that we forget the column width settings when an item is unselected
        // Currently we don't keep track of width any way but we will
        const checkedColumn = { field: value };
        const idxInternalList = internalOrder.findIndex((i) => i.field === value);

        copiedSettings.splice(idxInternalList, 0, checkedColumn);
      } else {
        editType = "Column deselected";
        copiedSettings.splice(selectedIdx, 1);
      }

      updateColumnSettings(copiedSettings, value, editType);
    },
    [selectedColumns, updateColumnSettings, onColumnSettingChange, internalOrder],
  );

  // Handling the drag event for the order of the columns
  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;
      if (!destination || destination.index === source.index) {
        return;
      }

      const copiedInternalOrder = Array.from(internalOrder);
      const [removed] = copiedInternalOrder.splice(source.index, 1);
      copiedInternalOrder.splice(destination.index, 0, removed);
      setInternalOrder(copiedInternalOrder);

      const newColumnSettingsOrder = [];
      for (const column of copiedInternalOrder) {
        if (selectedIdSet.has(column.field)) {
          const columnSetting = selectedColumns.find((s) => s.field === column.field);
          columnSetting && newColumnSettingsOrder.push(columnSetting);
        }
      }
      updateColumnSettings(newColumnSettingsOrder, draggableId, "Column reordered");

      tracking.logEvent(tracking.EventNames.columnEdit, {
        "Saved view id": currentViewId,
        "Data type": tracking.EventDataTypes.savedView,
        "Edit type": "Column reordered",
        "Column name": draggableId, // draggableId is in our case the name of the column
      });
    },
    [internalOrder, updateColumnSettings, currentViewId, selectedIdSet, selectedColumns],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="columnList">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <ul className={css.columnListWrapper} aria-label="column list">
              {internalOrder.map((column, index) => (
                <Draggable draggableId={column.field} key={column.field} index={index}>
                  {(provided) => (
                    <li
                      key={column.field}
                      className={css.columnListItem}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Icon
                        component={DragIcon}
                        {...provided.dragHandleProps}
                        className={css.dragIcon}
                      />
                      <Checkbox
                        disabled={column.disabled}
                        aria-label={column.title}
                        className={css.checkBox}
                        value={column.field}
                        checked={selectedIdSet.has(column.field)}
                        onChange={onSelectChange}
                      />
                      {column.title}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default EditColumnsButton;
