import * as React from "react";
import { PopoverProps } from "antd/lib/popover";
import classnames from "classnames";
import moment from "moment";

import ViewDescription from "components/actions/ViewDescription";
import RecordStage from "lib/core_components/RecordStage";
import { RecordPreview } from "lib/types/models";
import { assertCurrentUser } from "../../lib/currentUser";
import { RecordMatchInfo } from "../../lib/qued/queryRunner";
import {
  PreviewGuidMatchMap,
  PreviewRecordMatch,
  SelfServeQuery,
  Signal,
  SignalCategory,
} from "../../lib/StotlesApi";
import { isDefined } from "../../lib/utils";
import { useRecordMatchData } from "../my_feed/useRecordMatchData";

import css from "./RecordCardList.module.scss";

type RecordCardProps = {
  record: RecordPreview;
  popoverPlacement?: PopoverProps["placement"];
  previewRecordMatch: PreviewRecordMatch;
  matchInfo: RecordMatchInfo | undefined;
  disableNavigation?: boolean;
  showTenderStage?: boolean;
  signalMap: Map<string, Signal>;
};

const SIGNAL_ORDER: SignalCategory[] = [
  SignalCategory.KEYWORD,
  SignalCategory.PARTNER,
  SignalCategory.COMPETITOR,
  SignalCategory.CPV_CODE,
];

const SIGNAL_CATEGORY_CSS_CLASSES: Record<SignalCategory, string> = {
  [SignalCategory.KEYWORD]: css.keywordMatch,
  [SignalCategory.PARTNER]: css.partnerMatch,
  [SignalCategory.COMPETITOR]: css.competitorMatch,
  [SignalCategory.CPV_CODE]: css.cpvCodeMatch,
};

function RecordCard({
  record,
  popoverPlacement,
  previewRecordMatch,
  matchInfo,
  disableNavigation,
  showTenderStage,
  signalMap,
}: RecordCardProps): JSX.Element {
  const matchedSignals = previewRecordMatch.signal_ids
    .map((signalId) => signalMap.get(signalId))
    .filter(isDefined);
  return (
    <div className={css.recordCard} key={record.guid}>
      <div className={css.matchReasonContainer}>
        {matchedSignals.length > 0 && (
          <ul className={css.matchList}>
            {SIGNAL_ORDER.map((signalCategory) => {
              return matchedSignals
                .filter((s) => s.category === signalCategory)
                .map((signal) => (
                  <li
                    className={classnames(
                      css.matchItem,
                      SIGNAL_CATEGORY_CSS_CLASSES[signal.category as SignalCategory],
                    )}
                    key={signal.id}
                  >
                    {signal.name}
                  </li>
                ));
            })}
          </ul>
        )}
        <p className={css.publishDate}>Published {moment(record.publish_date).fromNow()}</p>
      </div>
      <div className={css.infoContainer}>
        {showTenderStage && <RecordStage stage={record.stage} className={css.stageLabel} />}
        <div className={css.title}>{record.name}</div>
        <p className={css.buyerName}>{record.buyer?.name}</p>
      </div>
      <div className={css.actionContainer}>
        <ViewDescription
          record={record}
          queryMatchInfo={matchInfo}
          disableNavigation={disableNavigation}
          triggerLabel="Details"
          popoverPlacement={popoverPlacement}
        />
      </div>
    </div>
  );
}

type Props = {
  records: RecordPreview[];
  guidMatchMap?: PreviewGuidMatchMap;
  popoverPlacement?: PopoverProps["placement"];
  disableRecordNavigation?: boolean;
  showTenderStage?: boolean;
  signalMap?: Map<string, Signal>;
  // Required to fetch highlighting information for displayed records
  feedCriteria: SelfServeQuery;
  companyId?: number;
};

const VIRTUAL_PAGE_SIZE = 40;
function RecordCardList({
  records,
  popoverPlacement,
  guidMatchMap,
  disableRecordNavigation,
  showTenderStage,
  signalMap,
  feedCriteria,
  companyId,
}: Props): JSX.Element {
  const [displayedRecords, setDisplayedRecords] = React.useState(() =>
    records.slice(0, VIRTUAL_PAGE_SIZE),
  );
  const [scrollContainer, setScrollContainer] = React.useState<HTMLElement | null>(null);

  const displayedRecordGuids = React.useMemo(() => {
    return displayedRecords.map((r) => r.guid);
  }, [displayedRecords]);

  const recordMatchInfoMap = useRecordMatchData(
    displayedRecordGuids,
    companyId || assertCurrentUser().company.id,
    feedCriteria,
  );

  React.useEffect(() => {
    setDisplayedRecords(records.slice(0, VIRTUAL_PAGE_SIZE));
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }, [records, scrollContainer]);

  const updateScrollContainer = React.useCallback((elem: HTMLElement | null) => {
    while (elem && getComputedStyle(elem).overflowY !== "auto") {
      elem = elem.parentElement;
    }
    setScrollContainer(elem);
  }, []);

  const loadMoreResults = React.useCallback(() => {
    setDisplayedRecords((displayedRecords) => {
      if (displayedRecords.length === records.length) return displayedRecords;
      const newCount = displayedRecords.length + VIRTUAL_PAGE_SIZE;
      return records.slice(0, newCount > records.length ? records.length : newCount);
    });
  }, [records]);

  React.useEffect(() => {
    if (scrollContainer) {
      // The parent container has fixed height, let's say 500px (calculated as something like 100vh - 50px)
      // Now let's say initial page rendered a list of 800px.
      // This means that scrollTop can be between 0-300px.(see maxScroll below).
      // When the user scrolls, we compare current scrollTop with maxScroll
      // and if we have less than 1 extra page rendered (target.clientHeight)
      // we render more records.
      const handleScroll = (event: Event) => {
        const { target } = event;
        if (!target || !(target instanceof HTMLElement)) return;
        const maxScroll = target.scrollHeight - target.clientHeight;
        const currentScroll = target.scrollTop;
        if (currentScroll >= maxScroll - target.clientHeight) {
          console.log("fetching more results");
          loadMoreResults();
        }
      };

      scrollContainer.addEventListener("scroll", handleScroll, { passive: true });
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollContainer, loadMoreResults]);

  return (
    <ul className={css.recordList} ref={updateScrollContainer}>
      {guidMatchMap &&
        signalMap &&
        displayedRecords.map(
          (record) =>
            guidMatchMap[record.guid] && (
              <li key={record.guid}>
                <RecordCard
                  record={record}
                  popoverPlacement={popoverPlacement}
                  previewRecordMatch={guidMatchMap[record.guid]}
                  disableNavigation={disableRecordNavigation}
                  showTenderStage={showTenderStage}
                  signalMap={signalMap}
                  matchInfo={recordMatchInfoMap && recordMatchInfoMap[record.guid]}
                />
              </li>
            ),
        )}
    </ul>
  );
}

export default RecordCardList;
