import React, { useState } from "react";
import { Select } from "antd5";

import { useDebouncedValue } from "lib/debounce";
import {
  SignalsToMigrate,
  useSignalsToMigrate,
} from "lib/hooks/api/admin/signal_migration/useSignalsToMigrate";
import { deduplicateByKey } from "lib/utils";

import css from "./SignalsToMigrateSelection.module.scss";

type Props = {
  selectedSignals: SignalsToMigrate[];
  feedSettingIds?: string[];
  onSignalsChange: (selectedOrgs: SignalsToMigrate[]) => void;
};

function SignalsToMigrateSelection({
  selectedSignals,
  onSignalsChange,
  feedSettingIds = [],
}: Props): JSX.Element {
  const [textSearch, setTextSearch] = useState<string>("");
  const [debouncedText] = useDebouncedValue(textSearch, 300);

  const { isLoading, data } = useSignalsToMigrate({
    feedSettingIds,
    textSearch: debouncedText,
  });

  const signalResults = data?.signalsToMigrate || [];

  return (
    <div>
      <h3>Choose supplier signals to migrate</h3>
      <Select
        className={css.searchInput}
        loading={isLoading}
        showSearch
        size="large"
        onSearch={setTextSearch}
        mode="multiple"
        value={selectedSignals.map((signal) => signal.name)}
        options={signalResults.map((signal) => ({
          key: signal.name,
          value: signal.name,
          label: `${signal.name} (${signal.ids.length})`,
        }))}
        optionFilterProp="value"
        placeholder="Search by signal name"
        onChange={(selectedNames) => {
          const newSelectedSignals = signalResults.filter((signal) =>
            selectedNames.includes(signal.name),
          );

          const retainedSignals = selectedSignals.filter((signal) =>
            selectedNames.includes(signal.name),
          );
          const allSignals = Array.from(
            deduplicateByKey(
              [...newSelectedSignals, ...retainedSignals],
              (signal: SignalsToMigrate) => {
                return signal.name;
              },
            ),
          );

          onSignalsChange(allSignals);
        }}
      />
    </div>
  );
}

export default SignalsToMigrateSelection;
