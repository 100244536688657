import * as React from "react";
import { Button } from "antd5";

import { ContactFilters } from "components/contacts/contactUtils";
import PaywallPopover from "components/paywall/PaywallPopover";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { TrackingProvider } from "lib/tracking";
import RequestContactsModal from "./RequestContactsModal";

import css from "./RequestContacts.module.scss";

import blueGeometric from "../../../assets/images/icons/stotles_geometric_square.svg";

type RequestContactsButtonProps = {
  contextSource: string;
  contactFilters?: ContactFilters;
  title?: string;
  notice?: {
    guid: string;
    name: string;
    buyer?: { guid: string; name: string };
  };
};

function RequestContactsButton({
  contactFilters,
  title,
  contextSource,
  notice,
}: RequestContactsButtonProps): JSX.Element {
  const [requestContactsModalIsOpen, setRequestContactsModalIsOpen] = React.useState(false);

  if (!notice?.guid && !contactFilters) {
    throw new Error(
      "Either 'notice' or 'contactFilters' must be provided to RequestContactsButton.",
    );
  }

  const { authorised: hasContacts } = useCheckSubscription("CONTACTS", {
    "Context source": contextSource,
  });

  return (
    <TrackingProvider
      data={{
        "Context source": contextSource,
      }}
    >
      <RequestContactsModal
        isOpen={requestContactsModalIsOpen}
        onClose={() => setRequestContactsModalIsOpen(false)}
        contactFilters={contactFilters}
        notice={notice}
      />
      <PaywallPopover featureType="CONTACTS" contextSource={contextSource}>
        <Button
          className={css.requestContactButton}
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            if (hasContacts) {
              setRequestContactsModalIsOpen(true);
            }
          }}
        >
          {title ?? "Request Contacts"}
        </Button>
      </PaywallPopover>
    </TrackingProvider>
  );
}

type RequestContactsRowProps = {
  contactFilters?: ContactFilters;
  title?: string;
  notice?: {
    guid: string;
    name: string;
    buyer?: { guid: string; name: string };
  };
};

export function RequestContactsRow({
  contactFilters,
  title,
  notice,
}: RequestContactsRowProps): JSX.Element {
  return (
    <div className={css.requestContactsRow}>
      <img className={css.graphic} src={blueGeometric} />
      <div>
        <h4 className={css.headContent}>Not found any relevant contacts?</h4>
        <span className={css.textContent}>
          Just send a request below and a member of the team can look into a couple of other sources
          for you.
        </span>
      </div>
      <RequestContactsButton
        contactFilters={contactFilters}
        title={title}
        contextSource="No relevant contacts banner"
        notice={notice}
      />
    </div>
  );
}

export default RequestContactsButton;
