import * as React from "react";
import { CaretDownOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { Divider } from "antd5";
import Select, { OptionProps } from "antd5/es/select";

import TextButton from "components/actions/TextButton";
import { ContactUsWidget } from "components/contact_us_widget/ContactUsWidget";
import SendInviteModal from "components/modals/SendInviteModal";
import { assertCurrentUser } from "lib/currentUser";
import { useUpdateLeadAssignee } from "lib/hooks/api/records/useUpdateLeadAssignee";
import { useUsers } from "lib/hooks/api/useUsers";
import { useDialogManager } from "lib/providers/DialogManager";
import { useSubscription } from "lib/providers/Subscription";
import UserInitials, { UnassignedIcon } from "../comments/UserInitials";

import css from "./AssigneeSelect.module.scss";

const { Option } = Select;

type Props = {
  iconClassName?: string;
  recordGuid: string;
  procurementStageId: string;
  procurementStageStage: string;
  signals: { name: string; category: string }[];
  score?: number | null;
  assignee?: {
    guid: string;
    firstName: string;
    lastName: string;
  };
};

const UNASSIGNED = "unassigned";

function AssigneeSelect({
  procurementStageId,
  procurementStageStage,
  recordGuid,
  iconClassName,
  signals,
  score,
  assignee,
}: Props): JSX.Element | null {
  const dialogManager = useDialogManager();
  const { companyPaymentType } = useSubscription();
  const currentUser = assertCurrentUser();
  const { mutate: updateLeadAssignee, isLoading: isUpdatingAssignee } = useUpdateLeadAssignee();

  // Because it is react query, this will only run once as responses are cached
  const { data: allUsers, isLoading: loadingUsers } = useUsers();

  const handleSelect = (userGuid: string | null) => {
    const match = allUsers?.find((u) => u.guid === userGuid);

    updateLeadAssignee({
      companyGuid: currentUser.company.guid,
      user: match,
      recordGuid: recordGuid,
      procurementStageId: procurementStageId,
      procurementStageStage: procurementStageStage,
      signals: signals,
      score: score,
    });
  };

  const options: React.ReactElement<OptionProps>[] = React.useMemo(() => {
    let options: React.ReactElement<OptionProps>[] = [];

    const unassignedOption = (
      <Option
        key={UNASSIGNED}
        value={UNASSIGNED}
        label={<UnassignedIcon className={css.userIcon} />}
      >
        <span className={css.option}>
          <UnassignedIcon showTooltip={false} className={css.userIcon} />
          <span>Unassigned</span>
          {!assignee && <CheckOutlined className={css.icon} />}
        </span>
      </Option>
    );

    // This is for when the page loads and we haven't fetched the user options yet
    // Then we fabricate the selected option
    if (!allUsers && assignee) {
      options = [
        <Option
          key={assignee.guid}
          value={assignee.guid}
          label={
            <UserInitials
              firstName={assignee.firstName}
              lastName={assignee.lastName}
              className={css.userIcon}
            />
          }
        >
          <span className={css.option}>
            <UserInitials
              firstName={assignee.firstName}
              lastName={assignee.lastName}
              showTooltip={false}
              className={css.userIcon}
            />
            <span>{`${assignee.firstName} ${assignee.lastName}`}</span>
            <CheckOutlined className={css.icon} />
          </span>
        </Option>,
      ];
    } else if (allUsers) {
      options = allUsers
        // we filter out the current user because their option should be on top
        // we also filter out inactive users, except for the current assignee (in the case that they are no longer active)
        .filter(
          (u) =>
            (u.guid !== currentUser.guid && u.active) || (!u.active && u.guid === assignee?.guid),
        )
        .map((u) => (
          <Option
            key={u.guid}
            value={u.guid}
            disabled={!u.active}
            label={
              <UserInitials
                firstName={u.firstName}
                lastName={u.lastName}
                className={css.userIcon}
              />
            }
          >
            <span className={css.option}>
              <UserInitials
                firstName={u.firstName}
                lastName={u.lastName}
                showTooltip={false}
                className={css.userIcon}
              />
              <span>
                {`${u.firstName} ${u.lastName}`} {!u.active && "- deactivated"}
              </span>
              {assignee?.guid === u.guid && <CheckOutlined className={css.icon} />}
            </span>
          </Option>
        ));
    }
    const currentUserOption = (
      <Option
        key={currentUser.guid}
        value={currentUser.guid}
        label={
          <UserInitials
            firstName={currentUser.first_name}
            lastName={currentUser.last_name}
            className={css.userIcon}
          />
        }
      >
        <span className={css.option}>
          <UserInitials
            firstName={currentUser.first_name}
            lastName={currentUser.last_name}
            className={css.userIcon}
            showTooltip={false}
          />
          <span>{`${currentUser.first_name} ${currentUser.last_name}  (assign to me)`}</span>
          {assignee?.guid === currentUser.guid && <CheckOutlined className={css.icon} />}
        </span>
      </Option>
    );

    return [unassignedOption, currentUserOption, ...options];
  }, [allUsers, assignee, currentUser]);

  return (
    <Select
      size="large"
      popupMatchSelectWidth={false}
      bordered={false}
      loading={loadingUsers || isUpdatingAssignee}
      onMouseEnter={(e) => e.stopPropagation()}
      value={assignee?.guid || UNASSIGNED}
      onSelect={handleSelect}
      popupClassName={css.dropdownMenu}
      suffixIcon={<CaretDownOutlined style={{ pointerEvents: "none" }} className={iconClassName} />}
      optionLabelProp="label"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider className={css.divider} />
          <TextButton
            icon={<PlusOutlined />}
            className={css.addAMateBtn}
            onMouseDown={(e) => e.preventDefault()}
            onClick={(e) => {
              e.stopPropagation();

              if (companyPaymentType === "Freemium") {
                dialogManager.openDialog(SendInviteModal, {
                  linkToTeamPage: true,
                });
              } else {
                dialogManager.openDialog(ContactUsWidget, {
                  triggeredFrom: "Assignee: invite a team member",
                });
              }
            }}
          >
            Invite team members
          </TextButton>
        </>
      )}
    >
      {options}
    </Select>
  );
}

export default AssigneeSelect;
