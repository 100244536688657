import React from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { App, Button, Menu, MenuProps, Popover } from "antd5";
import classNames from "classnames";

import BookmarkIcon from "components/actions/Bookmark";
import PaywallPopover from "components/paywall/PaywallPopover";
import { UpdateSignalSettings } from "lib/generated/app-service-gql/graphql";
import { useUpdateSignalSettings } from "lib/hooks/api/signals/useUpdateSignalSettings";
import { ExternalLink } from "lib/icons/ExternalLink";
import PaywallStar from "lib/icons/PaywallStar";
import Signal from "lib/icons/Signal";
import { useDialogManager } from "lib/providers/DialogManager";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { white } from "lib/themes/colors";
import { isArrayInArray, pluralise } from "lib/utils";
import { SupplierContextSource } from "./SaveSupplier";
import SaveSupplierModal, {
  HIDE_SAVE_SUPPLIER_MODAL,
  SaveSupplierModalProps,
} from "./SaveSupplierModal";

import css from "./SaveSupplierPopover.module.scss";

type Props = {
  // Having it as an array of guids because I imagine bulk saving will be a
  // thing in the future - much like buyers
  supplierGuids: string[];
  partnerGuids: string[];
  competitorGuids: string[];
  isPopoverOpen: boolean;
  contextSource: SupplierContextSource;
  children: React.ReactNode;
  onOpenChange: (open: boolean) => void;
  onSaveSupplierOp: (supplierGuids: string[], category: "Competitor" | "Partner") => void;
};

function SaveSupplierPopover({
  supplierGuids,
  partnerGuids,
  competitorGuids,
  children,
  contextSource,
  isPopoverOpen,
  onOpenChange,
  onSaveSupplierOp,
}: Props) {
  const isPartner = isArrayInArray(supplierGuids, partnerGuids);
  const isCompetitor = isArrayInArray(supplierGuids, competitorGuids);

  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Save Supplier",
  });

  const { message } = App.useApp();

  const dialogManager = useDialogManager();

  const { mutate: updateSignalSettings, isLoading } = useUpdateSignalSettings({
    onSuccess: (_data, variables, _context) => {
      const { competitorIds, partnerIds } = variables.input;
      if (competitorIds !== undefined && competitorIds !== null) {
        onSaveSupplierOp(competitorIds, "Competitor");
      }
      if (partnerIds !== undefined && partnerIds !== null) {
        onSaveSupplierOp(partnerIds, "Partner");
      }

      void message.success({
        content: (
          <>
            Signal settings updated
            <Button
              type="primary"
              className={css.viewSigSettings}
              icon={<ExternalLink fill={white} className={css.external} />}
              target="_blank"
              href="/account-management/feed-settings"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              View signal settings
            </Button>
          </>
        ),
        duration: 3,
        icon: <CheckCircleTwoTone />,
      });
    },

    onError: (_error, variables) => {
      const { competitorIds } = variables.input;
      void message.error(
        `Failed to ${
          supplierGuids.length > 1 ? "bulk save suppliers" : "save supplier"
        } as ${pluralise(
          supplierGuids.length,
          competitorIds !== undefined ? "Competitor" : "Partner",
        )}, 
        please contact an admin if this issue persists.`,
      );
    },
  });

  /**
   * Depending on user's history, either opens an informative modal, or just saves/unsaves
   * the supplier(s) immediately
   * @param type
   * @param savedGuids
   */
  const onSaveSupplier = (
    type: "competitor" | "partner",
    savedSupplierGuids: string[],
    isSavedSupplier: boolean,
  ) => {
    if (!hasSuppliers) {
      return;
    }

    let newSavedSupplierGuids: string[] = [];
    let action: "Save" | "Remove";

    if (isSavedSupplier) {
      newSavedSupplierGuids = savedSupplierGuids.filter((item) => !supplierGuids.includes(item));
      action = "Remove";
    } else {
      newSavedSupplierGuids = savedSupplierGuids.concat(supplierGuids);
      action = "Save";
    }

    const input: UpdateSignalSettings = {
      competitorIds: type === "competitor" ? newSavedSupplierGuids : undefined,
      partnerIds: type === "partner" ? newSavedSupplierGuids : undefined,
    };

    if (localStorage.getItem(HIDE_SAVE_SUPPLIER_MODAL) === "true") {
      updateSignalSettings({ input });
    } else {
      dialogManager.openDialog(SaveSupplierModal, {
        type,
        onConfirm: () => updateSignalSettings({ input }),
        isBulkSave: supplierGuids.length > 1,
        action,
      } as Omit<SaveSupplierModalProps, "isOpen" | "onClose">);
    }
  };

  const options: MenuProps["items"] = [
    {
      key: "saveSupplierOptions",
      type: "group",
      label: <h4 className={css.listHeader}>Save as Signal</h4>,
      loading: isLoading,
      children: [
        {
          key: "competitors",
          label: (
            <PaywallPopover featureType="COMPETITORS" contextSource={contextSource}>
              <span className={css.supplierCategory}>
                My competitors
                {hasSuppliers ? (
                  <BookmarkIcon
                    colour="blue"
                    filled={isCompetitor}
                    className={classNames({
                      [css.showOnHover]: !isCompetitor,
                    })}
                  />
                ) : (
                  <PaywallStar />
                )}
              </span>
            </PaywallPopover>
          ),
          icon: <Signal className={css.signalIcon} size={16} label="signalIcon" />,
          onClick: () => onSaveSupplier("competitor", competitorGuids, isCompetitor),
        },
        {
          key: "partners",
          label: (
            <PaywallPopover featureType="PARTNERS" contextSource={contextSource}>
              <span className={css.supplierCategory}>
                My partners
                {hasSuppliers ? (
                  <BookmarkIcon
                    colour="blue"
                    filled={isPartner}
                    className={classNames({
                      [css.showOnHover]: !isPartner,
                    })}
                  />
                ) : (
                  <PaywallStar />
                )}
              </span>
            </PaywallPopover>
          ),
          icon: <Signal className={css.signalIcon} size={16} label="signalIcon" />,
          onClick: () => onSaveSupplier("partner", partnerGuids, isPartner),
        },
      ],
    },
  ];

  return (
    <Popover
      // Click to open popover, then when open it closes automatically after 0.3 secs
      trigger={isPopoverOpen ? ["hover"] : ["click"]}
      mouseLeaveDelay={0.3}
      placement="bottomLeft"
      arrow={false}
      onOpenChange={(open) => onOpenChange(open)}
      overlayInnerStyle={{ padding: 0 }}
      content={
        <div className={css.popoverContainer}>
          <Menu
            mode="inline"
            className={css.menuContent}
            items={options}
            inlineIndent={12}
            selectable={false}
            multiple
          />
        </div>
      }
    >
      {children}
    </Popover>
  );
}

export default SaveSupplierPopover;
