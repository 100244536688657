import React from "react";
import { CaretDownFilled } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";

import BookmarkIcon from "components/actions/Bookmark";
import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { useDialogManager } from "lib/providers/DialogManager";
import { SuppliersModal, SuppliersModalProps } from "./SuppliersModal";

// eslint-disable-next-line css-modules/no-unused-class
import css from "./SaveSupplier.module.scss";

type Props = {
  supplierGuids: string[];
};

function BulkSaveNoticeSupplier({ supplierGuids }: Props) {
  const dialogManager = useDialogManager();

  // Just need loading state for the button. Should wait until the saved suppliers API is ready
  const { isLoading } = useSavedSuppliers();

  const openSaveSupplierModal = () => {
    dialogManager.openDialog(SuppliersModal, {
      guids: supplierGuids,
    } as Omit<SuppliersModalProps, "isOpen" | "onClose">);
  };

  return (
    <div>
      <Tooltip title={`Save ${supplierGuids.length} suppliers as a competitor or partner`}>
        <Button
          type="default"
          className={css.bookmarkButton}
          loading={isLoading}
          onClick={openSaveSupplierModal}
        >
          <BookmarkIcon colour="blue" />
          <CaretDownFilled className={css.caretDown} />
        </Button>
      </Tooltip>
    </div>
  );
}

export default BulkSaveNoticeSupplier;
