import * as React from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import { App, Button, Typography } from "antd5";
import classNames from "classnames";
import moment from "moment";

import { ContactUsWidget } from "components/contact_us_widget/ContactUsWidget";
import { FeatureType } from "components/paywall/paywallUtils";
import { assertCurrentUser } from "lib/currentUser";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { usePqlActioned } from "lib/hooks/api/users/usePqlActioned";
import { useDialogManager } from "lib/providers/DialogManager";
import { useSubscription } from "lib/providers/Subscription";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { LearnStotlesProWidget } from "../contact_us_widget/LearnStotlesProWidget";
import { ActionableBanner } from "./ActionableBanner";
import PaywallCTAModal from "./PaywallCTAModal";
import { DataTypeGrid } from "./PlanTypes";
import SettingsContentContainer from "./SettingsContentContainer";
import { SubscriptionPlans } from "./SubscriptionPlans";
import { SubscriptionPlansDetails } from "./SubscriptionPlansDetails";

import css from "./Subscription.module.scss";

type Props = {
  className?: string;
  triggerBookADemoForm?: (value: boolean) => void;
  trackingData?: EventData;
};

function Subscription({ className, triggerBookADemoForm, trackingData }: Props): JSX.Element {
  const [paywallCTAModalOpen, setPaywallCTAModalOpen] = React.useState(false);
  const currentPlanisFreemium = assertCurrentUser().subscription === "freemium";
  const { mutate: pqlActioned } = usePqlActioned(undefined, trackingData);
  const dialogManager = useDialogManager();
  const subscription = useSubscription();
  const { logEvent } = useTracking();
  const { message } = App.useApp();

  const activeDataTypes = subscription.activatedDataTypes;
  const deactivatedDataTypes = subscription.deactivatedDataTypes;
  const trialEligble = subscription.eligibleForTrial();
  const trialEnd = subscription.trialEndDate;

  const handleProClick = () => {
    logEvent(EventNames.clickedRequestPro, {
      "Context source": "main page",
    });
    pqlActioned({
      actionType: "Upgrade",
      pageUrl: window.location.href,
      dataType: trackingData?.["Data type"] as FeatureType,
    });
    void dialogManager.openDialog(LearnStotlesProWidget, {
      triggeredFrom: "Subscription page",
    });
  };

  const handleContactYourCSMButton = () => {
    pqlActioned({
      actionType: "Speak to your CSM",
      pageUrl: window.location.href,
      dataType: trackingData?.["Data type"] as FeatureType,
    });

    void message.success("Your Customer Success Manager has been contacted.");
  };

  if (FeatureToggles.isEnabled(Feature.NEW_SUBSCRIPTION_PAGE)) {
    return (
      <div className={classNames(css.container, className)}>
        <div className={css.heading}>
          <h1>
            A plan that's right for your team<span className={css.dot}>.</span>
          </h1>
          <p>Fit for purpose plans to drive sales productivity in the public sector market.</p>
          {currentPlanisFreemium ? (
            <Button
              type="primary"
              className={css.bookADemoButton}
              onClick={() => {
                pqlActioned({
                  actionType: "Book a demo in-app",
                  pageUrl: window.location.href,
                  dataType: trackingData?.["Data type"] as FeatureType,
                });

                // In settings we want to open a new modal (PaywallCTAModal) to show the book a demo form
                // Anywhere else we want to display the form and plan details in the same modal
                triggerBookADemoForm ? triggerBookADemoForm(true) : setPaywallCTAModalOpen(true);
              }}
            >
              Book a demo
            </Button>
          ) : (
            <Button
              type="primary"
              className={css.speakToYourCSMButton}
              onClick={handleContactYourCSMButton}
            >
              Speak to your Customer Success Manager
            </Button>
          )}
        </div>

        <SubscriptionPlans />
        <SubscriptionPlansDetails />
        {currentPlanisFreemium && (
          <ActionableBanner
            setBookADemoModalOpen={
              triggerBookADemoForm ? triggerBookADemoForm : setPaywallCTAModalOpen
            }
          />
        )}
        <PaywallCTAModal
          isOpen={paywallCTAModalOpen}
          onClose={() => setPaywallCTAModalOpen(false)}
          showBookADemoForm={true}
          featureType={trackingData?.["Data type"] as FeatureType}
        />
      </div>
    );
  }

  return (
    <SettingsContentContainer title="Subscription">
      <div className={css.subscription}>
        <div>
          <Typography.Title level={2}>Current access</Typography.Title>
          {!!trialEnd && trialEnd > new Date() && (
            <TrialMessage
              trialEndDate={trialEnd}
              onGetInTouchClicked={() =>
                dialogManager.openDialog(ContactUsWidget, {
                  triggeredFrom: "Subscription page contact us widget",
                })
              }
            />
          )}
          <DataTypeGrid dataTypes={activeDataTypes} />
        </div>
        {!!deactivatedDataTypes.length && (
          <div>
            <Typography.Title level={2}>More options</Typography.Title>
            <DataTypeGrid dataTypes={deactivatedDataTypes} />
          </div>
        )}
        <div className={css.footer}>
          {!trialEligble && (
            <p className={css.planChange}>
              Get in touch to learn more or make changes to your plan
            </p>
          )}
          <div className={css.actions}>
            <Button
              type="default"
              size="large"
              onClick={() =>
                dialogManager.openDialog(ContactUsWidget, {
                  triggeredFrom: "Subscription page contact us widget",
                })
              }
            >
              Get in touch
            </Button>
            {trialEligble && (
              <Button type="primary" onClick={handleProClick} size="large">
                See Stotles Pro in action
              </Button>
            )}
          </div>
        </div>
      </div>
    </SettingsContentContainer>
  );
}

interface TrialMessageProps {
  trialEndDate: Date;
  onGetInTouchClicked: () => void;
}

function TrialMessage({ trialEndDate, onGetInTouchClicked }: TrialMessageProps) {
  const now = moment();
  const daysRemaining = moment(trialEndDate).endOf("day").diff(now, "day");
  return (
    <div className={css.trialMessage}>
      <InfoCircleFilled />
      <p>
        <b>
          Your trial will end in {daysRemaining} days and all PRO data will be removed from your
          account.
        </b>
        <br />
        To keep using Stotles Pro and access this data,{" "}
        <b>
          <a onClick={onGetInTouchClicked}>get in touch</a>
        </b>
        .
      </p>
    </div>
  );
}

export default Subscription;
