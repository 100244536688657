import React from "react";
import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { Spin, Tag, Tooltip } from "antd5";
import { capitalize } from "lodash";

import { EventDataTypes, EventNames, useTracking } from "lib/tracking";

import css from "./QuickFilterBar.module.scss";

// The maxium number of search engine results that can be displayed
const MAX_SEARCH_ENGINE_RESULTS = 1_000_000;

type Props<T> = {
  isLoading: boolean;
  totalResults: number;
  appliedFilters: string[];
  sortField: string;
  onClear: (key: keyof T) => void;
  filterKeyToLabel: Record<keyof T, string>;
  dataType: EventDataTypes;
  hiddenFields?: (keyof T)[];
  onFilterClick: (key: keyof T) => void;
};

function convertSnakeCaseFieldToSentenceCase(field?: string) {
  if (!field) {
    return "";
  }
  const words = field.split("_").join(" ");
  return capitalize(words);
}

export function QuickFilterBar<T>(props: Props<T>) {
  const { totalResults, isLoading, sortField, appliedFilters, hiddenFields } = props;

  const totalNumberDisplay = `${totalResults >= MAX_SEARCH_ENGINE_RESULTS ? "1,000,000+" : totalResults}`;

  const filters = appliedFilters.filter((key) => !hiddenFields?.includes(key as keyof T));
  return (
    <div className={css.container} aria-label="quick-filter-bar">
      <div className={css.filterBarItem} aria-label="total-results">
        {isLoading ? <Spin size="small" /> : totalNumberDisplay}
        <div className={css.filterBarText}>Results</div>
      </div>
      <div className={css.filterBarText}>•</div>
      <div className={css.filterBarItem} aria-label="sorted-by">
        <div className={css.filterBarText}>Sorted by </div>
        {convertSnakeCaseFieldToSentenceCase(sortField)}
      </div>
      {filters.length > 0 && (
        <>
          <div className={css.filterBarText}>•</div>
          <FilterChipsContainer<T> {...props} appliedFilters={filters} />
        </>
      )}
    </div>
  );
}
const MAX_NO_OF_FILTERS_TO_DISPLAY = 4;

type FilterChipsContainerProps<T> = Pick<
  Props<T>,
  "onClear" | "filterKeyToLabel" | "appliedFilters" | "dataType" | "onFilterClick"
>;

function FilterChipsContainer<T>({
  appliedFilters,
  onClear,
  filterKeyToLabel,
  dataType,
  onFilterClick,
}: FilterChipsContainerProps<T>) {
  const tracking = useTracking();

  const clearFilter = (key: keyof T) => {
    tracking.logEvent(EventNames.filterActioned, {
      "Context source": "Filter chip",
      "Action type": "Filter cleared",
      "Filter name": filterKeyToLabel[key],
      "Data type": dataType,
    });
    onClear(key);
  };

  return (
    <div aria-label="filter-chips" className={css.filterBarItem}>
      <div className={css.filterBarText}>Filtered by </div>
      <div className={css.filterChipsContainer}>
        {appliedFilters.slice(0, MAX_NO_OF_FILTERS_TO_DISPLAY).map((key) => {
          const filterKey = key as keyof T;
          return (
            <Tag
              className={css.filterChip}
              key={filterKey.toString()}
              onClose={() => clearFilter(filterKey)}
              onClick={() => onFilterClick(filterKey)}
              closeIcon={
                <span className={css.closeIconWrapper}>
                  <CloseCircleOutlined className={`${css.closeIcon} ${css.closeIconDefault}`} />
                  <CloseCircleFilled className={`${css.closeIcon} ${css.closeIconHover}`} />
                </span>
              }
              aria-label={`filter-chip-${filterKey.toString()}`}
            >
              <span className={css.filterChipLabel}>{filterKeyToLabel[filterKey]}</span>
            </Tag>
          );
        })}
      </div>
      {appliedFilters.length && appliedFilters.length > MAX_NO_OF_FILTERS_TO_DISPLAY && (
        <CollapsedFilters<T> appliedFilters={appliedFilters} filterKeyToLabel={filterKeyToLabel} />
      )}
    </div>
  );
}

type CollapsedFiltersProps<T> = Pick<Props<T>, "appliedFilters" | "filterKeyToLabel">;
function CollapsedFilters<T>({ appliedFilters, filterKeyToLabel }: CollapsedFiltersProps<T>) {
  const remainingFilters = appliedFilters
    .slice(MAX_NO_OF_FILTERS_TO_DISPLAY)
    .map((filterKey) => <li>{filterKeyToLabel[filterKey as keyof T]}</li>);
  return (
    <Tooltip title={remainingFilters}>
      <span>{`+ ${appliedFilters.length - MAX_NO_OF_FILTERS_TO_DISPLAY} More`}</span>
    </Tooltip>
  );
}
