import React, { useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton, Tag } from "antd5";

import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { useDebouncedValue } from "lib/debounce";
import { useSupplierSearch } from "lib/hooks/api/suppliers/useSupplierSearch";
import { Select, SelectProps } from "./Inputs";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  idType?: "guid" | "id";
};

export function SupplierSelect<T extends FieldValues>({ idType = "id", ...props }: FieldProps<T>) {
  const { field } = useController(props);

  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);

  const showInitialSuppliers = !!field.value && !debouncedText && field.value.length > 0;
  const { data: initialSuppliers, isLoading: isLoadingInitial } = useSupplierSearch(
    {
      guid: idType === "guid" ? field.value : undefined,
      id: idType === "id" ? field.value : undefined,
    },
    {
      enabled: showInitialSuppliers,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const { data, isLoading } = useSupplierSearch(
    {
      sort: "relevance",
      text: debouncedText,
      limit: 20,
      country: ["UK", "IE"],
    },
    { enabled: !showInitialSuppliers && debouncedText.length > 1 },
  );

  let options = data?.results
    ? data.results.map((supplier) => ({
        label: supplier.name,
        value: idType === "id" ? supplier.id : supplier.guid,
      }))
    : [];

  if (!debouncedText && initialSuppliers?.results && options.length === 0) {
    options = initialSuppliers.results.map((supplier) => ({
      label: supplier.name,
      value: idType === "id" ? supplier.id : supplier.guid,
    }));
  }

  const loading =
    (isLoading && !showInitialSuppliers && !data) ||
    (isLoadingInitial && showInitialSuppliers && !initialSuppliers);

  return (
    <RedactedWrapper
      redactContent={
        <Select
          {...props}
          selectAll={!!debouncedText}
          loading={loading && !!debouncedText}
          options={options}
          optionFilterProp="label"
          onSearch={(val) => setSearchText(val)}
          tagRender={(props) => (
            <Tag {...props} style={{ marginRight: 3 }}>
              {loading && props.label?.toString() === props.value ? (
                <LoadingOutlined />
              ) : (
                props.label
              )}
            </Tag>
          )}
          showSearch
          filterOption={false}
          disabled
        />
      }
      requiredDataType="SUPPLIERS"
      contextSource="Supplier filter"
    >
      <Select
        {...props}
        selectAll={!!debouncedText}
        loading={loading && !!debouncedText}
        options={options}
        tagRender={(props) => (
          <Tag {...props} style={{ marginRight: 3 }}>
            {/* When value = label, they're both the ID. If we use only loading...
             then when we refetch results, already correct tags become loading tags */}
            {loading && props.label?.toString() === props.value ? <LoadingOutlined /> : props.label}
          </Tag>
        )}
        dropdownRender={(menu) =>
          loading && !!debouncedText ? (
            <Skeleton style={{ padding: "8px", paddingBottom: "0px" }} active />
          ) : (
            menu
          )
        }
        optionFilterProp="label"
        onSearch={(val) => setSearchText(val)}
        showSearch
        filterOption={false}
      />
    </RedactedWrapper>
  );
}
