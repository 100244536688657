import * as React from "react";
import { Select } from "antd5";

import css from "./AliasSelection.module.scss";

type Props = {
  selectedAliases: string[];
  onAliasChange: (selectedAliases: string[]) => void;
};

function AliasSelection({ selectedAliases, onAliasChange }: Props): JSX.Element {
  return (
    <div>
      <h3>Manually type in aliases for the selected organisations</h3>
      <Select
        allowClear
        size="large"
        placeholder="Press Enter to separate aliases."
        mode={"tags"}
        className={css.searchInput}
        value={selectedAliases}
        // Just making sure there's no duplicates possible
        onChange={(alias) => onAliasChange(Array.from(new Set(alias)))}
      />
    </div>
  );
}

export default AliasSelection;
