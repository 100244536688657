import * as React from "react";
import { Tooltip } from "antd"; // upgrade and verify, .mention colour should reference theme
import classNames from "classnames";

import { TextLink } from "components/actions/Links";
import { getMentionDetails } from "lib/comment_utils";
import { ICompanyDataProvider } from "lib/data_providers/CompanyDataProvider";
import { useStotlesData } from "lib/providers/StotlesData";
import { TextMatch } from "lib/qued/queryRunner";

import css from "./TextMatchHighlighter.module.scss";

export enum MatchType {
  Link = "link",
  Keyword = "keyword",
  Mention = "mention",
}

export type TextHighlighterMatch = TextMatch & { matchType?: MatchType };

type TextMatchHighlighterProps = {
  text: string;
  matches: TextHighlighterMatch[];
  highlightClassName?: string;
};

// Custom lastIndexOf function that accepts multiple characters and returns the index of the one closest to the indicated position
function lastIndexOf(str: string, pos: number, characters: string[]): number {
  for (let idx = pos; idx >= 0; --idx) {
    // We need to add 1 to the matched index to account for the space that the character itself takes up and we don't highlight it
    if (characters.includes(str[idx])) return idx + 1;
  }
  return -1;
}

// Custom indexOf function that accepts multiple characters and returns the index of the one closest one to the indicated position
function indexOf(str: string, pos: number, characters: string[]): number {
  for (let idx = pos; idx <= str.length; ++idx) {
    if (characters.includes(str[idx])) return idx;
  }
  return -1;
}

const ALL_MATCH_RENDERERS: TextToHtmlProps["matchRenderers"] = {
  [MatchType.Keyword]: {
    render: (_m, text) => <span className={css.highlight}>{text}</span>,
  },
  [MatchType.Link]: {
    render: (_m, text) => (
      <TextLink to={text} targetType="external">
        {text}
      </TextLink>
    ),
  },
  [MatchType.Mention]: {
    render: (_m, text) => <MentionedUser text={text} />,
  },
};

const SPACE_IDENTIFIERS = [" ", "\n"];

// Helper functions to make the code below simpler:
// `prevSpace` returns the position of the first space before `searchStart` or 0 if there is no such space
const prevSpace = (text: string, searchStart: number) => {
  const spacePos = lastIndexOf(text, searchStart, SPACE_IDENTIFIERS);
  return spacePos === -1 ? 0 : spacePos;
};
// `next` returns the position of the first space after `searchStart` or 0 if there is no such space
const nextSpace = (text: string, searchStart: number) => {
  const spacePos = indexOf(text, searchStart, SPACE_IDENTIFIERS);
  return spacePos === -1 ? text.length : spacePos;
};

export function TextMatchHighlighter(props: TextMatchHighlighterProps): JSX.Element {
  const { text, matches } = props;

  const processedMatches: TextHighlighterMatch[] = React.useMemo(() => {
    const newMatches: TextHighlighterMatch[] = [];

    // Extend keyword matches to full word boundaries

    for (const m of matches) {
      if (m.matchType === undefined || m.matchType === MatchType.Keyword) {
        const fullWordStart = prevSpace(text, m.start);
        const fullWordEnd = nextSpace(text, m.end);
        newMatches.push({
          ...m,
          start: fullWordStart,
          end: fullWordEnd,
        });
      } else {
        newMatches.push(m);
      }
    }
    return newMatches;
  }, [matches, text]);

  return (
    <TextToHtml
      text={text}
      matches={processedMatches}
      matchRenderers={ALL_MATCH_RENDERERS}
      defaultMatchType={MatchType.Keyword}
    />
  );
}

type MatchRenderingConfig = {
  render: (match: TextHighlighterMatch, text: string) => JSX.Element;
};
type TextToHtmlProps = {
  text: string;
  matches: TextHighlighterMatch[];
  matchRenderers: Record<MatchType, MatchRenderingConfig>;
  defaultMatchType: MatchType; // if no match type is provided (legacy)
};

function TextToHtml(props: TextToHtmlProps): JSX.Element {
  const { text, matches } = props;

  const spans = React.useMemo(() => {
    if (matches.length === 0) {
      return <span>{text}</span>;
    }
    // Current position in the string
    let textPos = 0;
    const resultSpans: React.ReactNode[] = [];

    const sortedMatches = matches.sort((m1, m2) => m1.start - m2.start);
    // For each match (sorted by starting position) we need to add any "snipped"
    // context between end of last match (`textPos`) and beginning of the match `m.start`.
    // The context has two parts:
    // {text immediately after prev keyword}... ...{text immediately before this keyword}
    // We try to only snip at word boundaries and sometimes merge contexts if
    // it doesn't make sense to snip just couple characters.
    for (const m of sortedMatches) {
      const renderMatch = props.matchRenderers[m.matchType ?? props.defaultMatchType].render;

      const matchStart = m.start;
      const matchEnd = m.end;
      const fullMatchLength = matchEnd - matchStart;
      const matchedText = text.substring(matchStart, matchEnd);
      const keyId = `${m.start}-${m.end}`;
      // The code is slightly simpler if we handle some edge cases separately
      if (matchStart < textPos) {
        // We assume that there are should be no overlaps between matches
        // so we just skip the ones that overlap.
        continue;
      } else if (matchStart === textPos) {
        // In this case we don't have to handle the context before the match.
        // This could happen for matches at the beginning of `text` or consecutive matches.
        resultSpans.push(
          <React.Fragment key={keyId}>{renderMatch(m, matchedText)}</React.Fragment>,
        );
      } else {
        resultSpans.push(
          <span key={keyId}>
            {text.substring(textPos, matchStart)}
            {renderMatch(m, matchedText)}
          </span>,
        );
      }
      // Move textPos pointer to end of current match
      textPos = matchStart + fullMatchLength;
    }
    // We need to add last context - the text after the last keyword.
    if (matches.length > 0) {
      resultSpans.push(<span key={textPos}>{text.substring(textPos, text.length)}</span>);
    }
    return resultSpans;
  }, [matches, text, props.defaultMatchType, props.matchRenderers]);

  return <>{spans}</>;
}

/**
 * Returns all matches of the regex in `query`.
 * The regex must have exactly one match group.
 */
export function findRegexMatches(text: string, re: RegExp): TextMatch[] {
  if (!text) return [];

  const keywordMatches: TextMatch[] = [];

  let match: RegExpExecArray | null;
  // we use `RegExp.exec` & `lastIndex` to get positions of all matches
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex
  while ((match = re.exec(text)) !== null) {
    const pos = re.lastIndex - match[1].length;
    keywordMatches.push({
      start: pos,
      end: pos + match[1].length,
    });
  }
  return keywordMatches;
}

export function SimpleMatchHighlighter(props: TextMatchHighlighterProps): JSX.Element {
  const { text, matches, highlightClassName } = props;
  // `substrSpan` is like `String.substr` but takes start end end of the substring

  const spans = React.useMemo(() => {
    if (matches.length === 0) {
      return <span>{text}</span>;
    }
    const substrSpan = (start: number, end: number) => text.substr(start, end - start);
    // Current position in the string
    let textPos = 0;
    const resultSpans: React.ReactNode[] = [];

    let spanIdx = 1;
    for (const m of matches) {
      if (textPos < m.start) {
        resultSpans.push(<span key={++spanIdx}>{substrSpan(textPos, m.start)}</span>);
      }
      resultSpans.push(
        <span key={++spanIdx} className={classNames(css.highlight, highlightClassName)}>
          {substrSpan(m.start, m.end)}
        </span>,
      );
      textPos = m.end;
    }
    if (textPos < text.length) {
      resultSpans.push(<span key={++spanIdx}>{substrSpan(textPos, text.length)}</span>);
    }
    return resultSpans;
  }, [matches, text, highlightClassName]);

  return <>{spans}</>;
}

function MentionedUser({ text }: { text: string }) {
  const { value: users } = useStotlesData(ICompanyDataProvider, (bc) => bc.getUsers(), []);

  const userDetails = React.useMemo(() => getMentionDetails(text, users ?? []), [text, users]);

  if (userDetails) {
    return (
      <span className={css.mention}>
        <Tooltip
          placement="top"
          title={
            <>
              <b>{userDetails.name}</b> ({userDetails.email})
            </>
          }
        >
          {`@${userDetails.name}`}
        </Tooltip>
      </span>
    );
  } else {
    return <span>{text}</span>;
  }
}
