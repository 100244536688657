import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Tooltip } from "antd5";

import { TextLink } from "components/actions/Links";
import TextButton from "components/actions/TextButton";
import { OrgWithStats } from "./types";

function BuyerInfoMenu({ org, reduced }: { org: OrgWithStats; reduced?: boolean }): JSX.Element {
  const moreMenu = React.useMemo(() => {
    const items = [
      {
        key: "in-app-profile",
        label: (
          <TextLink targetType="new-tab" to={`/buyers/${org.guid}`}>
            In-app profile
          </TextLink>
        ),
      },
      {
        key: "admin-panel-profile",
        label: (
          <TextLink targetType="new-tab" to={`/admin/buyers/${org.guid}`}>
            Admin-panel profile (admins only)
          </TextLink>
        ),
      },
    ];

    if (!reduced) {
      items.push({
        key: "split-buyer",
        label: (
          <TextLink
            targetType="new-tab"
            to={`/admin/buyer/buyer_split?guid=${org.guid}&name=${org.name}`}
          >
            Split this organisation
          </TextLink>
        ),
      });
    }

    if (org.url) {
      items.push({
        key: "visit-website",
        label: (
          <Tooltip title={org.url}>
            <TextLink targetType="external" to={org.url}>
              Visit website
            </TextLink>
          </Tooltip>
        ),
      });
    }

    return { items };
  }, [org, reduced]);

  return (
    <Dropdown trigger={["hover"]} menu={moreMenu}>
      <TextButton bold>
        Options <DownOutlined />
      </TextButton>
    </Dropdown>
  );
}

export default BuyerInfoMenu;
