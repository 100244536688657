import React from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd5";
import classNames from "classnames";

import { LimitedExportButton } from "components/actions/ExportButton";
import TextButton from "components/actions/TextButton";
import Badge from "lib/core_components/Badge";
import MultiSelectDropdown from "lib/core_components/MultiSelectDropdownList";
import { ContactSearchRequestDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventDataTypes, EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { JobFunction } from "lib/utils/oscarDataUtils";
import ContactsFilterDrawer from "./ContactsFilterDrawer";
import {
  CATEGORY_TYPES,
  contactFilterCount,
  ContactFilters,
  CONTACTS_DISPLAY,
  SENIORITY_OPTIONS,
} from "./contactUtils";

import css from "./ContactsFilterBar.module.scss";

const EXPORT_LIMIT = 500;

type Props = {
  contactFilters: ContactFilters;
  selectedRowGuids: string[];
  totalResults: number;
  onChangeSearchFilters: (filters: Partial<ContactFilters>) => void;
  defaultFilters: ContactFilters;
  showDropdownFilters: boolean;
  showAllFiltersDrawer: boolean;
  hideBuyerFilters?: boolean; // If this prop is provided, then contacts are searched for already within thr context of a buyer
  filterRef: React.MutableRefObject<null>; // For the tour
  showingRelatedContactsTour: boolean;
};

export function ContactsFilterBar({
  contactFilters,
  selectedRowGuids,
  totalResults,
  onChangeSearchFilters,
  defaultFilters,
  showDropdownFilters,
  hideBuyerFilters,
  showAllFiltersDrawer,
  filterRef,
  showingRelatedContactsTour,
}: Props) {
  const openApi = useOpenApi();

  const [filterDrawerVisible, setFilterDrawerVisible] = React.useState<boolean>(false);

  const { logEvent } = useTracking();

  const handleClearFilters = () => {
    onChangeSearchFilters({
      ...defaultFilters,
    });
    logEvent(EventNames.filterActioned, {
      "Filter name": "All filters",
      "Filter selection": "All options",
      "Action type": "Filter cleared",
    });
  };

  const resultsCount = selectedRowGuids.length > 0 ? selectedRowGuids.length : totalResults;

  const exportAction = React.useCallback(
    async (format: "xlsx" | "csv"): Promise<[string, Blob]> => {
      let requestDto: ContactSearchRequestDto;

      // If user has selected row guids, then do not export with active filters.
      // We only care about the contactIds as a filterable selection
      if (selectedRowGuids.length > 0) {
        requestDto = {
          ...defaultFilters,

          // This filter is only needed when applying a job function filter
          includeEmptyJobFunction: false,
          includeUncategorisedBuyers: true,
          limit: EXPORT_LIMIT,
          contactIds: selectedRowGuids,
          offset: 0,
        };
      } else {
        const includeUncategorised = contactFilters.jobFunction.includes("Uncategorised");
        requestDto = {
          ...contactFilters,
          jobFunction: contactFilters.jobFunction,
          includeEmptyJobFunction: includeUncategorised,
          limit: EXPORT_LIMIT,
          contactIds: [],
          offset: 0,
        };
      }

      switch (format) {
        case "csv": {
          const response = await openApi.contactsSearchCsv({
            contactSearchRequestDto: requestDto,
          });
          return ["contacts_export", new Blob([response])];
        }
        case "xlsx": {
          const response = await openApi.contactsSearchXlsx({
            contactSearchRequestDto: requestDto,
          });
          return ["contacts_export", new Blob([response])];
        }
      }
    },
    [selectedRowGuids, defaultFilters, contactFilters, openApi],
  );

  const handleTextSearch = (value: string) => {
    if (!showingRelatedContactsTour) {
      onChangeSearchFilters({ jobTitle: value });
      logEvent(EventNames.filterActioned, {
        "Filter name": "Job title",
        "Filter selection": value,
        "Action type": value.length > 0 ? "Filter applied" : "Filter cleared",
      });
    }
  };

  const filtersAppliedCount = React.useMemo(() => {
    return contactFilterCount(contactFilters, defaultFilters);
  }, [contactFilters, defaultFilters]);

  return (
    <>
      <TrackingProvider data={{ "Context source": "Contacts filter slide-out" }}>
        <ContactsFilterDrawer
          visible={filterDrawerVisible}
          filtersApplied={filtersAppliedCount > 0}
          onChangeSearchFilters={onChangeSearchFilters}
          toggleDrawer={setFilterDrawerVisible}
          clearFilters={handleClearFilters}
          handleTextSearch={handleTextSearch}
          contactFilters={contactFilters}
          withinContextOfBuyer={hideBuyerFilters}
        />
      </TrackingProvider>

      {/* Normal contacts table has a grey background + margin all over */}
      <div
        className={classNames(
          css.filtersContainer,
          hideBuyerFilters ? undefined : css.filtersWithMargin,
        )}
      >
        <div className={css.contactFilters} ref={filterRef}>
          <Input
            placeholder="Search job titles"
            onChange={(e) => handleTextSearch(e.target.value)}
            value={contactFilters.jobTitle}
            className={css.searchInput}
            prefix={<SearchOutlined className={css.icon} />}
            allowClear
          />
          {showDropdownFilters && (
            <>
              <MultiSelectDropdown
                title="Function"
                searchable
                options={CATEGORY_TYPES}
                selectedOptions={contactFilters.jobFunction}
                onChange={(e) => onChangeSearchFilters({ jobFunction: e as JobFunction[] })}
              />

              <MultiSelectDropdown
                title="Seniority"
                searchable
                options={SENIORITY_OPTIONS}
                selectedOptions={contactFilters.seniority}
                onChange={(e) => onChangeSearchFilters({ seniority: e as string[] })}
              />

              {hideBuyerFilters && (
                <MultiSelectDropdown
                  title="Contact information"
                  options={CONTACTS_DISPLAY}
                  selectedOptions={contactFilters.contactDisplayOptions || []}
                  onChange={(e) => {
                    onChangeSearchFilters({ contactDisplayOptions: e });
                  }}
                />
              )}
            </>
          )}

          {showAllFiltersDrawer && (
            <Button
              type="default"
              className={css.allFilterBtn}
              onClick={() => {
                if (!showingRelatedContactsTour) setFilterDrawerVisible(true);
              }}
            >
              <FilterOutlined className={css.icon} />
              All filters {filtersAppliedCount > 0 && <Badge>{filtersAppliedCount}</Badge>}
            </Button>
          )}

          {filtersAppliedCount > 0 && (
            <TextButton bold className={css.clearFilters} onClick={handleClearFilters}>
              Clear filters
            </TextButton>
          )}
        </div>

        {resultsCount > 0 && (
          <LimitedExportButton
            exportAction={exportAction}
            exportLimit={EXPORT_LIMIT}
            resultsCount={resultsCount}
            requiredDataType={"CONTACTS"}
            exportedDataType={EventDataTypes.contact}
          />
        )}
      </div>
    </>
  );
}
