import React from "react";
import { Popover } from "antd5";
import { startCase } from "lodash";

import { TextLink } from "components/actions/Links";

import css from "./OrganisationsPopover.module.scss";

type Org = { id: number | string; name: string };

type OrgProps = {
  orgs: Org[];
  orgType: "buyers" | "suppliers";
  authorised?: boolean;
  onUnauthorisedClick?: () => boolean;
};

export default function OrganisationsContainer(props: OrgProps) {
  const { orgs, orgType, authorised, onUnauthorisedClick } = props;

  if (orgs.length === 0) {
    return <span>--</span>;
  }

  if (orgs.length === 1) {
    return (
      <TextLink
        to={`/${orgType}/${orgs[0].id}`}
        disabled={!!window.guestUser}
        targetType="new-tab"
        authorised={authorised}
        onUnauthorisedClick={onUnauthorisedClick}
      >
        {orgs[0].name}
      </TextLink>
    );
  }

  return <OrgPopover {...props} />;
}

function OrgPopover({ orgs, orgType, authorised, onUnauthorisedClick }: OrgProps) {
  const total = orgs.length;
  const orgTypeLabel = startCase(orgType);

  const content = (
    <div className={css.popoverContent}>
      {orgs.map((org) => (
        <TextLink
          to={`/${orgType}/${org.id}`}
          disabled={!!window.guestUser}
          targetType="new-tab"
          authorised={authorised}
          onUnauthorisedClick={onUnauthorisedClick}
        >
          {org.name}
        </TextLink>
      ))}
    </div>
  );

  return (
    <Popover
      title={<div className={css.popoverTitle}>{`${total} ${orgTypeLabel}`}</div>}
      content={content}
      arrow={false}
      trigger="hover"
      placement="bottom"
    >
      {<span className={css.orgProperty}>{`${total} ${orgTypeLabel}`}</span>}
    </Popover>
  );
}
