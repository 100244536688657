import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { PqlActionedRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { useFindIntegrationProvider } from "../integrations/useFindIntegrationProvider";
import { getDataTypeFromUrl } from "../utils";

export function usePqlActioned(
  options?: UseMutationOptions<void, unknown, PqlActionedRequest, unknown>,
  trackingData?: EventData,
  trackEvent = true,
) {
  const api = useOpenApi();
  const { logEvent } = useTracking();

  const { data: providerType } = useFindIntegrationProvider();

  return useMutation(
    (req: PqlActionedRequest) => {
      req.dataType = req.dataType ?? getDataTypeFromUrl(window.location.href);
      return api.pqlActioned({ pqlActionedRequest: req });
    },
    {
      ...options,
      onSuccess: (_data, variables, _context) => {
        trackEvent &&
          logEvent(EventNames.paywallActioned, {
            "Data type": variables.dataType ?? "N/A",
            "Connected provider": providerType ?? "N/A",
            Action: variables.actionType,
            ...trackingData,
          });
      },
    },
  );
}
