import * as React from "react";
import { Select } from "antd"; // upgrade and verify, please extract the divs with the css classes to a component in searchpage and remove the css dependency here, check with michael if unsure

import AutoclosingMultiselect from "components/form_components/AutoclosingMultiselect";
import { countryCodes } from "lib/data/optionItems";

import css from "./CountryFilter.module.scss";

const { Option } = Select;

const countryCodeOptions = countryCodes.map((countryObj) => (
  <Option value={countryObj.code} key={countryObj.code} title={countryObj.name}>
    {countryObj.name}
  </Option>
));

interface Props {
  value: string[] | undefined;
  label?: string;
  onChange: (newValue: string[]) => void;
  showLabel?: boolean;
  placeholder?: string;
  id?: string;
}

function CountryFilter({
  label,
  value,
  onChange,
  showLabel = true,
  placeholder,
  id,
}: Props): JSX.Element {
  return (
    <div className={css.countryFilter}>
      {showLabel && <span>{label || "Country"}</span>}
      <AutoclosingMultiselect<string>
        id={id}
        onChange={onChange}
        placeholder={placeholder || "Select..."}
        value={value || []}
        filterOption={(input, option) =>
          (option.props.title as string).toLowerCase().startsWith(input.toLowerCase())
        }
        style={{ width: "100%" }}
      >
        {countryCodeOptions}
      </AutoclosingMultiselect>
    </div>
  );
}

export default CountryFilter;
