import React, { ReactNode } from "react";
import {
  ClusterOutlined,
  FileSearchOutlined,
  IdcardOutlined,
  ThunderboltOutlined,
  TrophyOutlined,
} from "@ant-design/icons";

import { TextLink } from "components/actions/Links";
import { FreeIcon, ProIcon } from "lib/core_components/Labels";
import { DataTypeName, DataTypeNameWithBuyersAndSuppliers } from "lib/types/models";

import css from "./PlanTypes.module.scss";

interface DataTypeGridProps {
  dataTypes: DataTypeName[];
}

/**
 * @deprecated
 */
export function DataTypeGrid({ dataTypes }: DataTypeGridProps): JSX.Element {
  return (
    <div className={css.dataTypeGrid}>
      {dataTypes.map((type) => (
        <DataTypeInfo key={type} dataType={type} />
      ))}
    </div>
  );
}

interface DataTypeInfoProps {
  dataType: DataTypeNameWithBuyersAndSuppliers;
  pricingLayout?: boolean;
  withoutIcon?: boolean;
  hideDescription?: boolean;
}

/**
@deprecated
*/
function DataTypeInfo({
  dataType,
  pricingLayout,
  hideDescription,
}: DataTypeInfoProps): JSX.Element | null {
  switch (dataType) {
    case "TENDERS|PRE-TENDERS":
      return (
        <DataType
          icon={<FileSearchOutlined />}
          name="Tenders and pre-tenders"
          description="Curated feed showing the right opportunities, helping you keep a real-time market pulse"
          pricingLayout={pricingLayout}
          hideDescription={hideDescription}
          free
          learnMoreLink="https://help.stotles.com/tenders-pins"
        />
      );
    case "AWARDS":
      return (
        <DataType
          icon={<TrophyOutlined />}
          name="Contract Awards"
          description="Get ahead with visibility on upcoming contract expiries"
          pricingLayout={pricingLayout}
          hideDescription={hideDescription}
          learnMoreLink="https://help.stotles.com/contract-award-data"
        />
      );
    case "BUYERS|SUPPLIERS":
      return (
        <>
          <DataTypeInfo dataType="SUPPLIERS" pricingLayout={pricingLayout} />
          <DataTypeInfo dataType="BUYERS" pricingLayout={pricingLayout} />
        </>
      );
    case "SUPPLIERS":
      return (
        <DataType
          icon={<ClusterOutlined />}
          name="Supplier Intelligence"
          description="Track competitor & partner activity"
          pricingLayout={pricingLayout}
          hideDescription={hideDescription}
          learnMoreLink="https://help.stotles.com/supplier-intelligence"
        />
      );
    case "BUYERS":
      return (
        <DataType
          icon={<ThunderboltOutlined />}
          name="Buyer Intelligence"
          description="Real-time tracking and historical context on key accounts and prospects"
          pricingLayout={pricingLayout}
          hideDescription={hideDescription}
          learnMoreLink="https://help.stotles.com/buyer-intelligence"
        />
      );
    case "CONTACTS":
      return (
        <DataType
          icon={<IdcardOutlined />}
          name="Decision maker contacts"
          description="Get access to decision makers in your key accounts"
          pricingLayout={pricingLayout}
          hideDescription={hideDescription}
          learnMoreLink="https://help.stotles.com/enhanced-contact-data"
        />
      );
    default:
      return null;
  }
}

interface DataTypeProps {
  icon: ReactNode;
  name: string;
  description?: string;
  free?: boolean;
  pricingLayout?: boolean;
  learnMoreLink?: string;
  withoutIcon?: boolean;
  hideDescription?: boolean;
}

function DataType({
  icon,
  name,
  description,
  free,
  pricingLayout,
  learnMoreLink,
  hideDescription,
}: DataTypeProps): JSX.Element {
  if (pricingLayout) {
    return (
      <div>
        <h3 className={css.dataTypeHeader}>
          <span className={css.dataTypeNamePricing}>{name}</span>
          <span className={css.dataTypeIcon}>{free ? <FreeIcon large /> : <ProIcon large />}</span>
        </h3>
        <div>
          {description && (
            <p>
              {!hideDescription && description}{" "}
              {learnMoreLink && (
                <TextLink className={css.learnMoreLink} targetType="new-tab" to={learnMoreLink}>
                  Learn more
                </TextLink>
              )}
            </p>
          )}
        </div>
      </div>
    );
  }
  return (
    <div>
      <h3 className={css.dataTypeHeader}>
        {icon}
        <span className={css.dataTypeName}>{name}</span>
        <span className={css.dataTypeIcon}>{free ? <FreeIcon /> : <ProIcon />}</span>
      </h3>
      {description && <p>{description}</p>}
      {learnMoreLink && (
        <TextLink className={css.learnMoreLink} targetType="new-tab" to={learnMoreLink}>
          Learn more
        </TextLink>
      )}
    </div>
  );
}
