export const REACT_QUERY_OPTIONS_NEVER_REFETCH = {
  staleTime: Infinity,
  cacheTime: Infinity,
};

export function getDataTypeFromUrl(url: string) {
  if (url.includes("buyer")) {
    return "BUYERS";
  }
  if (url.includes("supplier")) {
    return "SUPPLIERS";
  }
  if (url.includes("spend")) {
    return "SPEND";
  }
  if (url.includes("contact")) {
    return "CONTACTS";
  }
  if (url.includes("framework")) {
    return "FRAMEWORKS";
  }
  // Upcoming expiries view, no easy way of doing this, this will works at least some of the time
  // it should catch the upgrade button being clicked on the nav bar on this page
  if (url.includes("c735a82a-4fea-4a97-84a8-7f9638e64c25")) {
    return "AWARDS";
  }
  return undefined;
}
