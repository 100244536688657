import React from "react";
import { ExportOutlined } from "@ant-design/icons";
import classNames from "classnames";

import SrcDocsLink from "components/actions/SrcDocsLink";
import { Currency } from "components/app_layout/Typography/Numbers";
import BulkSaveNoticeSupplier from "components/suppliers/BulkSaveNoticeSupplier";
import SaveSupplier from "components/suppliers/SaveSupplier";
import { PropertyInline } from "lib/core_components/Property";
import { RelevanceScorePopover } from "lib/core_components/RelevanceScore";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { BasicSignal } from "lib/types/models";
import OrganisationsContainer from "./OrganisationsPopover";

import css from "./DetailsCardContent.module.scss";

type Supplier = {
  id: number;
  name: string;
  guid: string;
};

type Lot = {
  suppliers?: Supplier[] | null;
};

type DetailsCardProps = {
  notice: {
    guid: string;
    stage: string;
    value: number | null;
    currency: string;
    url: string;
  };
  buyers: { id: number; name: string }[];
  lots: Lot[];
  signals: BasicSignal[];
  score: number;
};

type Org = { id: number; name: string; guid: string };

function getSuppliers(lots: Lot[]): Org[] {
  const suppliers: Supplier[] = [];

  const uniqueSupplierIds = new Set();

  for (const lot of lots) {
    lot.suppliers?.forEach((supplier) => {
      if (!uniqueSupplierIds.has(supplier.id)) {
        suppliers.push(supplier);
        uniqueSupplierIds.add(supplier.id);
      }
    });
  }

  return suppliers;
}

// customised to get the ellipsis to work correctly alongside the icon
function CustomSourcesProperty({ recordUrl }: { recordUrl: string }): JSX.Element {
  return (
    <div className={css.sourceProperty}>
      <span className={css.sourcePropertyTitle}>Sources</span>
      <span className={classNames({ [css.linkEllipsis]: recordUrl })}>
        {recordUrl ? <SrcDocsLink url={recordUrl} linkType="text" showIcon={false} /> : "--"}
      </span>
      {recordUrl && <ExportOutlined className={css.exportIcon} />}
    </div>
  );
}

export default function DetailsCardContent({
  notice,
  buyers,
  signals,
  score,
  lots,
}: DetailsCardProps) {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const { checkSubscription: onUnauthorisedClick, authorised } = useCheckSubscription("SUPPLIERS");

  const suppliers = getSuppliers(lots);

  return (
    <div className={css.container}>
      <PropertyInline
        title="Signal score"
        value={
          <RelevanceScorePopover
            recordGuid={notice.guid}
            recordStage={notice.stage}
            signals={signals ?? []}
            relevanceScore={score}
            btnClassName={css.relevanceScoreBtn}
          />
        }
        className={css.property}
        borderBottom
      />
      <PropertyInline
        title="Buyer(s)"
        value={<OrganisationsContainer orgs={buyers} orgType="buyers" />}
        className={css.property}
        borderBottom
      />
      {notice.stage === "AWARDED" && (
        <PropertyInline
          title="Supplier(s)"
          value={
            <div className={css.supplierProp}>
              <OrganisationsContainer
                orgs={suppliers}
                orgType="suppliers"
                onUnauthorisedClick={onUnauthorisedClick}
                authorised={authorised}
              />
              {suppliers.length > 0 &&
                !window.guestUser &&
                window.currentUser?.use_supplier_name === false &&
                enableNewSupplierFilter && (
                  <>
                    {suppliers.length > 1 ? (
                      <BulkSaveNoticeSupplier supplierGuids={suppliers.map((s) => s.guid)} />
                    ) : (
                      <SaveSupplier
                        supplierGuid={suppliers[0].guid}
                        supplierName={suppliers[0].name}
                        contextSource={"Notice preview"}
                        saveType="bookmark"
                      />
                    )}
                  </>
                )}
            </div>
          }
          className={css.property}
          borderBottom
        />
      )}
      <PropertyInline
        title="Contract value"
        value={<Currency value={notice.value} currency={notice.currency || undefined} />}
        className={css.property}
        borderBottom
      />
      <CustomSourcesProperty recordUrl={notice.url} />
    </div>
  );
}
