import * as React from "react";
import { useMemo } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ConfigProvider, StepProps, Steps, Tooltip } from "antd5";

import { EllipsisTooltipText, EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { StageIconFull, StageIconHollow, StageIconTarget } from "lib/icons/StageIcon";
import {
  blue600,
  grey900,
  openGreen,
  purple,
  purple500,
  red600,
  yellow400,
  yellow600,
} from "lib/themes/colors";
import { EventNames, logEvent, TrackingProvider } from "lib/tracking";
import { formatDate, startOfDay } from "lib/utils";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import CentredSpinner from "./CentredSpinner";

import css from "./ProcurementTimeline.module.scss";

// These stages encompass all framework and record stages. They each have their own subsets though of current status,
// depending on the logic of the dates
export enum TimelineStage {
  PRE_TENDER = "Pre-tender",
  TENDER = "Tender",
  AWARD = "Award",
  EXPIRED = "Expired",
  CANCELLED = "Cancelled",
  UNKNOWN = "Unknown",
}

enum TimelineProgress {
  PAST = "Past",
  PRESENT = "Present",
  FUTURE = "Future",
}

/**
 * Given the timeline colour, stage and progress - (past, present, future), returns the appropriate icon for the timelineSteps.
 * @param timelineProgress
 * @param colour
 * @param stage
 * @returns
 */
function getTimelineIcon(timelineProgress: TimelineProgress, colour: string, stage: string) {
  const iconLabel = `${stage} ${timelineProgress} icon`;
  switch (timelineProgress) {
    case TimelineProgress.PAST:
      return <StageIconFull fill={colour} ariaLabel={iconLabel} />;
    case TimelineProgress.FUTURE:
      return <StageIconHollow fill={colour} ariaLabel={iconLabel} />;
    case TimelineProgress.PRESENT:
      return <StageIconTarget fill={colour} ariaLabel={iconLabel} />;
    default:
      return null;
  }
}

function getTimelineProgress(index: number, currentStep: number): TimelineProgress {
  if (index < currentStep) return TimelineProgress.PAST;
  if (index > currentStep) return TimelineProgress.FUTURE;
  return TimelineProgress.PRESENT;
}

/**
 * For each timelineSteps stage, we need to determine the content to display. This is based on the dates
 * and the current timelineProgress on the timelineSteps
 * @param stage
 * @param datePeriods
 * @param timelineProgress
 * @returns
 */
function getTimelineContent(
  timelineObj: TimelineObj,
  timelineProgress: TimelineProgress,
  currentStage: TimelineStage,
): {
  stageTitle?: string;
  colour: string;
  tooltip: string;
} {
  const { publishDate, startDate, endDate, stage } = timelineObj;
  // Stripping time from these date objects so they're compared correctly
  const now = startOfDay(new Date());
  const afterEndDate = endDate && startOfDay(endDate) < now;

  const afterStartDate = startDate && startOfDay(startDate) <= now;

  switch (stage) {
    case TimelineStage.PRE_TENDER: {
      // Is only stale when the current stage is pre-tender and was published more than 3 months ago
      const staleThreshold = new Date();
      staleThreshold.setMonth(staleThreshold.getMonth() - 3);
      const isStale =
        currentStage === TimelineStage.PRE_TENDER && publishDate && publishDate < staleThreshold;

      if (isStale) {
        return {
          stageTitle: "Stale",
          colour: yellow600,
          tooltip:
            "Notice or framework that has been in the early market engagement stage for more than 3 months.",
        };
      } else {
        return {
          colour: yellow400,
          tooltip:
            "This notice or framework is in the early market engagement stage and has been for less than 3 months.",
        };
      }
    }

    case TimelineStage.TENDER: {
      if (timelineProgress === TimelineProgress.FUTURE) {
        return {
          colour: openGreen,
          tooltip: "Tendering stage has not begun yet",
        };
      } else if (
        timelineProgress === TimelineProgress.PAST ||
        (timelineProgress === TimelineProgress.PRESENT && afterEndDate)
      ) {
        return {
          stageTitle: "Closed",
          colour: red600,
          tooltip:
            "Notice or framework is no longer open for applications to be submitted by suppliers",
        };
      } else {
        return {
          stageTitle: "Open",
          colour: openGreen,
          tooltip: "Notice or framework is open for application to be submitted by suppliers.",
        };
      }
    }

    case TimelineStage.AWARD: {
      if (afterEndDate) {
        return {
          stageTitle: "Expired",
          colour: purple500,
          tooltip: "This contract has expired as the end date stated is in the past.",
        };
      } else if (afterStartDate && !!endDate) {
        return {
          stageTitle: "Live",
          colour: purple,
          tooltip:
            "The contract is currently in progress as it is past the contract start date stated on the notice.",
        };
      } else {
        return {
          colour: blue600,
          tooltip:
            "Notice or framework that has been awarded but not started yet. This could be because the start date is in the future or unknown.",
        };
      }
    }

    // Branch handles edge cases where we have expired contracts/frameworks, but no end dates
    case TimelineStage.EXPIRED: {
      return {
        stageTitle: "Expired",
        colour: purple500,
        tooltip: "This contract has expired as the end date stated is in the past.",
      };
    }

    case TimelineStage.CANCELLED: {
      return {
        colour: red600,
        tooltip: "Framework is cancelled",
      };
    }
    default: {
      return {
        colour: grey900,
        tooltip:
          "We do not have enough information to determine the current stage of this notice/framework.",
      };
    }
  }
}

export type TimelineObj = {
  title?: string | null;
  stotlesUrl?: string | null;
  externalSourceUrl?: string | null;
  stage: string;

  // Ensuring that Date objects are passed through, rather than strings because we want to do some logic on dates in this component to
  // calculate the current progress relative to the present date, as well as ensure consistent date formatting with the formatDate function
  publishDate?: Date;
  startDate?: Date;
  endDate?: Date;
};

type Props = {
  // We need to know what stage the overall procurement process is currently at, as it can change how
  //  we display certain things like icons and titles
  currentStage: TimelineStage;
  timelineStages: TimelineObj[];
  isLoading: boolean;
  extensionAvailable?: boolean;
  direction?: "horizontal" | "vertical";
};

/**
 * Timeline component which displays the timelineSteps of a notice or framework. The logic for determing the date, title and stage of each timeline step
 * is to be performed by an API call, which will return the timelineSteps stages and dates in the correct order
 * @param param0
 * @returns
 */
export default function ProcurementTimeline({
  timelineStages,
  isLoading,
  extensionAvailable = false,
  currentStage,
  direction = "vertical",
}: Props): JSX.Element {
  /**
   * Fills out the timeline component to incluide empty states, if a timelineObj has not been provided for
   * a standard stage in a procurement process. Also ensures a proper ordering of the timelineObjs in the timeline
   */
  const timeline = useMemo((): TimelineObj[] => {
    let finalTimelineStages: TimelineObj[] = [];

    const preTenderStage = timelineStages.find(
      (timelineStage) => timelineStage.stage === TimelineStage.PRE_TENDER,
    );

    // At the very least, this timeLineObj will have a stage attribute, which is enough for it to be displayed
    finalTimelineStages.push({
      ...preTenderStage,
      stage: TimelineStage.PRE_TENDER,
    });

    const tenderStage = timelineStages.find(
      (timelineStage) => timelineStage.stage === TimelineStage.TENDER,
    );

    finalTimelineStages.push({
      ...tenderStage,
      stage: TimelineStage.TENDER,
    });

    const finalStages = timelineStages.filter(
      (timelineStage) =>
        timelineStage.stage === TimelineStage.AWARD ||
        timelineStage.stage === TimelineStage.EXPIRED ||
        timelineStage.stage === TimelineStage.CANCELLED ||
        timelineStage.stage === TimelineStage.UNKNOWN,
    );
    if (!finalStages?.length) {
      finalTimelineStages.push({
        stage: TimelineStage.AWARD,
      });
    } else {
      finalTimelineStages = finalTimelineStages.concat(finalStages);
    }

    return finalTimelineStages;
  }, [timelineStages]);

  // The step we are currently at in the process based on how the dates relate to today
  const currentStep = timeline.findIndex((timelineObj) => timelineObj.stage === currentStage);

  /**
   * Called to render each step, changes content depending on the timelineSteps stage
   * @param stage
   * @param timelineDates
   * @param index
   * @returns
   */
  const renderStep = (timelineObj: TimelineObj, index: number): StepProps => {
    const timelineProgress = getTimelineProgress(index, currentStep);
    const { stageTitle, colour, tooltip } = getTimelineContent(
      timelineObj,
      timelineProgress,
      currentStage,
    );

    const icon = getTimelineIcon(timelineProgress, colour, timelineObj.stage);

    // Only display the stage title if the stage is not in the future
    const displayStageTitle = stageTitle && timelineProgress !== TimelineProgress.FUTURE;

    return {
      title: (
        <div className={css.timelineTitle} aria-label={`${timelineObj.stage} title`}>
          <div className={css.titleContainer}>
            <Tooltip title={tooltip}>
              <h3 className={css.stageTitle}>
                {/* Display title of expired timeline stage is actually AWARD */}
                <span>
                  {timelineObj.stage === TimelineStage.EXPIRED
                    ? TimelineStage.AWARD
                    : timelineObj.stage}
                </span>
                {displayStageTitle && (
                  <>
                    <span>•</span>
                    <span>{stageTitle}</span>
                  </>
                )}
              </h3>
            </Tooltip>

            <TimelineStageNoticeTitle timelineObj={timelineObj} />
          </div>
        </div>
      ),
      description: <TimelineDates timelineObj={timelineObj} />,
      icon: <span className={css.iconContainer}>{icon}</span>,
    };
  };

  return (
    <TrackingProvider
      data={{
        "Context source": "Procurement timeline",
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            lineWidth: 2,
          },
          components: {
            Steps: {
              margin: 8,
              customIconSize: 0,
              customIconTop: 0,
            },
          },
        }}
      >
        {isLoading ? (
          <span aria-label="Timeline loading">
            <CentredSpinner />
          </span>
        ) : (
          <Steps
            className={css.steps}
            size="default"
            // Forces grey timeline steps
            current={0}
            direction={direction}
            items={[
              ...timeline.map((timelineStage, index) => renderStep(timelineStage, index)),
              ...(extensionAvailable
                ? [
                    {
                      description: (
                        <div className={css.extensionWarning} aria-label="Extension available">
                          <InfoCircleOutlined className={css.warningInfoIcon} /> Option for
                          extension available
                        </div>
                      ),
                      icon: <></>,
                    },
                  ]
                : []),
            ]}
          />
        )}
      </ConfigProvider>
    </TrackingProvider>
  );
}

/**
 * Given the progress of the timelineObj, as well as the general content of the timelineObj, this component renders
 * a title for the notice, which can be a link to the stotles notice, a link to an external source, just the title or an empty state
 * @param param0
 * @returns
 */
function TimelineStageNoticeTitle({ timelineObj }: { timelineObj: TimelineObj }): JSX.Element {
  const { title, stotlesUrl, publishDate, startDate, endDate } = timelineObj;

  // If there's no info, then depending on whether the stage is past or present, display placeholder text
  if (!publishDate && !startDate && !endDate && !title && !stotlesUrl) {
    return (
      <span className={css.noInfo}>
        Not identified <InfoCircleOutlined className={css.infoIcon} />
      </span>
    );
  }

  // If there's a stotles URL, then display that with the title as the link text (there should be a title if there's a stotles url)

  if (stotlesUrl) {
    return (
      <EllipsisTooltipTextLink
        containerClassname={css.noticeTitle}
        fullText={title ?? stotlesUrl}
        linkText={title ?? stotlesUrl}
        linkProps={{
          to: stotlesUrl,
          targetType: "new-tab",
          onClick: () => logEvent(EventNames.noticeClicked, {}),
        }}
      />
    );
  }
  // Shouldn't be possible to get here, but just in case we somehow get a notice title without any link
  return (
    <EllipsisTooltipText
      containerClassname={css.noticeTitle}
      fullText={title ?? ""}
      noWrap={true}
    />
  );
}

function TimelineDates({ timelineObj }: { timelineObj: TimelineObj }): JSX.Element {
  const { publishDate, startDate, endDate, stage } = timelineObj;
  if (!publishDate && !startDate && !endDate) {
    return <span className={css.empty}></span>;
  }

  return (
    <div className={css.datesContainer} aria-label={`${stage} dates`}>
      <TimelineDate dateType={`Published date`} date={publishDate} />

      <TimelineDate
        dateType={stage === TimelineStage.TENDER ? "Open date" : "Start date"}
        date={startDate}
      />

      <TimelineDate
        dateType={stage === TimelineStage.TENDER ? "Close date" : "End date"}
        date={endDate}
      />
    </div>
  );
}

type TimelineDateProps = {
  dateType: string;
  date?: Date;
};
/**
 * Displays the timelineSteps date and relative time from date if it exists. Does this with equal spacing between the title and date
 * for each date in the section
 * @param param0
 * @returns
 */
function TimelineDate({ dateType, date }: TimelineDateProps): JSX.Element {
  return (
    <div className={css.timelineDate}>
      <span className={css.timelineDateTitle}>{dateType}</span>
      <span className={css.prop}>
        <span>{date ? formatDate(date) : "--"}</span>
        {date && <span className={css.smallText}>{toHumanRelativeTimeFromNow(date)}</span>}
      </span>
    </div>
  );
}
