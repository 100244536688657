import React from "react";
import { Dropdown } from "antd"; // should be button links, icons don't seem right too should not be strings anymore, possibly unused? clean up with prismic?
import { DropDownProps } from "antd/lib/dropdown";
import classnames from "classnames";

import css from "./DropdownMenu.module.scss";

type DropdownMenuProps = React.PropsWithChildren<DropDownProps>;

/**
 * @deprecated use ant5 or upgrade this to ant 5, remove custome css
 */
function DropdownMenu({ overlayClassName, children, ...rest }: DropdownMenuProps): JSX.Element {
  return (
    <Dropdown overlayClassName={classnames(css.dropdownMenu, overlayClassName)} {...rest}>
      {children}
    </Dropdown>
  );
}

export default DropdownMenu;
