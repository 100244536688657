import * as React from "react";
import classnames from "classnames";

import css from "./Badge.module.scss";

type BadgeProps = React.PropsWithChildren<{
  hide?: boolean;
}>;
// The suggested type of Record<string,never> instead of {} actually has a different behaviour in
// this case
// eslint-disable-next-line @typescript-eslint/ban-types
export default function Badge({ children, hide }: BadgeProps): JSX.Element {
  return <span className={classnames(css.badge, hide ? css.hidden : undefined)}>{children}</span>;
}
