import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  SignalsToMigrateQuery,
  SignalsToMigrateRequest,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../../useGraphQlClient";

const searchSignalsToMigrate = graphql(`
  query signalsToMigrate($request: SignalsToMigrateRequest!) {
    signalsToMigrate(request: $request) {
      ids
      name
    }
  }
`);
type SignalsToMigrateResponse = SignalsToMigrateQuery["signalsToMigrate"];
export type SignalsToMigrate = SignalsToMigrateResponse[0];

export function useSignalsToMigrate(
  request: SignalsToMigrateRequest,
  options?: UseQueryOptions<SignalsToMigrateQuery, unknown, SignalsToMigrateQuery, QueryKey>,
) {
  return useGraphQlQuery(["signalsToMigrate", request], searchSignalsToMigrate, [{ request }], {
    ...options,
  });
}
