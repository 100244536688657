import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Drawer, Input, Select, Typography } from "antd"; // remove typography, change button to text button and verify
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import BuyerCategoryTreeSelect from "components/form_components/BuyerCategoryTreeSelect";
import { BuyerListSelect } from "components/form_components/BuyerListSelect";
import BuyerMultiselect from "components/form_components/BuyerMultiselect";
import { FilterWithDisabledPopup } from "components/form_components/FilterWithDisabledPopup";
import { EventNames, logEvent } from "lib/tracking";
import { JobFunction } from "lib/utils/oscarDataUtils";
import {
  CATEGORY_TYPES,
  ContactDisplayOption,
  ContactFilters,
  SENIORITY_OPTIONS,
} from "./contactUtils";

import css from "./ContactsFilterDrawer.module.scss";

const { Title } = Typography;

type ContactsFilterDrawerProps = {
  visible: boolean;
  filtersApplied: boolean;
  contactFilters: ContactFilters;
  clearFilters: () => void;
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeSearchFilters: (filters: Partial<ContactFilters>) => void;
  handleTextSearch: (value: string) => void;
  withinContextOfBuyer?: boolean;
};

function ContactsFilterDrawer({
  visible,
  filtersApplied,
  contactFilters,
  withinContextOfBuyer,
  toggleDrawer,
  clearFilters,
  onChangeSearchFilters,
  handleTextSearch,
}: ContactsFilterDrawerProps): JSX.Element {
  const onChangeContactInformation = (e: CheckboxChangeEvent, contact_op: ContactDisplayOption) => {
    let contactDisplayOps = contactFilters.contactDisplayOptions;

    if (e.target.checked) {
      contactDisplayOps.push(contact_op);
    } else {
      contactDisplayOps = contactDisplayOps.filter((item) => item !== contact_op);
    }

    onChangeSearchFilters({ contactDisplayOptions: contactDisplayOps });

    logEvent(EventNames.filterActioned, {
      "Filter name": "Contact information",
      "Filter selection": contactDisplayOps,
      "Action type": contactDisplayOps.length > 0 ? "Filter applied" : "Filter cleared",
    });
  };

  return (
    <Drawer
      width={700}
      className={css.drawer}
      bodyStyle={{ padding: "0px" }}
      drawerStyle={{ backgroundColor: "#fff" }}
      visible={visible}
      onClose={() => toggleDrawer(false)}
    >
      <header className={css.titleBar}>
        <Title level={2}>Contact Filters</Title>
        {filtersApplied && (
          <Button type="link" className={css.clearFiltersBtn} onClick={clearFilters}>
            {/* ant-5 change this to TextButton and verify */}
            Clear all
          </Button>
        )}
      </header>
      <Divider />

      <div className={css.contactFilters}>
        <h2 className={css.subtitle}>Contact Information</h2>
        <div className={css.inputGroup}>
          <span className={css.inputLabel}>Job title</span>
          <Input
            placeholder="Search job titles"
            onChange={(e) => handleTextSearch(e.target.value)}
            value={contactFilters.jobTitle}
            className={css.searchInput}
            prefix={<SearchOutlined className={css.searchIcon} />}
            allowClear
          />
        </div>
        <div className={css.inputGroup}>
          <span className={css.inputLabel}>First name</span>
          <Input
            placeholder="Search names"
            onChange={(e) => onChangeSearchFilters({ firstName: e.target.value })}
            value={contactFilters.firstName}
            className={css.searchInput}
            allowClear
          />
        </div>
        <div className={css.inputGroup}>
          <span className={css.inputLabel}>Last name</span>
          <Input
            placeholder="Search names"
            onChange={(e) => onChangeSearchFilters({ lastName: e.target.value })}
            value={contactFilters.lastName}
            className={css.searchInput}
            allowClear
          />
        </div>
        <div className={css.inputGroup}>
          <span className={css.inputLabel}>Seniority</span>
          <Select<string[]>
            mode="multiple"
            placeholder="Select"
            value={contactFilters.seniority}
            style={{ width: "100%" }}
            allowClear
            filterOption={(text, option) =>
              (option.props.label as string).toLowerCase().includes(text.toLowerCase())
            }
            onChange={(e) => onChangeSearchFilters({ seniority: e as string[] })}
          >
            {SENIORITY_OPTIONS.map((op) => (
              <Select.Option value={op.value} key={op.value} label={op.title}>
                {op.title}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className={css.inputGroup}>
          <span className={css.inputLabel}>Function</span>
          <Select<string[]>
            mode="multiple"
            placeholder="Select"
            value={contactFilters.jobFunction}
            style={{ width: "100%" }}
            allowClear
            filterOption={(text, option) =>
              (option.props.label as string).toLowerCase().includes(text.toLowerCase())
            }
            onChange={(e) => onChangeSearchFilters({ jobFunction: e as JobFunction[] })}
          >
            {CATEGORY_TYPES.map((cat) => (
              <Select.Option value={cat.value} key={cat.value} label={cat.title}>
                {cat.title}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className={css.inputGroup}>
          <span className={css.inputLabel}>Contact Information</span>
          <span className={css.subtitleLabel}>Must contain</span>

          <Checkbox
            checked={contactFilters.contactDisplayOptions.includes("EMAIL")}
            onChange={(e) => onChangeContactInformation(e, "EMAIL")}
            className={css.contactCheckbox}
          >
            Email address
          </Checkbox>

          <Checkbox
            checked={contactFilters.contactDisplayOptions.includes("PHONE")}
            onChange={(e) => onChangeContactInformation(e, "PHONE")}
            className={css.contactCheckbox}
          >
            Phone number
          </Checkbox>
        </div>

        {!withinContextOfBuyer && (
          <>
            <Divider />
            <h2 className={css.subtitle}>Buyer Information</h2>

            <FilterWithDisabledPopup
              filterTitle={<span className={css.inputLabel}>Buyer lists</span>}
              popupTitle="Buyer list filter disabled"
              popupDescription="Clear buyers filter to enable searching by specific buyer lists"
              filterDisabled={!!contactFilters.buyerIds?.length}
              clearFilter={() => onChangeSearchFilters({ buyerIds: [] })}
              filterComponent={
                <BuyerListSelect
                  onSelect={(listOfBuyerIds) =>
                    onChangeSearchFilters({ buyerListIds: listOfBuyerIds })
                  }
                  selected={contactFilters.buyerListIds}
                  disabled={!!contactFilters.buyerIds?.length}
                />
              }
            />

            <FilterWithDisabledPopup
              filterTitle={<span className={css.inputLabel}>Buyers</span>}
              popupTitle="Buyers filter disabled"
              popupDescription="Clear buyer list filter to enable searching by specific buyers"
              filterDisabled={!!contactFilters.buyerListIds?.length}
              clearFilter={() => onChangeSearchFilters({ buyerListIds: [] })}
              filterComponent={
                <BuyerMultiselect
                  customPlaceholder="Search by buyer name"
                  identifier="id"
                  countryCodes={["IE", "UK"]}
                  buyerIds={contactFilters.buyerIds}
                  onBuyerIdsChange={(buyerIds) => onChangeSearchFilters({ buyerIds: buyerIds })}
                  disabled={!!contactFilters.buyerListIds?.length}
                />
              }
            />

            <div className={css.inputGroup}>
              <span className={css.inputLabel}>Buyer type</span>

              <BuyerCategoryTreeSelect
                showUncategorised={contactFilters.includeUncategorisedBuyers}
                selectedIds={contactFilters.buyerCategoryIds || []}
                onUpdate={(ids, showUncategorised) => {
                  onChangeSearchFilters({
                    buyerCategoryIds: ids,
                    includeUncategorisedBuyers: showUncategorised,
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
}

export default ContactsFilterDrawer;
