import React from "react";
import { Button, Modal } from "antd5";

import { FeedbackModal } from "components/modals/FeedbackModal";
import { ProductTourCompletionStateResponseTourStateTourIdentifierEnum } from "lib/generated/app-api";
import { ProviderTypes } from "lib/generated/integration-api/models/ProviderTypes";
import { useProductTour } from "lib/hooks/useProductTour";
import { ExternalLink } from "lib/icons/ExternalLink";
import { DialogManager } from "lib/providers/DialogManager";
import { white } from "lib/themes/colors";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { PROVIDER_METADATA } from "./providerMetadata";

import css from "./ContactSentToCRMTourModal.module.scss";

import CELEBRATE_ICON from "../../../assets/images/icons/celebrate.svg";

type ContactSentToCRMTourModalProps = {
  providerType: ProviderTypes;
  contactId: string;
  isOpen: boolean;
  externalUrl: string;
  dialogManager: DialogManager;
  onClose: () => void;
  permanentlyHide: () => void;
};

export function ContactSentToCRMTourModal({
  providerType,
  contactId,
  isOpen,
  externalUrl,
  onClose,
  dialogManager,
  permanentlyHide,
}: ContactSentToCRMTourModalProps) {
  const { logEvent } = useTracking();

  const { permanentlyHide: permanentlyHideFeedback } = useProductTour(
    ProductTourCompletionStateResponseTourStateTourIdentifierEnum.CrmContactSendFeedback,
  );

  const onCloseModal = React.useCallback(() => {
    permanentlyHide();
    onClose();
  }, [onClose, permanentlyHide]);

  const onNoThanks = React.useCallback(() => {
    logEvent(EventNames.productTourHidden);
    onCloseModal();
  }, [logEvent, onCloseModal]);

  const viewInCRM = React.useCallback(() => {
    logEvent(EventNames.viewInCRMButtonClicked);
    window.open(externalUrl, "_blank");
    onCloseModal();

    dialogManager.openDialog(FeedbackModal, {
      tourIdentifier:
        ProductTourCompletionStateResponseTourStateTourIdentifierEnum.CrmContactSendFeedback,
      title: "You pushed a contact to your CRM! How did it go?",
      permanentlyHide: permanentlyHideFeedback,
    });
  }, [dialogManager, externalUrl, logEvent, onCloseModal, permanentlyHideFeedback]);

  return (
    <TrackingProvider
      data={{
        "Tour identifier":
          ProductTourCompletionStateResponseTourStateTourIdentifierEnum.CrmContactSendIntro,
        "Contact ID": contactId,
      }}
    >
      <Modal
        centered
        open={isOpen}
        className={css.modal}
        onCancel={onCloseModal}
        closable={false}
        footer={
          <div className={css.footer}>
            <Button onClick={viewInCRM} className={css.viewBtn} type="primary">
              <ExternalLink fill={white} className={css.icon} />
              View in {PROVIDER_METADATA[providerType].name}
            </Button>
            <Button onClick={onNoThanks}> No thanks</Button>
          </div>
        }
      >
        <div className={css.tourModalContent}>
          <img src={CELEBRATE_ICON} alt="celebrate" className={css.largeIcon} />
          <h3 className={css.modalTitle}>Great job!</h3>
          <p className={css.modalDesc}>
            Great job on sending your first contact to your CRM! Let us know if it went as you
            thought it would.
          </p>
        </div>
      </Modal>
    </TrackingProvider>
  );
}
