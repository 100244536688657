import * as React from "react";
// eslint-disable-next-line no-restricted-imports
import { Tabs } from "antd"; //unknown global ant styles
import { TabsProps } from "antd/lib/tabs";
import classNames from "classnames";

import css from "./tabs.module.scss";

export function PrimaryTabs({
  className,
  children,
  ...rest
}: React.PropsWithChildren<TabsProps>): JSX.Element {
  return (
    <Tabs animated={false} className={classNames(className, css.primaryTabs)} {...rest}>
      {children}
    </Tabs>
  );
}

export function SecondaryTabs({
  className,
  children,
  ...rest
}: React.PropsWithChildren<TabsProps>): JSX.Element {
  return (
    <Tabs className={classNames(className, css.secondaryTabs)} {...rest} type="card">
      {children}
    </Tabs>
  );
}

export const TabPane = Tabs.TabPane;
