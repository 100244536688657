/* eslint no-restricted-imports: ["error", { patterns: [{
    group: ["*"],
    message: "Please consider the importance of this import, and add exceptions if really necessary"
}]}]
--------
This module is used for landing pages, which need to be very light and minimal on imports. If you
need to change some functionality here, consider looking at lib/utils.ts
*/

// simple helper for toHumanRelativeTimeFromNow below
function getAbsoluteTimespan(amount: number, unit: string) {
  const val: number | "a" = Math.abs(Math.round(amount));
  return `${val === 1 ? "a" : val} ${unit}${val > 1 ? "s" : ""}`;
}

// very similar to Moment's
type RelativeTime = {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  totalDiffMs: number;
};

function parseDate(date: string | Date): Date {
  if (date instanceof Date) {
    return date;
  } else if (typeof date === "string") {
    const parsedDate = Date.parse(date);
    if (isNaN(parsedDate)) throw `Unparseable Date ${date}`;
    return new Date(parsedDate);
  } else {
    throw new Error(`Invalid date string: ${date}`);
  }
}

function toRelativeTimeFromNow(date: Date): RelativeTime {
  const parsedDate = date.getTime();

  const diff = parsedDate - new Date().valueOf();
  const diffSeconds = diff / 1000;
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;
  const diffMonths = diffDays / 30;
  const diffYears = diffMonths / 12;

  return {
    years: diffYears,
    months: diffMonths,
    days: diffDays,
    hours: diffHours,
    minutes: diffMinutes,
    seconds: diffSeconds,
    totalDiffMs: diff,
  };
}

export enum DateGranularity {
  "month",
  "day",
  "hour",
  "minute",
  "second",
}

// pass a string date eg. 2019-10-1 and get the difference from present day, in English
// eg. 2019-10-1 => 3 months ago; 2020-6-23 => in 7 months
export function toHumanRelativeTimeFromNow(
  date: string | Date,
  maximumGranularity: DateGranularity = DateGranularity.day,
): string {
  const dateToCheck = parseDate(date);
  const { years, months, days, hours, minutes, seconds, totalDiffMs } =
    toRelativeTimeFromNow(dateToCheck);
  let formattedAmount = "";

  if (Math.abs(years) >= 1) {
    formattedAmount = getAbsoluteTimespan(years, "year");
  } else if (Math.abs(months) >= 1 && maximumGranularity >= DateGranularity.month) {
    formattedAmount = getAbsoluteTimespan(months, "month");
  } else if (Math.abs(days) >= 1 && maximumGranularity >= DateGranularity.day) {
    formattedAmount = getAbsoluteTimespan(days, "day");
  } else if (Math.abs(hours) >= 1 && maximumGranularity >= DateGranularity.hour) {
    formattedAmount = getAbsoluteTimespan(hours, "hour");
  } else if (Math.abs(minutes) >= 1 && maximumGranularity >= DateGranularity.minute) {
    formattedAmount = getAbsoluteTimespan(minutes, "minute");
  } else if (Math.abs(seconds) >= 1 && maximumGranularity >= DateGranularity.second) {
    formattedAmount = "less than a minute";
  } else {
    const rtf = new Intl.RelativeTimeFormat("en", { numeric: "always" });
    const today = new Date();
    today.setHours(0, 0, 0, 1); // Reset to midnight today

    dateToCheck.setHours(0, 0, 0, 1); // Reset to midnight of the given date

    const timeDifference = dateToCheck.getTime() - today.getTime();

    const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return "today";
    }
    return rtf.format(daysDifference, "day");
  }

  return totalDiffMs > 0 ? `in ${formattedAmount}` : `${formattedAmount} ago`;
}
