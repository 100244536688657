import * as React from "react";
import { Tooltip } from "antd"; // upgrade and verify
import classNames from "classnames";

import { ContactDetail } from "components/buyer_details/contacts_panel/Procurement";
import SectionHeader from "components/reports/builder_components/SectionHeader";
import { DispatchComponentProps } from "components/reports/Section";
import { ContactsSection, ContactsSectionGeneratedContactsInner } from "lib/generated/admin-api";
import { TriggerHelperButton, useProHelper } from "lib/providers/ProHelper";
import { useSubscription } from "lib/providers/Subscription";
import { TrackingProvider } from "lib/tracking";

import css from "./ContactsChart.module.scss";

function renderTooltipMessage({ showTooltip }: { showTooltip: boolean }): JSX.Element | undefined {
  if (!showTooltip) return undefined;

  return (
    <span>
      Looking to dig deeper?{" "}
      <TriggerHelperButton requiredType="CONTACTS">Learn more</TriggerHelperButton>
    </span>
  );
}

export default function ContactsGrid({
  section,
  reportSectionId,
}: DispatchComponentProps<ContactsSection>): JSX.Element {
  const contactsBySeniority = React.useMemo(() => {
    // Having these pre-set just ensures the correct order. Any additional categories such as "Unkown" will be added to the end
    const groupedContacts: Record<string, ContactsSectionGeneratedContactsInner[]> = {
      "Chief Officer": [],
      "C-level & Execs": [],
      Management: [],
    };
    for (const contact of section.generated.contacts) {
      if (!groupedContacts[contact.stotlesSeniority]) {
        groupedContacts[contact.stotlesSeniority] = [];
      }

      groupedContacts[contact.stotlesSeniority].push(contact);
    }
    // Delete empty
    Object.keys(groupedContacts).forEach((k) => {
      !groupedContacts[k]?.length && delete groupedContacts[k];
    });

    return groupedContacts;
  }, [section.generated.contacts]);

  return (
    <div className={css.container} id={reportSectionId}>
      <SectionHeader title={section.generated.title} description={section.generated.description} />
      <div>
        {Object.entries(contactsBySeniority).map(([seniority, contacts]) => (
          <div className={css.section} key={seniority}>
            <h3>{seniority}</h3>
            <ul className={css.list}>
              {contacts.map((c, i) => (
                <li key={i}>
                  <ContactCard
                    name={`${c.firstName} ${c.lastName}`.trim()}
                    email={c.email}
                    phone={c.phone}
                    jobTitle={c.jobTitle}
                    categories={[c.stotlesCats]}
                    seniority={c.stotlesSeniority}
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

function ContactCard({
  jobTitle,
  name,
  categories,
  email,
  phone,
  seniority,
}: {
  jobTitle: string;
  name: string | null | undefined;
  categories: string[];
  email: string | null | undefined;
  phone: string | null | undefined;
  seniority: string;
}): JSX.Element {
  const subscription = useSubscription();
  const canViewAdvancedContacts = subscription.hasDataTypes("CONTACTS");
  const isAdvancedContactViewable = canViewAdvancedContacts ? undefined : css.blurred;
  const { checkSubscription } = useProHelper();
  const showSlideout = React.useCallback(() => checkSubscription("CONTACTS"), [checkSubscription]);

  return (
    <TrackingProvider
      data={{
        "Contact job title": jobTitle,
        "Contact seniority": seniority,
        "Contact function": categories.join(", "),
      }}
    >
      <Tooltip
        title={renderTooltipMessage({
          showTooltip: !canViewAdvancedContacts,
        })}
      >
        <div
          className={classNames(css.contactCard, { [css.clickable]: !canViewAdvancedContacts })}
          onClick={canViewAdvancedContacts ? undefined : showSlideout}
        >
          <span className={css.title}>{jobTitle}</span>
          <span className={classNames(css.name, isAdvancedContactViewable)}>{name}</span>
          <span className={css.categories}>{categories.join(", ")}</span>
          {email && (
            <ContactDetail data={email} dataType="email" requiredDataSubscription="CONTACTS" />
          )}
          {phone && (
            <ContactDetail data={phone} dataType="phone" requiredDataSubscription="CONTACTS" />
          )}
        </div>
      </Tooltip>
    </TrackingProvider>
  );
}
