import React from "react";
import { Button, DatePicker, Input, message } from "antd"; // unknown careful with date picker? replace formspacer with a grid if possible? :)
import _ from "lodash";

import { useStotlesApi } from "lib/stotlesApiContext";
import { assertDefined } from "lib/utils";
import { DocumentBuyerSelect, DocumentTypeSelect } from "./DocumentComponents";
import { File } from "./types";
import { getDateFromString, getMonthStartString, validateFileProperties } from "./UploadDocuments";

import css from "./EditDocument.module.scss";

type EditDocumentProps = {
  userId: string;
  documentId: string | undefined;
  onFinished: () => void;
};

export function EditDocument({ documentId, onFinished }: EditDocumentProps): JSX.Element {
  const [fileData, setFileData] = React.useState<File>();
  const [initialFileData, setInitialFileData] = React.useState<File>();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>();

  const [ownerGuid, setOwnerGuid] = React.useState<string>();

  const api = useStotlesApi();

  React.useEffect(() => {
    async function fetchData() {
      if (documentId) {
        const file = await api.fetchDocument(documentId);
        if (file) {
          setFileData(file);
          setInitialFileData(file);
          setOwnerGuid(file.owner_guid);
        }
      }
    }
    void fetchData();
  }, [api, documentId]);

  const hasFormDataChanged = React.useMemo(() => {
    return _.isEqual(fileData, initialFileData);
  }, [fileData, initialFileData]);

  const handleFormSubmit = async () => {
    assertDefined(fileData);

    const errors = validateFileProperties(ownerGuid, fileData.source_url);

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    setUploading(true);
    assertDefined(ownerGuid);
    try {
      await api.updateDocument({ ...fileData, owner_guid: ownerGuid });
      void message.info("Document updated");
      onFinished();
    } catch (e) {
      void message.error("Error updating document");
    }

    setUploading(false);
  };

  const handleBuyerChange = (buyerGuid: string | undefined) => {
    setOwnerGuid(buyerGuid);
    assertDefined(fileData);
    if (buyerGuid) {
      setFileData({ ...fileData, owner_guid: buyerGuid });
    }
  };

  return (
    <>
      {initialFileData && fileData ? (
        <div className={css.documentForm}>
          <span className={css.formSpacer}>Select Buyer</span>
          <DocumentBuyerSelect
            onBuyerChange={handleBuyerChange}
            initialBuyerGuid={initialFileData.owner_guid}
          />
          <span className={css.formSpacer}>Source URL</span>
          <Input
            defaultValue={initialFileData.source_url}
            onChange={(urlInput) =>
              setFileData({ ...fileData, source_url: urlInput.currentTarget.value })
            }
          />
          <span className={css.formSpacer}>Document Type</span>
          <DocumentTypeSelect
            defaultValue={initialFileData.document_type}
            onChange={(docType) => setFileData({ ...fileData, document_type: docType })}
          />

          <span className={css.formSpacer}>Publish Date</span>
          <DatePicker.MonthPicker
            placeholder="Publish Date"
            onChange={(date: moment.Moment | null, _) =>
              setFileData({
                ...fileData,
                published_at: getMonthStartString(date),
              })
            }
            defaultValue={getDateFromString(initialFileData.published_at)}
            value={getDateFromString(fileData.published_at)}
          />
          <span className={css.formSpacer}>Description</span>
          <Input
            placeholder="Description"
            defaultValue={initialFileData.description}
            value={fileData.description}
            onChange={(e) => setFileData({ ...fileData, description: e.currentTarget.value })}
          />
          <div className={css.errors}>
            {errors?.map((err, i) => (
              <span key={i} className={css.error}>
                {err}
              </span>
            ))}
          </div>
          <Button
            type="primary"
            disabled={hasFormDataChanged}
            loading={uploading}
            onClick={handleFormSubmit}
            className={css.formSpacer}
          >
            Update Document
          </Button>
        </div>
      ) : (
        <p>Invalid file</p>
      )}
    </>
  );
}
