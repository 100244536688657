import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Divider } from "antd5";

import { SelectBuyerList } from "components/form_components/BuyerListSelect";
import { BuyerSelect } from "components/form_components/BuyerSelect";
import { BuyerTypeSelect } from "components/form_components/BuyerTypeSelect";
import { Radio, Select } from "components/form_components/Inputs";
import { NewSupplierSelect } from "components/form_components/NewSupplierSelect";
import { SignalSelect } from "components/form_components/SignalSelect";
import { SupplierSelect } from "components/form_components/SupplierSelect";
import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { Framework_Lots } from "lib/generated/app-service-gql/graphql";
import FilterFormTitle from "../filter_form/filter_form_title/FilterFormTitle";
import { useSuppliersTracking } from "./tracking";
import { SupplierFilters } from "./utils";

import css from "./FilterForm.module.scss";

export type FilterSections = "suppliers" | "supplierGuids" | "buyers" | "lots" | "signals";

export type FilterFormProps = {
  onChange: (filters: SupplierFilters) => void;
  filters: SupplierFilters;
  showTitle: boolean;
  closeDrawer: () => void;
  hiddenSections?: FilterSections[];
  frameworkLots?: Framework_Lots[];
};

export function FilterForm({
  onChange,
  filters,
  showTitle,
  closeDrawer,
  hiddenSections = ["lots"],

  frameworkLots = [],
}: FilterFormProps) {
  const { control, watch, handleSubmit } = useForm<SupplierFilters>({
    defaultValues: filters,
  });
  const previousFilters = useRef(filters);
  const tracking = useSuppliersTracking();
  const enableNewSupplierFilter =
    useVariableValue(NEW_SUPPLIER_FILTER, false) && window.currentUser?.use_supplier_name === false;

  // submit form onChange
  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        tracking.filterFormChanged(previousFilters.current, d);
        previousFilters.current = d;
        onChange(d);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, onChange, tracking, watch]);

  return (
    <>
      {showTitle && <FilterFormTitle close={closeDrawer} />}
      <form>
        <div className={showTitle ? css.formContent : undefined}>
          {hiddenSections.includes("signals") ? null : (
            <>
              <h3 className={css.sectionTitle}>Signals</h3>
              <div className={css.filterSection}>
                <SignalSelect
                  name="signals"
                  label=""
                  control={control}
                  placeholder="Select..."
                  allowClear
                />
              </div>
              <Divider />
            </>
          )}
          {hiddenSections.includes("suppliers") ? null : (
            <>
              <h3 className={css.sectionTitle}>Suppliers</h3>
              <div className={css.filterSection}>
                {enableNewSupplierFilter ? (
                  <NewSupplierSelect name="guids" label="Supplier Guids" control={control} />
                ) : (
                  <SupplierSelect
                    name="suppliers"
                    label="Suppliers"
                    control={control}
                    mode="multiple"
                    placeholder="Search suppliers..."
                    allowClear
                  />
                )}

                <RedactedWrapper
                  redactContent={
                    <Radio
                      label="SME Supplier?"
                      name={"isSme"}
                      control={control}
                      disabled
                      options={[
                        { label: "Show all suppliers", value: "" },
                        { label: "Show only SMEs", value: true },
                        { label: "Hide SMEs", value: false },
                      ]}
                      optionType="default"
                      direction="vertical"
                    />
                  }
                  requiredDataType="SUPPLIERS"
                  contextSource="Supplier filter"
                >
                  <Radio
                    label="SME Supplier?"
                    name={"isSme"}
                    control={control}
                    options={[
                      { label: "Show all suppliers", value: "" },
                      { label: "Show only SMEs", value: true },
                      { label: "Hide SMEs", value: false },
                    ]}
                    optionType="default"
                    direction="vertical"
                    defaultValue=""
                  />
                </RedactedWrapper>
              </div>
              <Divider />
            </>
          )}
          {hiddenSections.includes("buyers") ? null : (
            <>
              <h3 className={css.sectionTitle}>Buyers</h3>
              <div className={css.filterSection}>
                <BuyerSelect
                  name="buyerIds"
                  label="Buyers"
                  control={control}
                  mode="multiple"
                  placeholder="Search buyers..."
                  allowClear
                />
                <SelectBuyerList
                  name="buyerLists"
                  label="Buyer lists"
                  control={control}
                  placeholder="Select..."
                  mode="multiple"
                  allowClear
                />
                <BuyerTypeSelect
                  name="buyerCategories"
                  control={control}
                  multiple
                  label="Buyer type"
                  placeholder="Select..."
                  allowClear
                />
              </div>
            </>
          )}

          {hiddenSections.includes("lots") && frameworkLots ? null : (
            <>
              <h3 className={css.sectionTitle}>Lots</h3>
              <div className={css.filterSection} aria-label="Lots filter section">
                <Select
                  name="lotIds"
                  control={control}
                  label="Lot name"
                  placeholder="Select lots..."
                  options={frameworkLots.map((lot, index) => ({
                    key: index,
                    label: `${lot.id}: ${lot.title}`,
                    value: lot.id,
                  }))}
                  mode="multiple"
                  optionFilterProp="label"
                  allowClear
                />
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
}
